import { Button, Modal } from 'antd';
import { Switch, Typography } from 'antd'
import React, { useState } from 'react';
import MyPdfViewer from './PdfViewerModal';



const { Paragraph, Text } = Typography;
const ModalCustomCards = (props) => {
  // debugger
  const [open, setOpen] = useState(props.open);
  return (
    <>
      <Modal
        className = "cards_model_popup"
        title={props.content.header}
        centered
        closable={true}
        visible = {open}
        // open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={700}
        height={750}
        footer={null}
      >
        {/* {props.content} */}
       <MyPdfViewer/>
      </Modal>
    </>
  );
};


export default ModalCustomCards;