import React from 'react';
import PropTypes from 'prop-types';

export default class Schema extends React.Component {

    static metadata = {
        displayName: 'Theme Service',
        container: false,
        visual: true,
        builderCompatible: true,
        thumbnail: undefined,
        categories: ['Services']
    };

    static propTypes = {
        currentTheme: PropTypes.string.isRequired,
        isGrayscale: PropTypes.bool,
    };

    static defaultProps = {
        currentTheme: 'Default'
    };

    static i18n = {
        default : "en-US"
    }; 

    static mapStateToProps = (state) => {
        const {currentBreakpoint, accessibility} = state;
        return {
            currentBreakpoint,
            accessibility
        }
    }

}