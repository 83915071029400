import { types } from "./types";
import ReportService from "../../service/reportService";
const controlPrompts = {
  Financialyear: [
    "region",
    "region_subregion",
    "distribution",
    "working_capital",
    "profile_sales_brand_productgroup",
    "profile_sales_brand",
    "profile_sales_byregion",
    "profile_sales_businessunit",
    "inventory_backlog",
    "accounts_receivables",
    "sankey_level1_report",
    "primary_report",
    "main_brands",
  ],
  Region: ["distribution"],
  Businessbrand: [
    "region",
    "region_subregion",
    "distribution",
    "working_capital",
    "profile_sales_brand_productgroup",
    "profile_sales_brand",
    "profile_sales_byregion",
    "profile_sales_businessunit",
    "inventory_backlog",
    "accounts_receivables",
    "sankey_level1_report",
  ],
  time_grain_region: ["profile_sales_byregion"],
};

const processFilters = (filterObj = {}) => {
  let filters = [];
  Object.keys(filterObj).map((filterName) => {
    let value = Array.isArray(filterObj[filterName])
      ? filterObj[filterName]
      : [filterObj[filterName]];
    if (filterObj[filterName] && filterObj[filterName] !== "ALL")
      filters.push({
        columnName: filterName,
        columnValue: value,
        excludeKeyword:
          Number.isInteger(filterObj[filterName]) ||
          filterName === "Year" ||
          filterName === "Financialyear",
      });
  });
  return filters;
};

const processDynamicColumns = (filterObj = {}) => {
  let filters = [];
  Object.keys(filterObj).map((filterName) => {
    filters.push({
      columnName: filterName,
      columnValue: filterObj[filterName],
    });
  });
  return filters;
};

const controlReportFilters = (reportName, filters) => {
  return filters.filter((filter) => {
    return (
      !controlPrompts[filter.columnName] ||
      (controlPrompts[filter.columnName] &&
        controlPrompts[filter.columnName].includes(reportName))
    );
  });
};

export const setReportData =
  (reports, filters, dynamicColumns) => async (dispatch) => {
    let processedFilters = processFilters(filters);
    let processedDynamicColumns = processDynamicColumns(dynamicColumns);
    let promises = [];
    reports &&
      reports.forEach((report) => {
        let filteredFilters = processedFilters;
        if(report.filters){
          filteredFilters = processFilters(report.filters);
        }
        filteredFilters = controlReportFilters(
          report.reportName,
          filteredFilters
        );
        try {
          promises.push(
            ReportService.fetchReport(
              report.reportName,
              filteredFilters,
              processedDynamicColumns
            )
          );
        } catch (error) {
          console.log(error);
        }
      });
    let reportData = {};

    Promise.allSettled(promises).then((values) => {
      values.map((response, index) => {
        let reponseData = response?.value?.data;
        let jsonData =
          reponseData?.httpStatus === 200 && reponseData?.response
            ? JSON.parse(reponseData.response)
            : [];
        reportData[[reports[index].reportName]] = jsonData || [];
      });
      dispatch({
        type: types.SET_REPORT_DATA,
        payload: reportData,
      });
      dispatch({
        type: types.SET_LOADING,
        payload: { isLoading: false },
      });
    });
  };
  export const setReportDatatablecount = (reports) =>async (dispatch)=> {
    return new Promise((resolve, reject) => {
      try {
        let promises = [];
        let tableDatacount = {};
  
        reports.forEach((report) => {
          try {
            promises.push(
              ReportService.fetchReport(report.tablecount)
            );
          } catch (error) {
            console.log(error);
          }
        });
  
        Promise.allSettled(promises).then((values) => {
          values.map((response, index) => {
            let reponseData = response?.value?.data;
            let jsonData =
              reponseData?.httpStatus === 200 && reponseData?.response
                ? JSON.parse(reponseData.response)
                : [];
            tableDatacount[[reports[index].tablecount]] = jsonData || [];
          });
  
          // dispatch({
          //   type: types.SET_CURRENT_REPORTS_TABLE_COUNT,
          //   payload: { tableDatacount: tableDatacount },
          // });
  
          resolve({ tableDatacount });
        });
      } catch (error) {
        reject("Error dispatching setReportDatatablecount");
      }
    });
  };
// export const setReportDatatablecount =
//   (reports, filters, dynamicColumns) => async (dispatch) => {
//   debugger
//     let promises = [];
//     reports &&
//       reports.forEach((report) => {
//         // let filteredFilters = processedFilters;
//         // filteredFilters = controlReportFilters(
//         //   report.reportName,
//         //   filteredFilters
//         // );
//         try {
//           promises.push(
//             ReportService.fetchReport(
//               report.tablecount,
//               // filteredFilters,
//               // processedDynamicColumns
//             )
//           );
//         } catch (error) {
//           console.log(error);
//         }
//       });
//     let tableDatacount = {};

//     Promise.allSettled(promises).then((values) => {
//       values.map((response, index) => {
//         let reponseData = response?.value?.data;
//         let jsonData =
//           reponseData?.httpStatus === 200 && reponseData?.response
//             ? JSON.parse(reponseData.response)
//             : [];
//             tableDatacount[reports[index].tablecount] = jsonData || [];
//       });
//       dispatch({
//         type: types.SET_CURRENT_REPORTS_TABLE_COUNT,
//         payload: {tableDatacount:tableDatacount},
//       });
//       // dispatch({
//       //   type: types.SET_LOADING,
//       //   payload: { isLoading: false },
//       // });
//     });
//   };

export const setCurrentReports = (reports,executeReports) => (dispatch) => {
  dispatch({
    type: types.SET_CURRENT_REPORTS,
    payload: { currentReports: reports,executeReports:(executeReports?executeReports:true) },
  });
};

export const setLoading = (isLoading) => (dispatch) => {
  dispatch({
    type: types.SET_LOADING,
    payload: { isLoading: isLoading },
  });
};

export const setParams = (params) => (dispatch) => {
  dispatch({
    type: types.SET_PARAMS,
    payload: params,
  });
};
