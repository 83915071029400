
const initialState = {
    isLoading: true,
    nestedFields:["Region","CountryName","ProductGroup","Businessbrand"],
    formFields: {
        "Whats New" : [
            {
                label: "Title",
                type: "input",
                dataIndex: "TITLE"
              },
            {
              label: "Short Description",
              type: "input",
              dataIndex: "SHORT_DESCRIPTION"
            },
            {
              label: "Long Description",
              type: "input",
              dataIndex: "LONG_DESCRIPTION"
            },
            {
              label: "Category",
              type: "input",
              options: [{ label: "Data", value: "Data" }, { label: "System", value: "System" }],
              dataIndex: "CATEGORY"
            },
            {
              label: "Thumbnail Image",
              type: "file",
              dataIndex: "THUMBNAIL_IMAGE"
            },
            {
              label: "Type",
              type: "select",
              placeholder: "Select Type",
              options: [{ label: "PDF", value: "PDF" },{ label: "URL", value: "URL" }],
              dataIndex: "ATTACHMENT_TYPE"
            },
            {
              label: "URL",
              type: "input",
              dynamic: true,
              dataIndex: "URL"
            },
            {
              label: "PDF",
              dynamic: true,
              type: "file",
              dataIndex: "URL"
            }
          ],
        "System Notification": [
            {
                label: "Title",
                type: "input",
                dataIndex: "TITLE"
              },
            {
              label: "Short Description",
              type: "input",
              dataIndex: "SHORT_DESCRIPTION"
            },
            {
              label: "Long Description",
              type: "input",
              dataIndex: "LONG_DESCRIPTION"
            },
            {
              label: "Category",
              type: "input",
              options: [{ label: "Data", value: "Data" }, { label: "System", value: "System" }],
              dataIndex: "CATEGORY"
            },
            
            {
                label: "Target Resolution Date",
                type: "calendar",
                dataIndex: "TARGET_RESOLUTION_DATE"
              },
            
            {
              label: "Notes",
              type: "input",
              dataIndex: "NOTES"
            },
            {
              label: "Thumbnail Image",
              type: "file",
              dataIndex: "THUMBNAIL_IMAGE"
            },
            {
              label: "Status",
              type: "select",
              placeholder: "Select Status",
              options: [{ label: "Opened", value: "Opened" },{ label: "In Progress", value: "In Progress" },{ label: "Resolved", value: "Resolved" }],
              dataIndex: "STATUS"
            }
          ],
        "Training Videos": [
            {
                label: "Title",
                type: "input",
                dataIndex: "TITLE"
              },
            {
              label: "Short Description",
              type: "input",
              dataIndex: "SHORT_DESCRIPTION"
            },
            {
              label: "Long Description",
              type: "input",
              dataIndex: "LONG_DESCRIPTION"
            },
            {
              label: "Category",
              type: "input",
              options: [{ label: "Data", value: "Data" }, { label: "System", value: "System" }],
              dataIndex: "CATEGORY"
            },
            {
              label: "URL",
              type: "input",
              dataIndex: "URL"
            }
          ],
        "Tutorial of The Week": [
            {
                label: "Title",
                type: "input",
                dataIndex: "TITLE"
              },
            {
              label: "Short Description",
              type: "input",
              dataIndex: "SHORT_DESCRIPTION"
            },
            {
              label: "Long Description",
              type: "input",
              dataIndex: "LONG_DESCRIPTION"
            },
            {
              label: "Category",
              type: "radio",
              options: [{ label: "Data", value: "Data" }, { label: "System", value: "System" }],
              dataIndex: "CATEGORY"
            },
            
            {
              label: "Thumbnail Image",
              type: "file",
              dataIndex: "THUMBNAIL_IMAGE"
            },
            {
              label: "Type",
              type: "select",
              placeholder: "Select Type",
              options: [{ label: "PDF", value: "PDF" },{ label: "URL", value: "URL" }],
              dataIndex: "TYPE"
            },
            {
              label: "URL",
              type: "input",
              dynamic: true,
              dataIndex: "URL"
            },
            {
              label: "PDF",
              dynamic: true,
              type: "file",
              dataIndex: "URL"
            }
          ],
        "FAQs": [
            {
                label: "Questions",
                type: "input",
                dataIndex: "QUESTIONS"
              },
            {
              label: "Answers",
              type: "input",
              dataIndex: "ANSWERS"
            },
           
            {
              label: "Category",
              type: "input",
              options: [{ label: "Data", value: "Data" }, { label: "System", value: "System" }],
              dataIndex: "CATEGORY"
            },
            // {
            //   label: "Related URL",
            //   type: "input",
            //   dataIndex: "RELATED_URL"
            // },
            // {
            //   label: "Thumbnail Image",
            //   type: "file",
            //   dataIndex: "THUMBNAIL_IMAGE"
            // }
            {
              label: "Type",
              type: "select",
              placeholder: "Select Type",
              options: [{ label: "PDF", value: "PDF" },{ label: "URL", value: "URL" }],
              dataIndex: "TYPE"
            },
            {
              label: "URL",
              type: "input",
              dynamic: true,
              dataIndex: "URL"
            },
            {
              label: "PDF",
              dynamic: true,
              type: "file",
              dataIndex: "URL"
            }
          ],
          "Useful Links": [
            {
                label: "Application Name",
                type: "input",
                dataIndex: "APPLICATION_NAME"
              },
            {
              label: "Application Description",
              type: "input",
              dataIndex: "APPLICATION_DESCRIPTION"
            },
           
            {
              label: "Application URL",
              type: "input",
              
              dataIndex: "APPLICATION_URL"
            },
            {
              label: "Display Order",
              type: "input",
              dataIndex: "DISPLAY_ORDER"
            },
            {
              label: "Thumbnail Image",
              type: "file",
              dataIndex: "THUMBNAIL_IMAGE"
            }
          ]
    }
}

export default function (state = initialState, action) {
  // debugger
    switch (action.type) {

        case "SET_REPORT_DATA":
            console.log("SET_REPORT_DATA ", { ...state, ...action.payload })
            return { ...state, ...action.payload };
        case "SET_REPORTS":
            return { ...state, ...action.payload };
        case "SET_CURRENT_REPORTS":
          // debugger
            return { ...state, ...action.payload };
        case "SET_CURRENT_REPORTS_TABLE_COUNT":
          // debugger
            return { ...state, ...action.payload };
        case "SET_PARAMS":
            return { ...state, ...{nestedFields: action.payload} };
        case "SET_LOADING":
            return { ...state, ...action.payload };
        default:
            return state;
    }
}