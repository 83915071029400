import React from 'react';
import PropTypes from 'prop-types';

export default class Schema extends React.Component {

    static mapStateToProps(state, ownProps) {
        const {editMode} = state;
        return {           
            editMode
        }
    }

    static metadata = {
        displayName: 'For Each Container',
        container: true,
        visual: true,
        builderCompatible: true,
        thumbnail: undefined,
        categories: ['Container'],
        disconnected: true
    };

    static propTypes = {
        onlyFirst: PropTypes.bool.isRequired,
        injectedPropName: PropTypes.string,
        itemsResolver: PropTypes.string.isRequired,
        itemSpan: PropTypes.number,
        carousel: PropTypes.bool,
        carouselOptions: PropTypes.shape({
            accessibility: PropTypes.bool,
            adaptiveHeight: PropTypes.bool,
            arrows: PropTypes.bool,
            autoplay: PropTypes.bool,
            autoplaySpeed: PropTypes.number,
            centerMode: PropTypes.bool,
            centerPadding: PropTypes.string,
            className: PropTypes.string,
            cssEase: PropTypes.string,
            dots: PropTypes.bool,
            dotsClass: PropTypes.string,
            draggable: PropTypes.bool,
            easing: PropTypes.string,
            fade: PropTypes.bool,
            focusOnSelect: PropTypes.bool,
            infinite: PropTypes.bool,
            initialSlide: PropTypes.number,
            lazyLoad: PropTypes.oneOf(['ondemand', 'progressive']),
            pauseOnDotsHover: PropTypes.bool,
            pauseOnFocus: PropTypes.bool,
            pauseOnHover: PropTypes.bool,
            responsive: PropTypes.arrayOf(
                PropTypes.shape({
                    breakpoint: PropTypes.number,
                    slidesToShow: PropTypes.number,
                    slidesToScroll: PropTypes.number,
                    infinite: PropTypes.bool,
                    dots: PropTypes.bool,
                    initialSlide: PropTypes.number
                })
            ),
            rows: PropTypes.number,
            rtl: PropTypes.bool,
            slide: PropTypes.string,
            slidesPerRow: PropTypes.number,
            slidesToScroll: PropTypes.number,
            slidesToShow: PropTypes.number,
            speed: PropTypes.number,
            swipe: PropTypes.bool,
            swipeToSlide: PropTypes.bool,
            touchMove: PropTypes.bool,
            touchThreshold: PropTypes.number,
            useCSS: PropTypes.bool,
            useTransform: PropTypes.bool,
            variableWidth: PropTypes.bool,
            vertical: PropTypes.bool,
            waitForAnimate: PropTypes.bool
        }),
        containerStyle: PropTypes.object
    };

    static defaultProps = {
        mounted: true,
        resolvedItems:[],
        childrenRefs: [],
        injectedPropName: 'item',
        onlyFirst: true,
        carousel: false,
        mounted:true,
        carouselOptions: {
            childrenRefs: [],
            accessibility: true,
            adaptiveHeight: false,
            arrows: true,
            autoplay: false,
            autoplaySpeed: 3000,
            centerMode: false,
            centerPadding: "50px",
            className: "",
            cssEase: "ease",
            dots: false,
            dotsClass: "slick-dots",
            draggable: true,
            easing: "linear",
            edgeFriction: 0.35,
            fade: false,
            focusOnSelect: false,
            infinite: true,
            initialSlide: 0,
            pauseOnDotsHover: false,
            pauseOnFocus: false,
            pauseOnHover: true,
            rows: 1,
            rtl: false,
            slide: "div",
            slidesPerRow: 1,
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 500,
            swipe: true,
            swipeToSlide: false,
            touchMove: true,
            touchThreshold: 5,
            useCSS: true,
            useTransform: true,
            variableWidth: false,
            vertical: false,
            waitForAnimate: true
        },
        containerStyle: {}
    };

    static i18n = {
        default : "en-US"
    };

}