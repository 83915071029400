import { Form, Icon, Input, Button, Checkbox,Alert } from 'antd';
import React from 'react';
import "./login.css"
import { FcGoogle } from "react-icons/fc";
import Azurepic from "../../assest/azure.png"
class LoginForm extends React.Component {
  state={
    errorMsg:false
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.setState({errorMsg:false});
        fetch('/login', {
          method: 'POST',
          headers:{
            'Content-Type': 'application/x-www-form-urlencoded'
          },    
          body: new URLSearchParams({
              'username': values.username,
              'password': values.password
          })
      }).then((e)=>{
        if(e.status==200){
          // this.setState({errorMsg:false})
          window.location.href = '/';
        }else{
          this.setState({errorMsg:true})

        }
      
      })
        
      }
    });
  };
  handlesigninWithGoogle(){
    window.location.href = '/oauth2/authorization/google';
  }
  handlesigninWithAzure(){
    window.location.href = '/oauth2/authorization/azure';
  }
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <Form.Item >
          {getFieldDecorator('username', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Username"
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
            />,
          )}
        </Form.Item>
        {/* <Form.Item>
          {getFieldDecorator('remember', {
            valuePropName: 'checked',
            initialValue: true,
          })(<Checkbox>Remember me</Checkbox>)}
        </Form.Item> */}
        <Form.Item >
                <div
                  style={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <Button type="primary antd-override-manual-button-primary" htmlType="submit">
                    Submit
                  </Button>
                  {this.state.errorMsg?<Alert type="error" message="Invalid username or password" banner />:''}
                  
                  <Button type="primary antd-override-manual-button-primary google-btn make_center" onClick={this.handlesigninWithGoogle}>
                  <FcGoogle className="mr-4 google-azure" /> Sign in with your Google Account 
                  </Button>
                  <Button type="primary antd-override-manual-button-primary google-btn make_center" onClick={this.handlesigninWithAzure}>
                  <img src={Azurepic} className='google-azure' style={{height: "20px"}}></img> Sign in with your Azure Account 
                  </Button>
                </div>
              </Form.Item>
      </Form>
    );
  }
}

const WrappedNormalLoginForm = Form.create({ name: 'normal_login' })(LoginForm);

export default WrappedNormalLoginForm ;