import axios from "axios";
import React, { useEffect } from "react";
// export default AppComponent;
import { connect } from "react-redux"; 
import { setReportData,setLoading } from "../store/actions/report"


function FilterService(props) {

  const { filters,reports,setReportData,dynamicColumns,setLoading,executeReports } = props;

  useEffect(() => {
    console.log( " cal api service ",reports)
    if(reports && executeReports){
      setLoading(true);
      setReportData(reports,filters,dynamicColumns)
    }

  }, [reports,filters,dynamicColumns]);
    
    return null;
};

const mapStateToProps = (state, ownProps) => {
    return {
      executeReports:state.reports.executeReports,
      reports: state.reports.currentReports,
      filters: state.filter.valueFilters,
      dynamicColumns: state.filter.paramFilters
    };
  };

export default connect(mapStateToProps,{setReportData,setLoading})(FilterService);