import React, { Fragment } from "react";
import Schema from "./schema";
import "./custom-classes.css";
import { processNodes } from "./ProcessNodes";
import { extractParams, getParams } from "./ActionParamsExtractor";
const HtmlToReactParser = require("@innive/html-to-react-custom").Parser;
const context = "";

export default class SemanticComponent extends Schema {
  imageExists = null;

  constructor(props) {
    super(props);
    this.state = { elements: null };
  }

  componentDidMount() {
    this.getElements(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.getElements(nextProps);
  }

  hasAction(obj) {
    return Object.keys(obj).filter((attr) => obj[attr].includes("actions["));
  }

  checkIfImageExists(imageUrl) {
    return new Promise((resolve) => {
      var imageData = new Image();
      imageData.onload = function() {
        resolve(true);
      };
      imageData.onerror = function() {
        resolve(false);
      };
      imageData.src = imageUrl;
    });
  }

  async getElements(props) {
    // debugger;
    if (this.imageExists === null) {
      this.imageExists = await this.checkIfImageExists(
        `${context}/static/icons/trend_neutral.svg`
      );
      let elements = this.getReactElements(props);
      this.setState({
        elements: elements,
      });
    } else {
      this.setState({
        elements: this.getReactElements(props),
      });
    }
  }

  getReactElements(props) {
    // debugger;
    const that = this;
    const { value, actions } = props;
    const processedHtml = extractParams(value);
    const extractedParams = getParams();
    let htmlToReactParser = new HtmlToReactParser();
    let isValidNode = function() {
      return true;
    };
    const processingInstructions = processNodes(
      this.imageExists,
      context,
      props
    );

    const preprocessingInstructions = [
      {
        shouldPreprocessNode: function(node) {
          const { attribs } = node;
          if (attribs) {
            const foundAttrs = that.hasAction(attribs);
            return foundAttrs && foundAttrs.length;
          }
          return false;
        },
        preprocessNode: function(node, children) {
          const { attribs } = node;
          if (attribs) {
            const foundAttrs = that.hasAction(attribs);
            for (let attr of foundAttrs) {
              const currAttr = attribs[attr];
              const actionMatches = currAttr.match(/\[(.*?)\]/);
              const matchedActionValue = actionMatches && actionMatches[1];
              const paramsMatches = currAttr.match(/semantic_params\[(.*?)\]/g);
              const paramsList = [];
              if (paramsMatches && paramsMatches.length) {
                for (let paramsMatch of paramsMatches) {
                  paramsList.push(
                    extractedParams[Number(paramsMatch.match(/[0-9]+/)[0])]
                  );
                }
              }

              const actionIndex = !isNaN(matchedActionValue)
                ? Number(matchedActionValue)
                : null;
              if (actionIndex != null && actions && actions[actionIndex]) {
                node.attribs[attr] = () => {
                  try {
                    if (typeof actions[actionIndex] === "function") {
                      that.props.dispatch(actions[actionIndex](...paramsList));
                    } else if (Array.isArray(actions[actionIndex])) {
                      that.props.dispatch(actions[actionIndex]);
                    }
                  } catch (e) {
                    if (window.localStorage.dev) {
                      console.log("Semantic Component Error", {
                        tag: node.name,
                        paramName: attr,
                        paramsList,
                        error: e,
                      });
                    }
                  }
                };
              }
            }
          }
        },
      },
    ];

    return htmlToReactParser.parseWithInstructions(
      processedHtml,
      isValidNode,
      processingInstructions,
      preprocessingInstructions
    );
  }

  render() {
    return <Fragment>{this.state.elements}</Fragment>;
  }
}
