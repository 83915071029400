import React from "react";
import Schema from "./schema";
import { connect } from "react-redux";
import { Collapse } from 'antd';
import SemanticComponent from "../../../components/semantic-component/component";
import { setParam } from "../../../store/actions/param";
import "./style.css";

const { Panel } = Collapse;


class NotificationTile extends Schema {

  render() {
    
    const { data = {}, accordionTile, landingPageSelectedTab,setParam ,notification_colors } = this.props;
    const notification_color = notification_colors[data.CATEGORY]
    const date = data.UPDATED_DATE && new Date(data.UPDATED_DATE).toLocaleString();


    const handleThumbnailClick = (data) => {
      
      if(data && data.URL && (landingPageSelectedTab=="Tutorial of The Week" || landingPageSelectedTab=="Whats New"  )) {
        window.open(data.URL, "_blank");
      } else if (data && data.URL && landingPageSelectedTab=="Training Videos") {
          setParam({videoPlayerModal: true, videoPlayerModalUrl: data.URL})
      }
    }

    const getYoutubeId = (url) =>{
      url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
   }

    if (accordionTile) {
      return (
        <div className="is-row is-width-100 is-fade" onClick={() => null}>
          <div className="is-width-100">
            <Collapse
              bordered={false}
              defaultActiveKey={['1']}        
            >
              <Panel header={
                <div className="is-row is-mar-1 is-font-size-sm is-no-gutter faq-tab" style={{backgroundColor:'#f9f9f9',color:'#000'}} >
                <div className="is-col-12 is-border">
                    <div className="is-row is-display-flex is-no-gutter ">
                        <div size="1" className="is-col-1 is-display-flex is-align-items-center is-pad-1" style={{backgroundColor:'#E8EBF3'}} >
                            <div className="is-row  is-no-gutter ">
                                <div  className="is-col-12 is-display-flex is-hu-4 is-justify-content-center is-cursor-pointer">
                                  <img src={data.THUMBNAIL_IMAGE || "https://cdn-icons-png.flaticon.com/512/258/258290.png"} />
                                </div>
                            </div>
                        </div>
                        <div size="11" className="is-col-11 is-pad-1">
                            <div className="is-row  is-no-gutter ">
                                <div size="12" className="is-col-12 is-display-flex is-flex-column">
                        <div className="is-row  is-no-gutter ">
                        <div className="is-col-12 is-display-flex is-justify-content-between">
                        <span className="is-font-size-xs semantic-component-text ">{date || `-`}</span>
                        {/* <span  removeborder="true" className={`ant-tag ant-tag-has-color  is-text-white is-bg-`+notification_color} >{data.CATEGORY}</span> */}
                        </div>
                        </div>
                          
                          <span className="is-font-size-sm semantic-component-text ">{data.QUESTIONS}</span>
                          <span className="is-font-size-xs semantic-component-text discr-txt">-</span>
                      </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              } key={data.QUESTIONS} showArrow={false} >
                <p className="is-pad-1">{data.ANSWERS}</p>
              </Panel>
            </Collapse>
          </div>
        </div>
      );
    } else if(landingPageSelectedTab=="Training Videos"){
      return (
        <>
        <div className="is-row is-width-100 is-fade" onClick={() => null}>
          <SemanticComponent
            actions={[()=> {handleThumbnailClick(data)}]}
            value={`<row class="is-mar-1 is-font-size-sm" style="background-color:#F9F9F9">
            <column class="is-border">
                <row class="is-display-flex">
                    <column size=1 class="is-display-flex is-align-items-center is-pad-1 " style="background-color:#E8EBF3">
                        <row>
                            <column title=${data.URL} onClick="actions[0]" class="is-display-flex is-hu-4 is-justify-content-center is-cursor-pointer">
                              
                              <image src=${data.URL ? `https://img.youtube.com/vi/${getYoutubeId(data.URL)}/0.jpg`  : "https://cdn-icons-png.flaticon.com/512/258/258290.png"}></image>
                            </column>
                        </row>
                    </column>
                    <column size=11 class="is-pad-1" >
                        <row>
                            <column size=12 class="is-display-flex is-flex-column" style="">
                    <row>
                    <column class="is-display-flex is-justify-content-between">
                    <text class="is-font-size-xs">${date || `-`}</text>
                    <text  removeborder="true" class="ant-tag ant-tag-has-color is-text-white is-bg-${notification_color}" style="backgroundColor:${notification_color}">${data.CATEGORY}</text>

                    </column>
                    </row>
                      
                      <text class="is-font-size-sm">${data.TITLE}</text>
                      <text class="is-font-size-xs discr-txt">${data.SHORT_DESCRIPTION}</text>
                  </column>
                           
                        </row>
                    </column>
                </row>
            </column>
        </row>`}
          />
        </div>
        </>
      );

    } else {

      return (
        <>
        <div className="is-row is-width-100 is-fade" onClick={() => null}>
          <SemanticComponent
            actions={[()=> {handleThumbnailClick(data)}]}
            value={`<row class="is-mar-1 is-font-size-sm" style="background-color:#F9F9F9">
            <column class="is-border">
                <row class="is-display-flex">
                    <column size=1 class="is-display-flex is-align-items-center is-pad-1 " style="background-color:#E8EBF3">
                        <row>
                            <column title=${data.URL} onClick="actions[0]" class="is-display-flex is-hu-4 is-justify-content-center is-cursor-pointer">
                              <image src=${data.THUMBNAIL_IMAGE || "https://cdn-icons-png.flaticon.com/512/258/258290.png"}></image>
                            </column>
                        </row>
                    </column>
                    <column size=11 class="is-pad-1" >
                        <row>
                            <column size=12 class="is-display-flex is-flex-column" style="">
                    <row>
                    <column class="is-display-flex is-justify-content-between">
                    <text class="is-font-size-xs">${date || `-`}</text>
                    <text  removeborder="true" class="ant-tag ant-tag-has-color is-text-white is-bg-${notification_color}"  style="backgroundColor:${notification_color}">${data.CATEGORY}</text>

                    </column>
                    </row>
                      
                      <text class="is-font-size-sm">${data.TITLE}</text>
                      <text class="is-font-size-xs discr-txt">${data.SHORT_DESCRIPTION}</text>
                  </column>
                           
                        </row>
                    </column>
                </row>
            </column>
        </row>`}
          />
        </div>
        </>
      );

    }


  }
}

const mapStateToProps = (state, ownProps) => ({
});

export default connect(mapStateToProps, {setParam})(NotificationTile);
