import { combineReducers } from "redux";
import reports from './report';
import filter from './filter';
import param from "./param";
import staticReport from "./staticReport"

export default combineReducers({
  reports,
  filter,
  param,
  staticReport
});
