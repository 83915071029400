export default class Events {

    static EVENTS = {
        DISPATCH_ON_HOVER: 'DISPATCH_ON_HOVER',
    };

    static HANDLERS = {
        [Events.EVENTS.DISPATCH_ON_HOVER]: (state, payload) => {
            return {
                onHoverId: {
                    $set: payload.elementId
                }
            };
        }
    };

    static DISPATCH_ON_HOVER(elementId) {
        return {
            type: Events.EVENTS.DISPATCH_ON_HOVER,
            payload: {
                elementId
            }
        }
    }

}