import { types } from "./types";

export const setParam = (payload) => async dispatch => {
   try {
    dispatch({
        type: types.SET_PARAM,
        payload: payload
    });

    } catch (error) {
        console.log(error);
    }
}
