import React, {useState} from "react";
import Schema from "./schema";
import { connect } from "react-redux";
import {setParam} from "../../../store/actions/param";
import "./style.css";
import {Modal} from 'antd';
import VideoPlayer from "../../../components/video-player";

function ModalVideoPlayer({url,showModal,setParam}) {
    
    //  const [showModal, setShowModal] = useState(false);

    const handleCancelClick = () => {
      setParam({videoPlayerModal:false});
    }

    const handleOKClick = () => {
      setParam({videoPlayerModal: false});
    }
    
  

  return (

    <Modal
    className='modal-video-player'
    footer=''
    width={"90%"}
    bodyStyle={{height:'600px',padding:0}}
    zIndex={1001}
    closable={true}
    title={null}
    visible={showModal}
    onOk={handleOKClick}
    onCancel={handleCancelClick}
    destroyOnClose={true}
    >
        <VideoPlayer url={url} />
      </Modal>
    
         
        
    
  );
}

const mapStateToProps = (state, ownProps) => ({ 
  showModal: state.param.videoPlayerModal,
  url: state.param.videoPlayerModalUrl 
});

export default connect(mapStateToProps, {setParam})(ModalVideoPlayer);
