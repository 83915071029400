import { Col, Row } from "react-bootstrap";

import { Breadcrumb, Layout, Menu, theme } from "antd";
import K12Logo from "../images/k12.png";
const { Header, Content, Footer } = Layout;
const isK12 = window.location.origin.includes('k12360.innive.io')
function AuthScreens({ component }) {
  return (
    <div>
      <Header
        style={{
          position: "sticky",
          background: "rgb(227 227 227)",
          top: 0,
          height: "74px",
          zIndex: 1,
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div className="demo-logo" >  {/* style={{ position: "fixed", top: "-7px" }}> */}
          <img style={{height: "100%",
    width: isK12 ? "63%" : "100%",
    maxWidth: "300px",
    padding: "2px 5px"}}src={"/images/client_logo.png"}></img>
        </div>
      </Header>
      <Row align={"middle"} style={{ height: "80vh" , display:"flex",justifyContent:"center" , alignItems: "center", marginTop : "-4rem"}}>
        <Col style={{width:"28%"}} span={24}>{component}</Col>
      </Row>
    </div>
  );
}

export default AuthScreens;
