import React,{ useEffect, useMemo } from "react";
import Schema from "./schema";
import { connect } from "react-redux";
import ForEachContainer from "../../../components/for-each-container";
import CarouselContainer from "../../../components/carousel-container";
import MetricImageTile from "../../../templates/tiles/metric-tile-with-image"
import "./style.css";

function UsefulLinkTile(props){
  // debugger
  console.log(" UsefulLinkTile re render")

  const {tileClick,data,dataMapping,showAllClick} = props;
 
  const mapData = (data) =>{
      const mappingKeys = Object.keys(dataMapping);
      data && data.map(item => {
        mappingKeys.map(key => {
          item[key] = item[dataMapping[key]]
        })
      })

      return data
  }

  // const finalData = useMemo(()=>{
  //   return dataMapping && data && data.length ?mapData(JSON.parse(JSON.stringify(data))):data
  // },[data])
  let finalData =  dataMapping && data && data.length ?mapData(JSON.parse(JSON.stringify(data))):data;
  finalData = finalData && finalData.length ?  finalData.filter(e=> e.ROLE !="COUNSELOR_ACADEMIC" && e.ROLE != "COUNSELOR_SPED_CMR") : finalData
  // useEffect(()=>{

  // },[finalData])


  return (
    <div className="is-row is-flex-column is-marz-5 is-marz-x-5 useFulName" style={{margin:'10px 50px'}}>
      <div className="is-display-flex is-justify-content-between">
        <div className="is-font-size-lg is-pad-1">Modules</div>
        
        {/* <div onClick={showAllClick} className="is-font-size-sm is-cursor-pointer">Show All</div> */}
      </div>
      {/* <div className="useFulName"> */}
        {data &&  data.length && <ForEachContainer
          className="useFulName"
          // itemSpan={"3 is-pad-x-2"}
          onlyFirst={false}
           //numberOfRow={data.length<=4?1:2}
          numberOfRow={2}
          itemsResolver={finalData}
          resolvedItems={finalData}
          carousel={true}
          carouselOptions={{slidesPerRow:4}}
          slidesToShow={1}
          slidesToScroll={1}
          tileClick={tileClick}
        >
          <MetricImageTile tileClick={tileClick}/>
        </ForEachContainer>}
        {/* </div> */}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => {  
};

export default connect(mapStateToProps, null)(UsefulLinkTile);
