import { Button, Avatar, Badge, Tag, Switch, Rate, Progress, Card,Icon } from 'antd';
import { ReactSVG } from 'react-svg';
import React, { Fragment } from 'react';
import Events from './events';
import VideoPlayer from "../../components/video-player";
const HtmlToReact = require('@innive/html-to-react-custom');
const { CheckableTag } = Tag;

export const processNodes = (imageExists, context, mainProps) => {
    const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
    const getProcessedProps = (node, index, processNodeDefinitions, camelCaseAttribs) => {
        let attribs = processNodeDefinitions.getProcessedProps(node, index);
        if (camelCaseAttribs) {
            for (let attrib of Object.keys(attribs)) {
                const foundAttrib = camelCaseAttribs.find(attr => attr.toLowerCase() === attrib);
                if (foundAttrib) {
                    attribs[foundAttrib] = attribs[attrib];
                    delete attribs[attrib];
                }
            }
        }
        return attribs;
    }
    const importAll = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            images[item.replace('./', '')] = r(item).replace('./', `${context}/provider/`);
        });
        return images;
    }

    const getBooleanValues = (attribs, values) => {
        for (let value of values) {
            const attrValue = attribs[value.toLowerCase()];
            if (attrValue && typeof attrValue === 'string') {
                if (attrValue.toLowerCase() === 'true') {
                    attribs[value] = true;
                } else {
                    attribs[value] = false;
                }
            }
        }
        return attribs;
    }

    const getNumberValues = (attribs, values) => {
        for (let value of values) {
            const attrValue = attribs[value.toLowerCase()];
            if (attrValue && !isNaN(attrValue)) {
                attribs[value] = Number(attrValue);
            }
        }
        return attribs;
    }

    const renderIconTag = (node, index) => {
        const CAMEL_CASE_ATTRIBS = ['twoToneColor'];
        const attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
        const { color } = attribs;
        const size = attribs.size || 'default';
        let style = attribs.style || {};
        let classAttr = attribs.className || '';
        classAttr = classAttr.concat(' semantic-icon ');
        switch (size) {
            case 'large': {
                style.fontSize = 'var(--is-icon-size-3)';
                break;
            }
            case 'default': {
                style.fontSize = 'var(--is-icon-size-2)';
                break;
            }
            case 'small': {
                style.fontSize = 'var(--is-icon-size-1)';
                break;
            }
            default: {
                if (Number(size)) {
                    style.fontSize = `var(--is-icon-size-${Number(size)})`;
                } else {
                    style.fontSize = 'var(--is-icon-size-2)';
                }
                break;
            }
        }
        if (color) {
            style.color = `var(--is-${color})`;;
            delete attribs.color;
        }
        attribs.style = style;
        attribs.className = classAttr;
        return (
            <Icon {...attribs}>
            </Icon>
        );
    }

    const renderTagTag = (node, children, index) => {
        const CAMEL_CASE_ATTRIBS = ['textColor', 'iconStyle', 'iconTheme', 'iconFill', 'iconTwoToneColor', 'removeBorder', 'highlightBorder', 'borderColor'];
        const BOOLEAN_VALUES = ['closable', 'visible', 'checked', 'removeBorder', 'highlightBorder', 'borderColor'];
        let attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
        const { icon, iconStyle, iconTheme, iconFill, iconTwoToneColor, textColor, color, removeBorder, highlightBorder, borderColor, size } = attribs;
        const hasCheckedAttr = attribs.hasOwnProperty('checked');
        let classAttr = attribs.className || '';
        let styleAttr = '';
        let iconAttribs = {};
        const id = `semantic-tag-${Date.now()}-${index}`;
        if (iconStyle) {
            const processed = processNodeDefinitions.getProcessedProps({ attribs: { style: iconStyle } }, index);
            iconAttribs.style = processed.style;
        }
        if (iconTheme) {
            iconAttribs.theme = iconTheme;
        }
        if (iconFill) {
            iconAttribs.fill = iconFill;
        }
        if (iconTwoToneColor) {
            iconAttribs.twoToneColor = iconTwoToneColor;
        }
        if (icon) {
            iconAttribs.className = ' is-pad-right-1 ';
        }
        classAttr = classAttr.concat(' semantic-ant-tag ');
        attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
        if (textColor) {
            styleAttr = styleAttr.concat(` color: var(--is-${textColor}) !important;`);
            delete attribs.textColor;
        }
        if (color) {
            attribs.color = `var(--is-${color})`;
        }
        if (removeBorder) {
            styleAttr = styleAttr.concat(' border-color: transparent !important;');
        }
        if (highlightBorder) {
            styleAttr = styleAttr.concat(` border: 1.5px solid var(--is-${color}-border) !important;`);
        }
        if (borderColor) {
            styleAttr = styleAttr.concat(` border: 1.5px solid var(--is-${borderColor}) !important;`);
        }
        if (size && size === 'large') {
            classAttr = classAttr.concat(' is-font-size-md ');
        }
        attribs.className = classAttr;
        return (
            <Fragment key={index}>
                <style>
                    {`
                        #${id} {
                            ${styleAttr}
                        }
                    `}
                </style>
                {!hasCheckedAttr &&
                    <Tag id={id} {...attribs}>
                        {icon && <Icon type={icon} {...iconAttribs} />}
                        {children}
                    </Tag>
                }
                {hasCheckedAttr &&
                    <CheckableTag id={id} {...attribs}>
                        {icon && <Icon type={icon} {...iconAttribs} />}
                        {children}
                    </CheckableTag>
                }
            </Fragment>
        );
    }

    const renderStatusTag = (node, children, index) => {
        const CAMEL_CASE_ATTRIBS = ['customColor'];
        const BOOLEAN_VALUES = ['inverse', 'highlight'];
        let attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
        attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
        const { value, shape, description, highlight, customColor } = attribs;
        const isIcon = shape && shape !== 'rounded' && shape !== 'circle';
        let styleAttr = attribs.style || {};
        let classAttr = attribs.className || '';
        let iconColor;
        let textColor;
        let color;
        let badgeAttribs = {};
        let badgeClass = 'status-badge';
        classAttr = classAttr.concat(' div-status-badge ');
        if (shape === 'rounded') {
            badgeClass = badgeClass.concat(' status-badge-rounded ');
        }
        switch (value) {
            case 'good':
                color = 'success';
                break;
            case 'regular':
                color = 'warning';
                break;
            case 'bad':
                color = 'danger';
                break;
            case 'custom':
                color = customColor;
                break;
            default:
                color = 'danger';
        }
        if (!isIcon) {
            badgeAttribs.color = `var(--is-${color})`;
            textColor = `var(--is-${color}-inverse-darkest)`;
        } else {
            classAttr = classAttr.concat(' div-status-badge-with-icon ');
            iconColor = `var(--is-${color})`;
            textColor = `var(--is-${color}-inverse-darkest)`;
        }
        if (highlight) {
            if (isIcon) {
                styleAttr.paddingLeft = `var(--is-su-1)`;
            }
            styleAttr.backgroundColor = `var(--is-${color}-inverse)`;
            classAttr = classAttr.concat(' div-status-badge-highlight ');
            if (isIcon) {
                classAttr = classAttr.concat(' is-hu-2 ');
            }
            textColor = `var(--is-${color})`;
        }
        badgeAttribs.className = badgeClass;
        attribs.style = styleAttr;
        attribs.className = classAttr;
        return (
            <span {...attribs}>
                <Badge {...badgeAttribs}>
                    {isIcon && <Icon type={shape} theme="filled" className="is-valign-middle" style={{ color: iconColor }}></Icon>}
                    {isIcon && description && <span className="is-mar-left-1 is-font-size-xxs is-valign-middle" style={{ color: textColor }}>{description}</span>}
                    {children}
                </Badge>
                {!isIcon && description && <span className="is-mar-left-2 is-font-size-xxs" style={{ color: textColor }}>{description}</span>}
            </span>
        );
    }

    const renderTrendTag = (node, index) => {
        const CAMEL_CASE_ATTRIBS = ['leftValue', 'valueClass'];
        let attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
        const { up, positive, value, leftValue, valueClass } = attribs;
        let classAttr = attribs.className || '';
        let fileName = 'trend_';
        let trendStatus;
        classAttr = classAttr.concat(' svg-wrapper ');
        if (up && up === 'true') {
            fileName = fileName.concat('up_');
        } else if (up && up === 'false') {
            fileName = fileName.concat('down_');
        } else {
            trendStatus = 'neutral';
            fileName = fileName.concat('neutral');
        }
        if (!fileName.includes('neutral')) {
            if (positive && positive === 'true') {
                trendStatus = 'positive';
                fileName = fileName.concat('positive');
            } else {
                trendStatus = 'negative';
                fileName = fileName.concat('negative');
            }
        }
        fileName = fileName.concat('.svg');
        attribs.className = classAttr;
        const SERVER_PATH = `${context}/static/icons/${fileName}`;
        const finalPath = imageExists ? SERVER_PATH : imgList[fileName];
        return (
            <Fragment key={attribs.key}>
                {leftValue && <span className={`trend-left-text trend-${trendStatus} ${valueClass || ''}`}>{leftValue}</span>}
                <ReactSVG {...attribs} src={finalPath}>
                </ReactSVG>
                {!leftValue && value && <span className={`trend-text trend-${trendStatus} ${valueClass || ''}`}>{value}</span>}
            </Fragment>
        );
    }

    const renderLandingSubtitleTag = (node, children, index) => {
        const BOOLEAN_VALUES = ['ellipsis'];
        let attribs = processNodeDefinitions.getProcessedProps(node, index);
        attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
        const { bold, ellipsis, className } = attribs;
        let classAttr = className || '';
        classAttr = classAttr.concat(' semantic-landing-subtitle ');
        if (ellipsis) {
            classAttr = classAttr.concat(' is-text-ellipsis ');
            attribs.title = (children && children[0]) || '';
        }
        if (bold) {
            classAttr = classAttr.concat(' is-font-weight-bold ');
        }
        attribs.className = classAttr;
        return (
            <span {...attribs}>
                {children}
            </span>
        );
    }

    const renderPageTitleTag = (node, children, index) => {
        const BOOLEAN_VALUES = ['ellipsis'];
        let attribs = processNodeDefinitions.getProcessedProps(node, index);
        attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
        const { ellipsis, className } = attribs;
        let classAttr = className || '';
        classAttr = classAttr.concat(' semantic-page-title ');
        if (ellipsis) {
            classAttr = classAttr.concat(' is-text-ellipsis ');
            attribs.title = (children && children[0]) || '';
        }
        attribs.className = classAttr;
        return (
            <span {...attribs}>
                {children}
            </span>
        );
    }

    const renderTextTag = (node, children, index) => {
        const BOOLEAN_VALUES = ['ellipsis'];
        let attribs = processNodeDefinitions.getProcessedProps(node, index);
        attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
        const { ellipsis, className } = attribs;
        let classAttr = className || '';
        classAttr = classAttr.concat(' semantic-component-text ');
        if (ellipsis) {
            classAttr = classAttr.concat(' is-text-ellipsis ');
            attribs.title = (children && children[0]) || '';
        }
        attribs.className = classAttr;
        return (
            <span {...attribs}>
                {children}
            </span>
        );
    }

    const renderGenericTextTag = (node, children, index) => {
        const attribs = processNodeDefinitions.getProcessedProps(node, index);
        return (
            <span {...attribs}>
                {children}
            </span>
        );
    }

    const renderImageTag = (node, index) => {
        const attribs = processNodeDefinitions.getProcessedProps(node, index);
        const { height, width } = attribs;
        if (height) {
            attribs.style = attribs.style || {};
            attribs.style.height = height;
        }
        if (width) {
            attribs.style = attribs.style || {};
            attribs.style.width = width;
        }
        return (
            <img {...attribs}>
            </img>
        );
    }

    const renderVideoTag = (node, index) => {
        const attribs = processNodeDefinitions.getProcessedProps(node, index);
        const { height, width } = attribs;
        if (height) {
            attribs.style = attribs.style || {};
            attribs.style.height = height;
        }
        if (width) {
            attribs.style = attribs.style || {};
            attribs.style.width = width;
        }
        return (
            <VideoPlayer {...attribs}>
            </VideoPlayer>
        );
    }

    const renderProgressTag = (node, children, index) => {
        const CAMEL_CASE_ATTRIBS = ['showInfo', 'strokeLinecap', 'strokeColor', 'successPercent', 'trailColor', 'strokeWidth', 'gapDegree', 'gapPosition'];
        const BOOLEAN_VALUES = ['showInfo'];
        const NUMBER_VALUES = ['percent', 'successPercent', 'strokeWidth', 'steps', 'width', 'gapDegree'];
        let attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
        attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
        attribs = getNumberValues(attribs, NUMBER_VALUES);
        let { strokeColor, status } = attribs;
        if (strokeColor && typeof strokeColor === 'string') {
            const varName = `is-${strokeColor}`.replace(/-([\S])/g, (match, s1) => { return s1.toUpperCase(); });
            attribs.strokeColor = mainProps.variables[varName];
        }
        return (
            <Progress {...attribs} format={["success", "exception", "normal", "active"].includes(status) ? false : () => <Icon type={status} style={{ color: attribs.strokeColor }} theme="outlined" />}>
                {children}
            </Progress>
        );
    }

    const renderRateTag = (node, children, index) => {
        const CAMEL_CASE_ATTRIBS = ['allowClear', 'allowHalf', 'autoFocus', 'defaultValue'];
        const BOOLEAN_VALUES = ['allowClear', 'allowHalf', 'autoFocus', 'disabled'];
        const NUMBER_VALUES = ['count', 'defaultValue', 'value'];
        let attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
        const { tooltips, icon } = attribs;
        attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
        attribs = getNumberValues(attribs, NUMBER_VALUES);
        if (tooltips) {
            try {
                attribs.tooltips = JSON.parse(tooltips);
            } catch (e) {
                attribs.tooltips = undefined;
            }
        }
        if (icon) {
            attribs.character = <Icon type={icon}></Icon>;
        }
        return (
            <Rate {...attribs}>
                {children}
            </Rate>
        );
    }

    const imgList = [];//importAll(require.context('./default/icons', false, /\.(png|jpe?g|svg)$/));

    return [
        {
            shouldProcessNode: function (node) {
                return node.name === 'row';
            },
            processNode: function (node, children, index) {
                const BOOLEAN_VALUES = ['isHover'];
                let attribs = processNodeDefinitions.getProcessedProps(node, index);
                attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
                const { gutter, className, isHover } = attribs;
                let classAttr = className || '';
                if (!classAttr.includes('is-row')) {
                    classAttr = `is-row ${classAttr}`;
                }
                switch (gutter) {
                    case 'none':
                        classAttr = classAttr.concat(' is-no-gutter ');
                        break;
                    case 'small':
                        classAttr = classAttr.concat(' is-gutter-sm ');
                        break;
                    case 'medium':
                        break;
                    case 'large':
                        classAttr = classAttr.concat(' is-gutter-lg ');
                        break;
                    default:
                        classAttr = classAttr.concat(' is-no-gutter ');
                }
                attribs.className = classAttr;
                const dispatchOnHover = (elementId) => {
                    if (isHover) {
                        mainProps.dispatch(Events.DISPATCH_ON_HOVER(elementId));
                    }
                }
                return (
                    <div {...attribs} onMouseEnter={() => { dispatchOnHover(attribs.id) }} onMouseLeave={() => { dispatchOnHover(null) }}>
                        {children}
                    </div>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                if (node.name === 'column') {
                    const { parent = {}, attribs } = node;
                    const parentAttrs = parent.attribs;
                    if (parentAttrs.ishover === 'true') {
                        const isParentHovered = mainProps.onHoverId === parentAttrs.id;
                        if (attribs.showonhover === 'true') {
                            return isParentHovered;
                        } else {
                            return !isParentHovered;
                        }
                    }
                    return true;
                }
                return false;
            },
            processNode: function (node, children, index) {
                const attribs = processNodeDefinitions.getProcessedProps(node, index);
                const size = (!isNaN(attribs.size) && attribs.size) || '12';
                let classAttr = attribs.className || '';
                classAttr = !classAttr.includes('is-col-') ? `is-col-${size} ${classAttr}` : classAttr;
                attribs.className = classAttr;
                return (
                    <div {...attribs}>
                        {children}
                    </div>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'button';
            },
            processNode: function (node, children, index) {
                const CAMEL_CASE_ATTRIBS = ['iconLeft', 'iconRight', 'htmlType'];
                const BOOLEAN_VALUES = ['disabled', 'ghost'];
                const CUSTOM_TYPES = ['secondary', 'accent', 'success', 'warning', 'danger'];
                let attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
                const { type, shape, loading, iconLeft, iconRight } = attribs;
                let classAttr = attribs.className || '';
                attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
                if (attribs.icon) {
                    delete attribs.icon;
                }
                if (iconLeft) {
                    delete attribs.iconLeft;
                }
                if (iconRight) {
                    delete attribs.iconRight;
                }
                if (children && children.length === 1 && children[0].trim().length === 0) {
                    children = null;
                }
                if (type && CUSTOM_TYPES.includes(type) && !attribs.disabled) {
                    classAttr = classAttr.concat(` ant-btn-${type} `);
                }
                if (shape && shape === 'circle') {
                    classAttr = classAttr.concat(' is-rounded-circle ');
                }
                if (loading) {
                    attribs = isNaN(loading) ? getBooleanValues(attribs, ['loading']) : { delay: getNumberValues(attribs, ['loading']) };
                }
                attribs.className = classAttr;
                return (
                    <Button {...attribs}>
                        {iconLeft && <Icon type={iconLeft} />}
                        {children}
                        {iconRight && <Icon type={iconRight} />}
                    </Button>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'avatar';
            },
            processNode: function (node, children, index) {
                const CAMEL_CASE_ATTRIBS = ['srcSet', 'iconStyle', 'iconTheme', 'iconFill', 'iconTwoToneColor'];
                const attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
                const { size, icon, iconStyle, iconTheme, iconFill, iconTwoToneColor } = attribs;
                let classAttr = attribs.className || '';
                let iconAttribs = { className: ' semantic-icon ' };
                if (Number(size)) {
                    classAttr = classAttr.concat(` is-icon-size-${Number(size)} `)
                }
                attribs.className = classAttr;
                if (icon) {
                    delete attribs.icon;
                }
                if (iconStyle) {
                    const processed = processNodeDefinitions.getProcessedProps({ attribs: { style: iconStyle } }, index);
                    iconAttribs.style = processed.style;
                }
                if (iconTheme) {
                    iconAttribs.theme = iconTheme;
                }
                if (iconFill) {
                    iconAttribs.fill = iconFill;
                }
                if (iconTwoToneColor) {
                    iconAttribs.twoToneColor = iconTwoToneColor;
                }
                return (
                    <Avatar {...attribs}>
                        {icon && <Icon type={icon} {...iconAttribs}></Icon>}
                        {children}
                    </Avatar>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'badge';
            },
            processNode: function (node, children, index) {
                const CAMEL_CASE_ATTRIBS = ['overflowCount', 'showZero', 'iconStyle', 'iconTheme', 'iconFill', 'iconTwoToneColor', 'notificationColor', 'notificationBgColor'];
                const NUMBER_VALUES = ['count', 'overflowCount'];
                const BOOLEAN_VALUES = ['dot'];
                let attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
                const { className, offset, icon, iconStyle, iconTheme, iconFill, iconTwoToneColor, notificationColor, notificationBgColor } = attribs;
                let classAttr = className || '';
                let iconAttribs = {};
                classAttr = classAttr.concat(' is-semantic-badge ');
                attribs = getNumberValues(attribs, NUMBER_VALUES);
                attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
                if (offset) {
                    try {
                        attribs.offset = JSON.parse(offset);
                    } catch (e) {
                        attribs.offset = undefined;
                    }
                }
                if (iconStyle) {
                    const processed = processNodeDefinitions.getProcessedProps({ attribs: { style: iconStyle } }, index);
                    iconAttribs.style = processed.style;
                }
                if (iconTheme) {
                    iconAttribs.theme = iconTheme;
                }
                if (iconFill) {
                    iconAttribs.fill = iconFill;
                }
                if (iconTwoToneColor) {
                    iconAttribs.twoToneColor = iconTwoToneColor;
                }
                if (notificationColor) {
                    attribs.style = attribs.style || {};
                    attribs.style.color = `var(--is-${notificationColor})`;
                }
                if (notificationBgColor) {
                    attribs.style = attribs.style || {};
                    attribs.style.backgroundColor = `var(--is-${notificationBgColor})`;
                }
                attribs.className = classAttr;
                return (
                    <Badge {...attribs}>
                        {icon && <Icon type={icon} {...iconAttribs} />}
                        {children}
                    </Badge>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'icon';
            },
            processNode: function (node, children, index) {
                return renderIconTag(node, index);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'tag';
            },
            processNode: function (node, children, index) {
                return renderTagTag(node, children, index);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'switch';
            },
            processNode: function (node, children, index) {
                const CAMEL_CASE_ATTRIBS = ['autoFocus', 'checkedChildren', 'defaultChecked', 'unCheckedChildren'];
                const BOOLEAN_VALUES = ['checked', 'defaultChecked', 'disabled', 'loading'];
                let attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
                attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
                return (
                    <Switch {...attribs}>
                        {children}
                    </Switch>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'trend';
            },
            processNode: function (node, children, index) {
                return renderTrendTag(node, index);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'status';
            },
            processNode: function (node, children, index) {
                return renderStatusTag(node, children, index);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'rate';
            },
            processNode: function (node, children, index) {
                return renderRateTag(node, children, index);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'landing-title';
            },
            processNode: function (node, children, index) {
                const BOOLEAN_VALUES = ['ellipsis'];
                let attribs = processNodeDefinitions.getProcessedProps(node, index);
                attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
                const { bold, boxed, ellipsis, className } = attribs;
                let classAttr = className || '';
                classAttr = classAttr.concat(' semantic-landing-title ');
                if (ellipsis) {
                    classAttr = classAttr.concat(' is-text-ellipsis ');
                    attribs.title = (children && children[0]) || '';
                }
                if (bold || boxed) {
                    classAttr = classAttr.concat(' is-font-weight-bold ');
                }
                if (boxed) {
                    classAttr = classAttr.concat(' semantic-landing-title-boxed ');
                }
                attribs.className = classAttr;
                return (
                    <span {...attribs}>
                        {children}
                    </span>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'landing-subtitle';
            },
            processNode: function (node, children, index) {
                return renderLandingSubtitleTag(node, children, index);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'page-title';
            },
            processNode: function (node, children, index) {
                return renderPageTitleTag(node, children, index);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'page-subtitle';
            },
            processNode: function (node, children, index) {
                const BOOLEAN_VALUES = ['ellipsis'];
                let attribs = processNodeDefinitions.getProcessedProps(node, index);
                attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
                const { ellipsis, className } = attribs;
                let classAttr = className || '';
                classAttr = classAttr.concat(' semantic-page-subtitle ');
                if (ellipsis) {
                    classAttr = classAttr.concat(' is-text-ellipsis ');
                    attribs.title = (children && children[0]) || '';
                }
                attribs.className = classAttr;
                return (
                    <span {...attribs}>
                        {children}
                    </span>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'title';
            },
            processNode: function (node, children, index) {
                const BOOLEAN_VALUES = ['ellipsis'];
                let attribs = processNodeDefinitions.getProcessedProps(node, index);
                attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
                const { ellipsis, className } = attribs;
                let classAttr = className || '';
                classAttr = classAttr.concat(' semantic-component-title ');
                if (ellipsis) {
                    classAttr = classAttr.concat(' is-text-ellipsis ');
                    attribs.title = (children && children[0]) || '';
                }
                attribs.className = classAttr;
                return (
                    <span {...attribs}>
                        {children}
                    </span>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'subtitle';
            },
            processNode: function (node, children, index) {
                const BOOLEAN_VALUES = ['ellipsis'];
                let attribs = processNodeDefinitions.getProcessedProps(node, index);
                attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
                const { ellipsis, className } = attribs;
                let classAttr = className || '';
                classAttr = classAttr.concat(' semantic-component-subtitle ');
                if (ellipsis) {
                    classAttr = classAttr.concat(' is-text-ellipsis ');
                    attribs.title = (children && children[0]) || '';
                }
                attribs.className = classAttr;
                return (
                    <span {...attribs}>
                        {children}
                    </span>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'legend';
            },
            processNode: function (node, children, index) {
                const BOOLEAN_VALUES = ['ellipsis', 'disabled'];
                let attribs = processNodeDefinitions.getProcessedProps(node, index);
                attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
                const { ellipsis, className, style, disabled } = attribs;
                let classAttr = className || '';
                attribs.style = style || {};
                classAttr = classAttr.concat(' semantic-component-legend ');
                if (ellipsis) {
                    classAttr = classAttr.concat(' is-text-ellipsis ');
                    attribs.title = (children && children[0]) || '';
                }
                if (disabled) {
                    classAttr = classAttr.concat(' is-text-muted ');
                    attribs.style.textDecorationLine = 'line-through';
                }
                attribs.className = classAttr;
                return (
                    <span {...attribs}>
                        {children}
                    </span>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'text';
            },
            processNode: function (node, children, index) {
                return renderTextTag(node, children, index);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'progress';
            },
            processNode: function (node, children, index) {
                return renderProgressTag(node, children, index);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'basic-metric';
            },
            processNode: function (node, children, index) {
                const CAMEL_CASE_ATTRIBS = ['iconColor', 'prefixText', 'prefixTag', 'prefixStatus', 'valueColor', 'valueTooltip', 'valueBgColor', 'suffixColor', 'trendValue', 'trendLeftValue', 'trendValueColor', 'trendType', 'trendPositive', 'progressValue', 'progressColor', 'labelEllipsis'];
                const NUMBER_VALUES = ['progressValue'];
                const BOOLEAN_VALUES = ['labelEllipsis'];
                let attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
                attribs = getNumberValues(attribs, NUMBER_VALUES);
                attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
                const { label, icon, iconColor, prefixText, prefixTag, prefixStatus, value, valueColor, valueTooltip, valueBgColor, suffix, suffixColor, trendType, trendPositive, trendValue, trendLeftValue, size, orientation, progressValue, progressColor, labelEllipsis } = attribs;
                const hasTrend = trendType || trendPositive || trendValue || trendLeftValue;
                const hasPrefix = prefixText || prefixTag || prefixStatus;
                const hasProgress = attribs.hasOwnProperty('progressValue');
                let iconNode;
                let prefixNode;
                let trendNode;
                let labelNode;
                let valueNode;
                let suffixNode;
                let progressNode;
                let containerClass = ' semantic-basic-metric-container ';
                if (icon) {
                    iconNode = Object.assign({}, node);
                    iconNode.attribs = {
                        type: icon,
                        color: iconColor
                    }
                }
                if (hasPrefix) {
                    prefixNode = Object.assign({}, node);
                    prefixNode.attribs = {};
                    // if (prefixColor) {
                    //     prefixNode.attribs.color = prefixColor;
                    // }
                    if (prefixTag) {
                        prefixNode.attribs = {
                            textColor: 'text-muted',
                            color: 'default-inverse',
                            removeBorder: 'true',
                            className: ' semantic-basic-metric-prefix-tag '
                        };
                        if (((orientation !== 'vertical' && !icon) || orientation === 'vertical') && size !== 'small' && size !== 'xs') {
                            prefixNode.attribs.size = 'large';
                        }
                    } else if (prefixText) {
                        prefixNode.attribs.className = ' semantic-basic-metric-prefix-text ';
                    } else if (prefixStatus) {
                        prefixNode.attribs = {
                            value: prefixStatus,
                            shape: 'rounded',
                            className: ' semantic-basic-metric-prefix-status '
                        }
                    }

                }
                if (hasTrend) {
                    trendNode = Object.assign({}, node);
                    let trendAttrs = {};
                    if (trendType) {
                        if (trendType === 'up') {
                            trendAttrs.up = "true";
                        } else if (trendType === 'down') {
                            trendAttrs.up = "false";
                        }
                    }
                    if (trendPositive) {
                        trendAttrs.positive = trendPositive;
                    }
                    if (trendValue) {
                        trendAttrs.value = trendValue;
                    } else if (trendLeftValue) {
                        trendAttrs.leftValue = trendLeftValue;
                    }
                    trendAttrs.valueClass = ' semantic-basic-metric-trend is-opacity-60 ';
                    trendNode.attribs = trendAttrs;
                }
                if (label) {
                    labelNode = Object.assign({}, node);
                    labelNode.attribs = {
                        className: ' semantic-basic-metric-label '
                    };
                    if (!attribs.hasOwnProperty('labelEllipsis') || labelEllipsis) {
                        labelNode.attribs.className = labelNode.attribs.className.concat(' is-text-ellipsis ');
                        labelNode.attribs.title = label;
                    }
                }
                if (value) {
                    valueNode = Object.assign({}, node);
                    valueNode.attribs = {
                        className: ' semantic-basic-metric-value '
                    };
                    if (orientation === 'vertical' && (suffix || hasTrend)) {
                        valueNode.attribs.className = valueNode.attribs.className.concat(' semantic-basic-metric-vertical-value ');
                    }
                    if (valueTooltip) {
                        valueNode.attribs.title = valueTooltip;
                    }
                    if (valueBgColor) {
                        valueNode.attribs.style = `background-color: var(--is-${valueBgColor}); color: var(--is-${valueBgColor}-text);`;
                        valueNode.attribs.className = valueNode.attribs.className.concat(' is-rounded ');
                    } else if (valueColor) {
                        valueNode.attribs.style = `color: var(--is-${valueColor});`;
                    }
                }
                if (suffix) {
                    suffixNode = Object.assign({}, node);
                    suffixNode.attribs = {
                        className: ' semantic-basic-metric-suffix '
                    };
                }
                if (orientation === 'vertical') {
                    containerClass = containerClass.concat(' vertical ');
                }
                if (hasProgress) {
                    progressNode = Object.assign({}, node);
                    progressNode.attribs = {
                        percent: progressValue,
                        strokeColor: progressColor
                    };
                }
                const setSizeValues = (labelSize, valueSize, suffixSize, containerPadding, iconSize) => {
                    if (label) {
                        labelNode.attribs.className = labelNode.attribs.className.concat(` is-font-size-${labelSize} `);
                    }
                    if (value) {
                        const valueFontSize = valueSize.startsWith('big') ? ` is-${valueSize} ` : ` is-font-size-${valueSize} `;
                        valueNode.attribs.className = valueNode.attribs.className.concat(valueFontSize);
                    }
                    if (suffix) {
                        suffixNode.attribs.className = suffixNode.attribs.className.concat(` is-font-size-${suffixSize} `);
                    }
                    if (icon) {
                        const iconFontSize = iconSize.startsWith('big') ? ` is-${iconSize} ` : ` is-font-size-${iconSize} `;
                        iconNode.attribs.className = iconFontSize;
                    }
                    containerClass = containerClass.concat(` is-pad-${containerPadding} `);
                }
                switch (size) {
                    case 'xs':
                        setSizeValues('xs', 'lg', 'xxs', '1', 'xl');
                        if (label) {
                            labelNode.attribs.className = labelNode.attribs.className.concat(' roboto-slab-family ');
                        }
                        if (prefixTag) {
                            prefixNode.attribs.className = prefixNode.attribs.className.concat(' roboto-slab-family ');
                            if (orientation !== 'vertical') {
                                prefixNode.attribs.className = prefixNode.attribs.className.concat(' is-mar-top-0 ')
                            }
                        }
                        break;
                    case 'small':
                        setSizeValues('sm', 'xl', 'xs', '1', 'xl');
                        break;
                    case 'default':
                        setSizeValues('md', 'xxl', 'xs', '1', 'big-font-size-1');
                        break;
                    case 'large':
                        setSizeValues('lg', 'big-font-size-1', 'sm', '2', 'big-font-size-3');
                        if (suffix) {
                            suffixNode.attribs.className = suffixNode.attribs.className.concat(' roboto-family ')
                        }
                        break;
                    default:
                        setSizeValues('md', 'xxl', 'xs', '1', 'big-font-size-1');
                }
                return (
                    <div key={0} className={containerClass}>
                        {icon && <div key={1} className=' semantic-basic-metric-icon '>
                            {renderIconTag(iconNode, 2)}
                        </div>}
                        <div style={{ flexGrow: 1 }} key={3}>
                            {label && renderGenericTextTag(labelNode, [label], 4)}
                            <div key={5} className=' semantic-basic-metric-content '>
                                {prefixTag ? renderTagTag(prefixNode, [prefixTag], 6)
                                    : prefixText ? renderGenericTextTag(prefixNode, [prefixText], 6)
                                        : prefixStatus ? renderStatusTag(prefixNode, [], 6)
                                            : null}
                                {value && renderGenericTextTag(valueNode, [value], 7)}
                                {suffix && renderGenericTextTag(suffixNode, [suffix], 8)}
                                {hasTrend && renderTrendTag(trendNode, 9)}
                                {hasProgress && renderProgressTag(progressNode, [], 10)}
                            </div>
                        </div>
                    </div>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'image-metric';
            },
            processNode: function (node, children, index) {
                const CAMEL_CASE_ATTRIBS = ['iconColor', 'valueColor', 'valueBgColor', 'trendValue', 'trendLeftValue', 'trendValueColor', 'trendType', 'trendPositive', 'labelEllipsis', "imageHeight", "imageWidth", "subLabel"];
                const attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
                const { image, imageWidth, imageHeight, label, icon, iconColor, value, valueColor, valueBgColor, trendType, trendPositive, trendValue, trendLeftValue, size, orientation, labelEllipsis, subLabel } = attribs;
                const hasTrend = trendType || trendPositive || trendValue || trendLeftValue;
                let imageNode;
                let iconNode;
                let trendNode;
                let labelNode;
                let subLabelNode;
                let valueNode;
                let containerClass = ' semantic-image-metric-container ';
                if (image) {
                    imageNode = Object.assign({}, node);
                    imageNode.attribs = {
                        src: image,
                        className: ' semantic-image-metric-image ',
                        width: imageWidth,
                        height: imageHeight
                    };
                }
                if (icon) {
                    iconNode = Object.assign({}, node);
                    iconNode.attribs = {
                        type: icon,
                        color: iconColor
                    }
                }

                if (value) {
                    valueNode = Object.assign({}, node);
                    valueNode.attribs = {
                        className: ' semantic-image-metric-value '
                    };
                    if (valueBgColor) {
                        valueNode.attribs.style = `background-color: var(--is-${valueBgColor}); color: var(--is-${valueBgColor}-text);`;
                        valueNode.attribs.className = valueNode.attribs.className.concat(' is-rounded ');
                    } else if (valueColor) {
                        valueNode.attribs.style = `color: var(--is-${valueColor});`;
                    }
                }
                if (label) {
                    labelNode = Object.assign({}, node);
                    labelNode.attribs = {
                        className: ' semantic-image-metric-label '
                    };
                    if (!attribs.hasOwnProperty('labelEllipsis') || labelEllipsis) {
                        labelNode.attribs.className = labelNode.attribs.className.concat(' is-text-ellipsis ');
                        labelNode.attribs.title = label;
                    }
                }
                if (subLabel) {
                    subLabelNode = Object.assign({}, node);
                    subLabelNode.attribs = {
                        className: ' semantic-image-metric-sub-label '
                    };
                }
                if (hasTrend) {
                    trendNode = Object.assign({}, node);
                    let trendAttrs = {
                        className: ' is-mar-left-2 '
                    };
                    if (trendType) {
                        if (trendType === 'up') {
                            trendAttrs.up = "true";
                        } else if (trendType === 'down') {
                            trendAttrs.up = "false";
                        }
                    }
                    if (trendPositive) {
                        trendAttrs.positive = trendPositive;
                    }
                    if (trendValue) {
                        trendAttrs.value = trendValue;
                    } else if (trendLeftValue) {
                        trendAttrs.leftValue = trendLeftValue;
                    }
                    trendNode.attribs = trendAttrs;
                }
                if (orientation === 'vertical') {
                    containerClass = containerClass.concat(' vertical ');
                }
                const setSizeValues = (labelSize, valueSize, subLabelSize, containerPadding, iconSize) => {
                    if (label) {
                        labelNode.attribs.className = labelNode.attribs.className.concat(` is-font-size-${labelSize} `);
                    }
                    if (subLabel) {
                        subLabelNode.attribs.className = subLabelNode.attribs.className.concat(` is-font-size-${subLabelSize} `);
                    }
                    if (value) {
                        const valueFontSize = valueSize.startsWith('big') ? ` is-${valueSize} ` : ` is-font-size-${valueSize} `;
                        valueNode.attribs.className = valueNode.attribs.className.concat(valueFontSize);
                    }
                    if (icon) {
                        const iconFontSize = iconSize.startsWith('big') ? ` is-${iconSize} ` : ` is-font-size-${iconSize} `;
                        iconNode.attribs.className = iconFontSize;
                    }
                    containerClass = containerClass.concat(` is-pad-${containerPadding} `);
                }
                switch (size) {
                    case 'xs':
                        setSizeValues('xs', 'lg', 'xxs', '1', 'xl');
                        if (label) {
                            labelNode.attribs.className = labelNode.attribs.className.concat(' roboto-slab-family ');
                        }
                        break;
                    case 'small':
                        setSizeValues('sm', 'xl', 'xs', '1', 'xl');
                        break;
                    case 'default':
                        setSizeValues('md', 'xxl', 'xs', '1', 'big-font-size-1');
                        break;
                    case 'large':
                        setSizeValues('lg', 'big-font-size-1', 'sm', '2', 'big-font-size-3');
                        break;
                    default:
                        setSizeValues('md', 'xxl', 'xs', '1', 'big-font-size-1');
                }
                return (
                    <div key={0} className={containerClass}>
                        {image && <div key={1}>
                            {image && renderImageTag(imageNode, 2)}
                        </div>}
                        {!image && icon && <div key={1} className=' semantic-basic-metric-icon '>
                            {renderIconTag(iconNode, 2)}
                        </div>}
                        <div key={3}>
                            <div className=' semantic-image-metric-content '>
                                {value && renderGenericTextTag(valueNode, [value], 4)}
                                {hasTrend && renderTrendTag(trendNode, 5)}
                            </div>
                            <div key={6} className=' semantic-image-metric-content '>
                                {label && renderGenericTextTag(labelNode, [label], 7)}
                            </div>
                            <div key={8} className=' semantic-image-metric-content '>
                                {subLabel && renderGenericTextTag(subLabelNode, [subLabel], 9)}
                            </div>
                        </div>
                    </div>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'rate-metric';
            },
            processNode: function (node, children, index) {
                const CAMEL_CASE_ATTRIBS = ['rateCount', 'rateValue'];
                const attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
                const { rateCount, rateValue } = attribs;
                const hasRate = rateCount && rateValue;
                let rateNode;
                if (hasRate) {
                    rateNode = Object.assign({}, node);
                    rateNode.attribs = {
                        count: rateCount,
                        value: rateValue,
                        disabled: 'true',
                        className: ' semantic-rate-metric-rate '
                    };
                }
                return renderRateTag(rateNode, [], index);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'metric-card';
            },
            processNode: function (node, children, index) {
                const CAMEL_CASE_ATTRIBS = ['statusShape'];
                const BOOLEAN_VALUES = ['bordered', 'hoverable', 'loading'];
                let attribs = getProcessedProps(node, index, processNodeDefinitions, CAMEL_CASE_ATTRIBS);
                attribs = getBooleanValues(attribs, BOOLEAN_VALUES);
                const { title, subtitle, icon, status, statusShape } = attribs;
                let iconNode;
                let titleNode;
                let subtitleNode;
                let statusNode;
                if (icon) {
                    iconNode = Object.assign({}, node);
                    iconNode.attribs = {
                        type: icon
                    }
                }
                if (title) {
                    titleNode = Object.assign({}, node);
                    titleNode.attribs = {
                        className: ' semantic-metric-card-title '
                    };
                    delete attribs.title;
                }
                if (subtitle) {
                    subtitleNode = Object.assign({}, node);
                    subtitleNode.attribs = {
                        className: ' semantic-metric-card-subtitle '
                    };
                }
                if (status) {
                    statusNode = Object.assign({}, node);
                    statusNode.attribs = {
                        value: status,
                        shape: statusShape || 'circle',
                        style: 'min-width: var(--is-su-3)'
                    };
                }
                if (!attribs.hasOwnProperty('bordered')) {
                    attribs.bordered = true;
                }
                const renderHeader = () => {
                    return (
                        <div key={0} className="semantic-metric-card-header-container">
                            {icon &&
                                <div key={1} className="is-mar-right-2">
                                    {renderIconTag(iconNode, 2)}
                                </div>
                            }
                            <div key={3} className={status ? 'is-flex-grow-1' : ''}>
                                {title && renderGenericTextTag(titleNode, [title], 4)}
                                {subtitle && renderGenericTextTag(subtitleNode, [subtitle], 5)}
                            </div>
                            {status && renderStatusTag(statusNode, [], 6)}
                        </div>
                    )
                }
                return (
                    <Card title={renderHeader()} {...attribs}>
                        {children}
                    </Card>
                );
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'image';
            },
            processNode: function (node, children, index) {
                return renderImageTag(node, index);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node.name === 'video';
            },
            processNode: function (node, children, index) {
                return renderVideoTag(node, index);
            }
        },
        {
            shouldProcessNode: function (node) {
                return node && node.type === 'text';
            },
            processNode: processNodeDefinitions.processDefaultNode
        }
    ];
}