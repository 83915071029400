import { types } from "./types";

export const setStaticParamFilter = (payload) => async dispatch => {
   try {
    dispatch({
        type: types.STATIC_PARAM_FILTER,
        payload: payload
    });

    } catch (error) {
        console.log(error);
    }
}

export const setStaticValueFilter = (payload) => async dispatch => {
    try {
     dispatch({
         type: types.FILTER_STATIC_DATA,
         payload: payload
     });
 
     } catch (error) {
         console.log(error);
     }
 }
export const setcommonDataAdd = (payload) => async dispatch => {
    // debugger
    try {
     dispatch({
         type: types.CommonDataAdd,
         payload: payload
     });
 
     } catch (error) {
         console.log(error);
     }
 }

 export const setStaticData = (payload) => async dispatch => {
    try {
     dispatch({
         type: types.LOAD_STATIC_DATA,
         payload: payload
     });
 
     } catch (error) {
         console.log(error);
     }
 }

 export const addStaticData = (payload) => async dispatch => {
    try {
     dispatch({
         type: types.ADD_STATIC_DATA,
         payload: payload
     });
 
     } catch (error) {
         console.log(error);
     }
 }
