import React from 'react';
import Schema from './schema';
import hash from 'object-hash';
import './style.module.less';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

export default class ForEachContainer extends Schema {

    itemsMap = {};
    indexMap = {};
    haveChildren = false;

    componentDidMount() {
      

        this.forEachRef = React.createRef()
        this.updateitems(this.props);
    }

    componentWillMount() {
        const { mounted } = this.props;
        if (!mounted) {
            this.props.resolvedItems.length = 0;
        }
    }



    updateitems = (props) => {
        const { resolvedItems, childrenRefs, itemsResolver} = props;
        const changedKeys = [];
        let changed;

        if (Array.isArray(itemsResolver)) {
            const resolvedItemsCopy = resolvedItems.slice();
            resolvedItems.length = 0;
            itemsResolver.forEach((item, index) => {
                const hashId = hash(item);
                const previousHashId = hash(resolvedItemsCopy[index] || {});
                if (!this.itemsMap[hashId] || hashId !== previousHashId) {
                    changedKeys.push(childrenRefs[index]);
                }
                const resolvedItem = this.itemsMap[hashId] = this.itemsMap[hashId] || item;
                this.indexMap[index] = hashId;
                resolvedItems.push(resolvedItem);
            });

            if (JSON.stringify(resolvedItems) !== JSON.stringify(resolvedItemsCopy)) {
                changed = true;
            }
        }

        if (changed) {
        }
    }

    componentWillReceiveProps(nextProps) {
        this.updateitems(nextProps);
    }

    renderChildren = (children, onlyFirst) => {
        
        let { carousel, itemSpan, resolvedItems, containerStyle, childrenStyle,numberOfRow,tileClick,slidesToScroll,slidesToShow } = this.props;

        const defaultSliderSettings = {
            childrenRefs: [],
            accessibility: true,
            adaptiveHeight: false,
            arrows: true,
            autoplay: false,
            autoplaySpeed: 3000,
            centerMode: false,
            centerPadding: "50px",
            className: "carousel-children",
            cssEase: "ease",
            dots: false,
            dotsClass: "slick-dots",
            draggable: true,
            easing: "linear",
            edgeFriction: 0.35,
            fade: false,
            focusOnSelect: false,
            infinite: false,
            initialSlide: 0,
            pauseOnDotsHover: false,
            pauseOnFocus: false,
            pauseOnHover: true,
            rows:numberOfRow||1,
            rtl: false,
            slide: "div",
            slidesPerRow: 4,
            slidesToScroll:slidesToScroll ,
            slidesToShow: slidesToShow,
            speed: 500,
            swipe: true,
            swipeToSlide: false,
            touchMove: true,
            touchThreshold: 5,
            useCSS: true,
            useTransform: true,
            variableWidth: false,
            vertical: false,
            waitForAnimate: true
        }


        if (carousel) {
            return (
                        <Slider {...defaultSliderSettings} ref={this.forEachRef}    >
        {
            resolvedItems.map((child, index) => (
                <div className="is-width-99 is-pad-x-4"  key={Date.now() + index}>
                    {React.cloneElement(children , {item: child})}
                    <div class='is-cursor-pointer' onClick={() => tileClick && tileClick(child)}> {child.title}</div>

                </div>
            ))
        }
    </Slider>

                )
            
        } else {
            let className;
            if (itemSpan !== null) {
                className = `is-pad-0 is-col-${itemSpan || '12'}`;
            }
            return (
                <div className={'is-row'} style={containerStyle} ref={this.forEachRef}>
                    {
                        resolvedItems.map((child, index) => (
                            (onlyFirst && index > 0) ? null : <div className={className} style={childrenStyle} key={Date.now() + index}>
                                {React.cloneElement(children, { ...children.props, data: child })}
                                <div class='is-cursor-pointer' onClick={() => tileClick && tileClick(child)}> {child.title}</div>
                                
                            </div>
                        ))
                    }
                </div>
            )

        }
    };

    render() {
        const { children, onlyFirst, editMode } = this.props;     
        const editing = editMode === 'full';

        return children ?
            onlyFirst ?
                this.renderChildren(children, onlyFirst) :
                this.renderChildren(children)
            :
            editing &&
            <div style={{ width: '100%', height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <span>Drop Item Here</span>
            </div>;
    }

}