import React from 'react'; 

export default class Schema extends React.Component {

    static propTypes = {

    };

    static defaultProps = {
       title:"Monitoring Progress",
       subtitle:""
    };
};