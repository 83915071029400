import React from "react";
import { useSelector } from "react-redux";
import { replaceToDataValue, getStateData } from "../../components/util";
import { Radio } from "antd";

const Wrapper = ({ children = {} }) => {
  const pivot = (data, row, column, value, addRow) => {
    var item = data.reduce((a, b) => {
      a[b[row]] = a[b[row]] || [];
      var obj = {};
      obj[addRow] = b[addRow];
      addRow
        ? a[b[row]].push({ ...obj, ...{ [b[column]]: b[value] } })
        : a[b[row]].push({ ...{ [b[column]]: b[value] } });

      return a;
    }, {});

    var array = Object.keys(item).map(function(k) {
      var itr = {};
      itr[row] = Number(k);
      return { ...itr, ...Object.assign.apply({}, item[k]) };
    });
    return array;
  };
  const { props = {} } = children;
  const { dataKey = "" } = props;
  const {constdata}=props;
  // const data = (data) ? (setCalcData(useSelector((state) => getStateData(state, dataKey)),calcFunc)):(useSelector((state) => getStateData(state, dataKey)));
  var data = (useSelector((state) => constdata ?constdata:getStateData(state, dataKey)));
  
  const Props = Object.assign({}, props);
  const ResolverFunction = (item) => {
    if(constdata){
      if (typeof item === "string") {
        return replaceToDataValue(item, data || [{}]);
      }
    }
    else{
      if (typeof item === "string") {
        return replaceToDataValue(item, dataKey ? data : Props.data || [{}]);
      } else if (Array.isArray(item)) {
        return item.map((iter) => ResolverFunction(iter));
      } else if (typeof item === "object" && item !== null) {
        if (item.hasOwnProperty("$$typeof")) {
          return item;
        }
        Object.keys(item).forEach((iter) => {
          item[iter] = ResolverFunction(item[iter]);
        });
        return item;
      }
      return item;
    }
   
  };
  Object.keys(Props).forEach((item) => {
    if (item === "data") {
      if (Props.hasOwnProperty("pivotConfig")) {
        Props[item] = pivot(Props[item], ...Props["pivotConfig"]);
      }
      return;
    }
    Props[item] = ResolverFunction(Props[item]);
  });

  const resolvedChildren = React.cloneElement(children, { ...Props });

  const renderFilters = () => {
    let { promptConfig = {} } = props;

    let groups = [];

    (promptConfig.selectors || []).forEach((selector) => {
      let { action = () => {} } = selector;

      if (selector.type == "buttons") {
        groups.push(
          <div className={"wrapper-filter-group"}>
            <Radio.Group
              onChange={(e) => {
                action(e.target.value);
              }}
              defaultValue={selector.defaultValue}
              buttonStyle="solid"
            >
              {selector.options.map((option) => (
                <Radio.Button value={option.value}>{option.label}</Radio.Button>
              ))}
            </Radio.Group>
          </div>
        );
      }
    });

    if (groups && groups.length) {
      return groups;
    }

    return null;
  };

  return (
    <React.Fragment>
      <div className="wrapper-component-header">{renderFilters()}</div>
      {resolvedChildren}
    </React.Fragment>
  );
};

export default Wrapper;
