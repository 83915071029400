const initialState = {
  landingPageSelectedReport: "Landing_Page_WhatsNew_Preview",
  landingPageSelectedTab: "Whats New",
  allowedRoles:["DISTRICT_ADMIN","DEVELOPER"],
  notification_colors:["grey","blue","violet","#bf6a49","#2f2a26","#9b616d","#012935","#86623b"],
  showallReportMappings:{"Landing_Page_WhatsNew_Preview":"Landing_Page_WhatsNew","Landing_Page_Notification_Preview":"Landing_Page_Notification"}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case "SET_PARAM":
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
