import React, { useEffect, useState } from "react";
import { Tabs } from "antd";

const { TabPane } = Tabs;

const TabExplorer = (props) => {
  const { configTabs, defaultKey, onChange } = props;
  console.log("TabExplorer re render")
  return (
    <Tabs
      type="card"
      defaultActiveKey={defaultKey}
      onChange={onChange}
    >
      {configTabs.map((tab, i) => {
        return (
          <TabPane tab={tab.tabName} key={tab.tabName}>
            {tab.child}
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default TabExplorer;
