import { types } from "./types";
import UserService from "../../service/userService";
import { CURRENT_ROLE_CUSTOMER_BASED } from "utils/customerBasedConfigs";


export const setUserData = 
  () => async (dispatch) => {
    return new Promise((res,rej)=>{
      let promise;
      try {
        promise = UserService.fetchUser()
      } catch (error) {
        console.log(error);
      }
      promise.then((reponseData) => {
          let jsonData = reponseData && reponseData.data;
          if(jsonData.authorities == null){
            let redirectUrl = `${window.location.origin}/service_unavailable.html`;
            window.location.href = redirectUrl;
          }
          let role = jsonData?.authorities[0]?.authority;
          window.userRoleDirect = role;
          dispatch({
            type: types.SET_PARAM,
            payload: {userRole:  CURRENT_ROLE_CUSTOMER_BASED()[role] ? CURRENT_ROLE_CUSTOMER_BASED()[role] : role},
          });
          dispatch({
            type: types.SET_REPORT_DATA,
            payload: {user:jsonData},
          });
          dispatch({
            type: types.PARAM_FILTER,
            payload: {"#{loggedin_role}": window.userRoleDirect},
          });
          res();
        }).catch(e=>{
          dispatch({
            type: types.PARAM_FILTER,
            payload: {"#{loggedin_role}": 'DEVELOPER'},
          });
          res();
        });
      
    //  console.log(jsonData.user_role?.[0]?.G_ROLE);
      
    })
  };

