export const getStateData = (obj, string) => {
  // debugger;
  var arr = string.split(".");
 
  // if(arr.length<=2){
  //   while (arr.length) {
  //     // debugger
  //     obj = obj[arr.shift()] || [];
  //   }
  // }
  // else{
    arr.length&&arr.forEach(e => {
      console.log("obj is :::::>>>",e)
       if(obj) obj=obj[e];
       });
    
   
  // }
  
 
  return obj;
};
export const replaceToDataValue = (str, dataSource, keepValue) => {
  if (!dataSource || (dataSource instanceof Array && !dataSource.length)) {
    return "";
  }
  let full = [];
  let group = [];
  let result = str;
  result.replace(/\#\{([^}]+)\}/g, function(a, b) {
    full.push(a);
    group.push(b);
  });
  if (group.length) {
    let data = dataSource;
    if (dataSource instanceof Array) {
      data = data[0];
    }
    if (data) {
      for (let i = 0; i < group.length; i++) {
        let value = data[group[i]] || " ";
        if (value || value === 0) {
          result = result && result.replace(full[i], value);
        } else if (!keepValue) {
          result = undefined;
        }
      }
    }
  }
  return result;
};
