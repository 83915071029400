import React from "react";
import Schema from "./schema";
import { connect } from "react-redux";
import "./style.css";
import ViewBoxComponent from "@innive/view-box-component";
import VideoPlayer from "../../../components/video-player";

function MiddleSection(props) {
    console.log("MiddleSection re render")
    const {onClick,selected,dataMapping,data=[{dashboard_name: "name",description: "description"}]} = props;
    
    const mapData = (data) =>{
        const mappingKeys = Object.keys(dataMapping);
        data && data.map(item => {
          mappingKeys.map(key => {
            item[key] = item[dataMapping[key]]
          })
        })
        return data
    }

    const finalData = dataMapping?mapData(data):data;


  return (

    <ViewBoxComponent columns={[{ size: 7 },{ size: 5 }]}>
      <div className="is-mar-x-5">
        <div className="is-font-size-md">{finalData[0] && finalData[0].description}</div>
      </div>

      <div className="is-mar-x-5">
        <div className="is-hu-25">
         <VideoPlayer url={finalData[0] && finalData[0].link} />
        </div>
      </div>
    </ViewBoxComponent>
    
  );
}

const mapStateToProps = (state, ownProps) => {  
};

export default connect(mapStateToProps, null)(MiddleSection);
