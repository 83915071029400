import merge from 'deepmerge';
import VariablesManipulator from './variablesManipulator';

export default class Events {

    static EVENTS = {
        THEME_CHANGED: 'THEME_CHANGED',
        THEME_NAME_CHANGED: 'THEME_NAME_CHANGED',
        LIST_LOADED: 'LIST_LOADED',
        CHART_THEME_LIST_LOADED: 'CHART_THEME_LIST_LOADED',
        TOGGLE_GRAYSCALE: 'TOGGLE_GRAYSCALE'
    };

    static HANDLERS = {
        [Events.EVENTS.THEME_CHANGED]: (state, payload) => {
            const {currentTheme} = payload;
            if (currentTheme) {
                let {currentBreakpoint, accessibility} = payload;
                const root = document.querySelector(':root');
                const defaultObj = currentTheme.default;
                const customObj = (currentTheme.values || []).find((currValue) => currValue.breakpoint === currentBreakpoint && currValue.accessibility === accessibility) || {};
                const mergedObj = merge(defaultObj, customObj);
                let newValues = {};
                for (let property in mergedObj) {
                    const cssProperty = property.split(/(?=[A-Z])/).join('-').toLowerCase();
                    const propertyName = '--' + cssProperty;
                    const propertyValue = mergedObj[property];
                    root.style.setProperty(propertyName, propertyValue);
                    newValues[propertyName] = propertyValue;
                }
                VariablesManipulator.setColorVariations(newValues);
            }

            return {
                variables: {
                    $set: VariablesManipulator.getUpdatedVariables()
                } 
            };
        },
        [Events.EVENTS.LIST_LOADED]: (state, payload) => {
            return {
                themes: {
                    $set: payload.data
                },
                variables: {
                    $set: VariablesManipulator.getUpdatedVariables()
                }
            };
        },
        [Events.EVENTS.CHART_THEME_LIST_LOADED]: (state, payload) => {
            return {
                chartThemes: {
                    $set: payload.data
                }
            };
        },
        [Events.EVENTS.THEME_NAME_CHANGED]: (state, payload) => {
            return {
                currentTheme: {
                    $set: payload.currentTheme
                }
            };
        },
        [Events.EVENTS.TOGGLE_GRAYSCALE]: (state, payload) => {
            const {isGrayscale} = state;
            if (!isGrayscale) {
                document.body.style.filter = 'grayscale(1)';
            } else {
                document.body.style.removeProperty('filter');
            }
            return {
                isGrayscale: {
                    $set: !isGrayscale
                }
            };
        }
    };

    static THEME_CHANGED(currentTheme, currentBreakpoint, accessbility) {
        return {
            type: Events.EVENTS.THEME_CHANGED,
            payload: {
                currentTheme,
                currentBreakpoint,
                accessbility
            }
        }
    }

    static THEME_NAME_CHANGED(currentTheme) {
        return {
            type: Events.EVENTS.THEME_NAME_CHANGED,
            payload: {
                currentTheme
            }
        }
    }

    static LIST_LOADED(data) {
        return {
            type: Events.EVENTS.LIST_LOADED,
            payload: {
                data
            }
        }
    }

    static CHART_THEME_LIST_LOADED(data) {
        return {
            type: Events.EVENTS.CHART_THEME_LIST_LOADED,
            payload: {
                data
            }
        }
    }

    static TOGGLE_GRAYSCALE() {
        return {
            type: Events.EVENTS.TOGGLE_GRAYSCALE,
            payload: {}
        }
    }

}