import React, { useEffect, useState } from "react";
import { Layout, Spin } from "antd";
import { connect } from "react-redux";

import "./style.css";

const { Content } = Layout;

const Container = (props) => {  
  return (
    <>
      <Layout>
        <Spin size="large" spinning={props.loading||false}>
          <div className="contentBackground">
            <Content>{props.children}</Content>
          </div>
        </Spin>
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  cards: state.cards,
});

export default connect(mapStateToProps, null)(Container);

// export default Container;
