import { Button, Card, Modal } from "antd";
import { Col, Row } from "antd";
import React, { useRef } from "react";
import Picture from "../../assest/laptop-for-business-student.png";
import StudentImage from "../../assest/stu.png";
import { Pagination } from "antd";
import { Carousel } from "antd";
import { Tag } from 'antd';
import { CaretRightOutlined,UpOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
// import Picture from "../../assest/"
import { DownloadOutlined, LeftOutlined, RightOutlined, } from "@ant-design/icons";
import "./index.css";
import { useState } from "react";
import Meta from "antd/lib/card/Meta";
import CheckableTag from "antd/lib/tag/CheckableTag";
import ModalCustomCards from "./ModalCustomCards";

const { Panel } = Collapse;
const tagsData = ['Movies', 'Books', 'Music', 'Sports'];


function prepareCarouselDataSetForWhatsanewSection(arr, chunkSize) {
  const res = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
}

const reportMapping = {
  "What's New ?": "Landing_Page_WhatsNew",
  "Tutorial of The  Week": "Landing_Page_JobAids",
  "FAQs": "Landing_Page_FAQ",
}
const tabsMapping = {
  "What's New ?": "Whats New",
  "Tutorial of The  Week": "Tutorial of The Week",
  "FAQs": "FAQs",
}

function MiddleCards({data, setParam}) {
  const [newLength, setNewLength] = useState(1);
  const [currentSlide, setCurrentSlide] = useState({});
  const [open,setOpen] = useState(false);
  const slider1 = useRef(null);
  const slider2 = useRef(null);
  const [content, setContent] = useState([]);
  const [selectedTags, setSelectedTags] = useState(['Books']);

  // const handleChangeCategoies = (tag, checked) => {
  //   const nextSelectedTags = checked
  //     ? [...selectedTags, tag]
  //     : selectedTags.filter((t) => t !== tag);
  //   setSelectedTags(nextSelectedTags);
  // };
  // let faqCategories = ()=> {
  //   return tagsData.map((tag) => (
  //     <CheckableTag
  //       key={tag}
  //       checked={selectedTags.indexOf(tag) > -1}
  //       onChange={(checked) => handleChangeCategoies(tag, checked)}
  //     >
  //       {tag}
  //     </CheckableTag>
  //   ))
  // }
  const handleShowAllClick = (e) => {
    debugger
  let reportname=  reportMapping[e.header];
  let selectedtab=  tabsMapping[e.header];
    setParam({usefulLinksModal: false,landingPageTableModal:true,landingPageSelectedReport: reportname,landingPageSelectedTab:selectedtab})
  }
  const renderWhatsNew = (e) => {
    return prepareCarouselDataSetForWhatsanewSection(e, 3).map((f, findex) => { 
      return (
        <div>
          {f.map((each, eachIndex) => { 
            return (
              <>
                <div className="middleCard_mainContent">
                  <Card
                    className="secondTileCard middleCards-childCard is-wu-24"
                    hoverable
                    bordered={false}
                    // style={{ width: 240 }}
                    cover={<img alt="example" src={
                      each.THUMBNAIL_IMAGE !== "" ? each.THUMBNAIL_IMAGE : require(`../../assest/stu.png`).default
                    } 
                    style={{ width: '140px' }}
                  />}
                  >
                    <div style={{display:'flex','flex-direction':'column','justifyContent':'space-between',height: '100%'}}>
                      <div>
                      {/* <Meta description={'Created Date :  '+ each.CREATED_DATE.slice(0,10)} />
                      <br></br> */}
                      <Meta description={'Updated Date :  '+ each.UPDATED_DATE.slice(0,10)} />
                      <br></br>
                      <Meta
                        title={each.TITLE}
                        description={each.SHORT_DESCRIPTION}
                      />
                      </div>
                      <div>
                      {each.URL && (<Button
                        type="primary"
                        shape="round"
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          let url = each.URL;
                      debugger
                          // Check if the URL starts with a protocol
                          if (!/^https?:\/\//i.test(url)) {
                              // If not, prepend "https://"
                              url = "https://" + url;
                          }
                      
                          // Open the new window
                          window.open(url);
                      }}
                        // onClick={()=>{
                        //   window.open(each.URL)
                        //   }}
                        // size={40}
                      >
                      {"Show More"}
                    </Button>)}
                      </div>
                    </div>
                   
                  
                  </Card>
                </div>
              </>
            );
          })}
        </div>
      );
    });
  };
  const renderTutorial = (e) => {
    // debugger
    return e.content.map((f) => {
      return (
        <div>
       
          <div className="middleCard_mainContent firstTileCard">
            <img className="is-hu-23 is-width-100"
              src={f.THUMBNAIL_IMAGE !== "" ? f.THUMBNAIL_IMAGE : require(`../../assest/laptop-for-business-student.png`).default}
              // src={require(`../../assest/${f.image}`).default}
              width="100"
              height="100">
            </img>
          </div>
          <div className="middleCard_mainContent_content">
              <div style={{ padding: "3% 7%" , borderRadius : "5px" , width: "95%", height : "420px"}}>
              <div style={{display:'flex','flex-direction':'column','justifyContent':'space-between',height: '100%'}}>
                          <div>
                          <div className="middleCard_header">{f.TITLE}</div>
                          <div className="middleCard_subheader  is-pad-y-2">{f.SHORT_DESCRIPTION}</div>
                          <div className="middleCard_description">{f.LONG_DESCRIPTION}</div>
                          </div>
                          <div>
                          <div className="middleCard_Actions">
                  {/* {f.actions.map((action) => {
                    return (
                      <Button className="is-hu-4 is-wu-17"
                        type="primary"
                        shape="round"
                        icon={<DownloadOutlined />}
                        onClick={()=>{setOpen((Math.random(1000)*3).toString().replace(".", ""));
                          setContent(f)
                        }}
                        // size={40}
                      >
                        Open Full Article
                      </Button>
                    );
                  })} */}
                  {f.URL && (<Button className="is-hu-4 "
                        type="primary"
                        shape="round"
                        icon={<DownloadOutlined />}
                        onClick={()=>{
                          window.open(f.URL)
                        }}
                        // size={40}
                      >
                        Show More
                      </Button>)}
                </div>
                          </div>
                    </div>
               
  
                
                
              </div>
          </div>
        </div>
      );
    });
  };
  const renderFaqsCategories = (e) =>{
// debugger

const renderPanel = (i, index) => (
  <Panel
    header={
      <div className="is-display-flex is-justify-content-between">
        <div className="is-font-size-md" style={{}}>{i.QUESTIONS}?</div>
        {/* <div className="is-font-size-xs" style={{}}>{'Created Date :  '+ i.CREATED_DATE.slice(0,10)}</div> */}
        <div className="is-font-size-xs" style={{}}>{i.UPDATED_DATE.slice(0,10)}</div>
      
      </div>
    }
    key={index}
    className="site-collapse-custom-panel is-font-size-md"
  >
    <div className="collapse-content">
    {/* {'Updated Date :  '+i.UPDATED_DATE.slice(0,10)} */}
      <div>{i.ANSWERS}</div>
      {i.URL && (<Button style={{width:"fit-content",flex:1}}
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              onClick={()=>{
                window.open(i.URL)
                }}
              // size={40}
            >
            {"Show More"}
        </Button>)}
      {/* <Button
        type="primary"
        shape="round"
        icon={<DownloadOutlined />}
        onClick={() => {
          setOpen((Math.random() * 1000).toString().replace(".", ""));
          // You can add more logic here if needed
        }}
      >
        {"Read More"}
      </Button> */}
    </div>
  </Panel>
);

return (
  <Collapse
    bordered={false}
    expandIcon={({ isActive }) => (
      <UpOutlined rotate={isActive ? 0 : 180} color={isActive ? 'black' : 'blue'} />
    )}
    className="site-collapse-custom-collapse faqCollapse"
  >
    {e.content.map((i, index) => renderPanel(i, index))}
  </Collapse>
);


  };

  return (
    <>
   <ModalCustomCards content={content} key={JSON.stringify(open)} open={open} setOpen={setOpen}/>
    <div className="site-card-wrapper">
      <Row gutter={16}>
        {(data || []).map((e, i) => {
          return (
            <Col span={8}>
             <Card className="middleCards-childCard test" style={{ color: "black" }}
                title={
                   <>
                   <div style={{display:"flex", justifyContent: "space-between"}}>
                   <div>
                   <div className="is-pad-bottom-1 is-font-size-lg theme-font-color"> {e.header} </div>
                    {/* {e.isFaq ? faqCategories() : */}

                    <span className="subHeaderCarousel is-display-flex theme-font-color">
                    {/* <LeftOutlined onClick={() => eval("slider"+(i+1)).current.prev()} className="arrowButtonsCarousel" />
                    <RightOutlined onClick={() => eval("slider"+(i+1)).current.next()} className="arrowButtonsCarousel" />   */}
                      {!e.isFaq  && (
                        <div className="subHeaderCarousel  is-mar-left-1" >
                        {/* style={{ color: "#00000075", fontSize: "1rem" }} > */}
                        {/* {e.isTutorial ?`Tutorial ${ currentSlide && currentSlide[e.header] ? currentSlide[e.header] : 1 }
                         of ${e.content.length}`: `${currentSlide && currentSlide[e.header] && currentSlide[e.header]*3-3 != 0
                            ? currentSlide[e.header]*3-3
                            : 1} to ${currentSlide && currentSlide[e.header]
                            ? currentSlide[e.header]*3
                            : 3} of ${e.content.length} News`} */}

                      {e.isTutorial ?
                      (<>
                      <div className="is-display-flex">
                          <LeftOutlined onClick={() =>{(currentSlide[e.header] && currentSlide[e.header]>1)  && eval("slider"+(i+1)).current.prev()}} className={(currentSlide[e.header] && currentSlide[e.header]>1)?"arrowButtonsCarousel":"arrowButtonsCarousel disabled"} />
                          <RightOutlined onClick={() => {!(currentSlide[e.header]==e.content.length) && eval("slider"+(i+1)).current.next()}} className={e.content.length && currentSlide[e.header] && (currentSlide[e.header]==e.content.length)?"arrowButtonsCarousel disabled" : "arrowButtonsCarousel"} />  
                          &nbsp;Tutorial { currentSlide && currentSlide[e.header] ? currentSlide[e.header] : (e.content.length ? 1: 0) }  of {e.content.length}
                      </div>
                         
                      </>)
                      :
                      (<>
                         <div className="is-display-flex">
                          <LeftOutlined onClick={() =>{(currentSlide[e.header] && currentSlide[e.header]>1)  && eval("slider"+(i+1)).current.prev()}} className={(currentSlide[e.header] && currentSlide[e.header]>1)?"arrowButtonsCarousel":"arrowButtonsCarousel disabled"} />
                          <RightOutlined onClick={() => {!(currentSlide[e.header]*3>=e.content.length) && eval("slider"+(i+1)).current.next()}} className={!currentSlide[e.header] && (currentSlide[e.header]*3>=e.content.length)?"arrowButtonsCarousel disabled":"arrowButtonsCarousel"} />  
                          &nbsp; Page {currentSlide && currentSlide[e.header] && currentSlide[e.header]*3-3 != 0 ? (currentSlide[e.header]) : (e.content.length ? 1: 0)} of {Math.ceil((e.content || "").length / 3)}
                        </div>
                         </> 
                         )
                      }

                    
                        </div>
                      )}
                    </span>
                    {/* // } */}
                    </div>
                    {/* {e.showAll &&  */}
                    <div
                    onClick={()=>handleShowAllClick(e)}
                      // onClick={()=>{
                      //     setOpen((Math.random(1000)*3).toString().replace(".", ""));
                      //     setContent([]);
                      //   }}
                     className="theme-font-color show-all-middle-cards">
                     Show All
                     </div>
                     {/* } */}
                    </div>
                  </>
                }
                bordered={false}
              >
                {e.isTutorial ? (
                  <>
                 <Carousel autoplay= {true} afterChange={(c) => { e.content.length && setCurrentSlide({ ...currentSlide, [e.header]: (c + 1) > e.content.length   ? currentSlide[e.header] : (c + 1) }); }} ref={slider1} >
                    {renderTutorial(e)}
                  </Carousel>
                  </>
                ) : e.isWhatsnew  ?(
                  <Carousel autoplay= {false} afterChange={(c) => { e.content.length && setCurrentSlide({ ...currentSlide, [e.header]: c + 1 }); }} ref={slider2} >
                    {renderWhatsNew(e.content)}
                  </Carousel>
                ):(
                  
                 renderFaqsCategories(e)
                  
                )}
              </Card>
            </Col>
          );
        })}
      </Row>
    </div>
    </>
  );
}

export default MiddleCards;
