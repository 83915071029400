const initialState = {
  sideMenuFilter: [],
  powerBiFilter: []
}

export default function (state = initialState, action) {

  switch (action.type) {
    case "PARAM_FILTER":
      return {
        ...state,
        paramFilters:{
          ...((state.paramFilters) || {}),
          ...action.payload
        }
      };
    case "VALUE_FILTER":
      return {
        ...state,
        valueFilters:{
          ...((state.valueFilters) || {}),
          ...action.payload
        }
      };
      case "SIDE_MENU_FILTER":
        return {
          ...state,
          sideMenuFilter: {...state.sideMenuFilter, ...action.payload}  
        };

        case "POWERBI_FILTER":
          return {
            ...state,
            powerBiFilter: action.payload   
          };
    default:
      return state;
  }
}
