const config = {
    "gcsd" : {
        "video_url": "https://elocallink.tv/e/share.php?ffb=1&v=mt9&ap=1",
        "image": {
          "dark": "",
          "light": ""
        }
      },
      "demo" :{
        "video_url": "https://www.youtube.com/watch?v=rZ1aOFAfMrQ&ab_channel=Innive",
        "image": {
          "dark": "",
          "light": ""
        }
      }
}
  
  export default config;