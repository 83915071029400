export const types = {
    SAVE_DATA: "SAVE_DATA",
    add: "add",
    CommonDataAdd: "CommonDataAdd",
    breadCrumb: "breadCrumb",
    menu: "menu",
    UPDATE_DRILLDOWN_DATA: "UPDATE_DRILLDOWN_DATA",
    UPDATE_RIDERSHIP_DRILLDOWN_DATA: "UPDATE_RIDERSHIP_DRILLDOWN_DATA",
    UPDATE_MAINTENANCE_DRILLDOWN_DATA: "UPDATE_MAINTENANCE_DRILLDOWN_DATA",
    UPDATE_FINANCE_DRILLDOWN_DATA: "UPDATE_FINANCE_DRILLDOWN_DATA",
    UPDATE_SAFETY_DRILLDOWN_DATA: "UPDATE_SAFETY_DRILLDOWN_DATA",
    UPDATE_SECURITY_DRILLDOWN_DATA: "UPDATE_SECURITY_DRILLDOWN_DATA",
    UPDATE_SERVICE_DRILLDOWN_DATA: "UPDATE_SERVICE_DRILLDOWN_DATA",
    SET_REPORT_DATA: "SET_REPORT_DATA",
    SET_REPORTS: "SET_REPORTS",
    SET_CURRENT_REPORTS:"SET_CURRENT_REPORTS",
    SET_PARAMS:"SET_PARAMS",
    SET_PARAM:"SET_PARAM",
    UPDATE_OPERATION_SELECTION: "UPDATE_OPERATION_SELECTION",
    UPDATE_OPERATION_CHART_INTERVAL: "UPDATE_OPERATION_CHART_INTERVAL",
    OPEN_MODAL: "OPEN_MODAL",
    CLOSE_MODAL: "CLOSE_MODAL",
    PARAM_FILTER: "PARAM_FILTER",
    VALUE_FILTER: "VALUE_FILTER",
    STATIC_VALUE_FILTER:"STATIC_VALUE_FILTER",
    STATIC_PARAM_FILTER:"STATIC_PARAM_FILTER",
    POWERBI_FILTER: "POWERBI_FILTER",
    SIDE_MENU_FILTER: "SIDE_MENU_FILTER",
    TAB: "TAB",
    CURRENT_REPORTS: "CURRENT_REPORTS",
    DISTRIBUTION: "DISTRIBUTION",
    SET_LOADING:"SET_LOADING",
    FILTER_STATIC_DATA:"FILTER_STATIC_DATA",
    LOAD_STATIC_DATA: "LOAD_STATIC_DATA",
    ADD_STATIC_DATA: "ADD_STATIC_DATA",
    SET_CURRENT_REPORTS_TABLE_COUNT: "SET_CURRENT_REPORTS_TABLE_COUNT"

}