import ThemeService from 'components/theme-service';
import Landingpage from 'pages/landingpage';
import React from 'react';
import FilterService from 'service/filterService';

export default function LandingPageCombined() {
  return (
    <div><ThemeService/>
    <FilterService/>
    <Landingpage/></div>
  )
}
