import React from "react";
import { Divider, Input, Popconfirm, Table, message } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Schema from "./schema";
import "./style.css";

class AntdTableComponent extends Schema {
  state = {
    columns: [],
    scrollY: 0,
    data: [],
    title: null,
    loading: false,
    editingKey: "",
    fieldEdit: {},
    subTableConfig: this.props.subTableConfig,
    selectedValues: this.props.selectedValues,
    selectedRowKeys: [],
    selectedRows: [],
    rowSelection:null
  };

  constructor(props) {
    super(props);
    this.tableContainer = React.createRef();
  }


  componentWillReceiveProps(nextProps) {
    this.setupData(nextProps);
    this.setupColumns(nextProps);
  }

  expandedRowRender = (record, index, indent, expanded) => {
    console.log(record, index, indent, expanded);
    const { columns = [], data = [], ...rest } = this.state.subTableConfig;
    const { primaryKey } = this.props;

    const subTableData = data.filter((n) => {
      return n[primaryKey] == record[primaryKey];
    });

    return (
      <Table
        key={index}
        columns={columns}
        dataSource={subTableData}
        pagination={false}
        indentSize={0}
        showHeader={false}
        {...rest}
      />
    );
  };

  renderExpandIcon = ({ expanded, onExpand, record }) =>
    expanded ? (
      <UpOutlined onClick={(e) => onExpand(record, e)} />
    ) : (
      <DownOutlined onClick={(e) => onExpand(record, e)} />
    );

  handleSelectChangeFromFilter = (selectedRowKeys, selectedRows) => {
    console.log("aravinth", selectedRowKeys);
    const { selectionEnabled, onSelectionChange = () => {} } = this.props;

    const { fromFilter } = selectionEnabled || {};
    if (fromFilter) {
      const { key } = fromFilter;
      const values = [];
      selectedRows.forEach((row, index) => {
        console.log(row);
        values.push(row[key]);
      });

      this.props.dispatch({ type: "UPDATE_SELECTED_VALUES", payload: {key:selectedRowKeys } });

      console.log(selectedRows);

      this.setState({
        selectedValues: values,
      });

      onSelectionChange(values, key);
    }
  };

  handlePageChange = (page, size) => {};

  handleSizeChange = (current, size) => {};

  setupScrollSize() {
    const container = this.tableContainer.current;
    const tableHead = container.querySelector(`.ant-table-header`);
    const tableTitle = container.querySelector(".ant-table-title");
    let scrollY = 0;

    if (tableHead) {
      const headerHeight = tableHead.clientHeight;
      scrollY = container.clientHeight - headerHeight - tableTitle.clientHeight;

      if (this.props.pagination) {
        const paginationHeight = container.querySelector(
          `.ant-table-pagination`
        ).clientHeight;
        scrollY -= paginationHeight + 32;
      }
    }

    if (scrollY !== this.state.scrollY) {
      this.setState({ scrollY });
    }
  }

  setupData(nextProps, pass) {
    let data = this.props.data;

    if (nextProps) {
      data = nextProps.data;
    }

    if (pass || JSON.stringify(data) !== JSON.stringify(this.props.data)) {
      this.setState({ data: [], loading: true }, () => {
        let timeCat = setTimeout(() => {
          this.setState({ data, loading: false });
          clearTimeout(timeCat);
        }, 1000);
      });
    }
  }

  setupColumns(props) {
    let { columns, data, editable, title, lazy } = props;
    let titleElement = null;

    let stateColumns = columns.map((c) => {
      if (c.customRender) {
        const useColumnValue = c.useColumnValue;
        c.render = c.customRender
      }

      if (c.suffix || c.prefix) {
        c.render = (_, record) => {
          return (
            <div>{this.appendSuffixAndPrefix(c, record[c.dataIndex])}</div>
          );
        };
      }

      if (c.format) {
        c.render = (t, record) => {
          return <div>{this.formatNumber(c.format, record[c.dataIndex])}</div>;
        };
      }

      if (c.customTitle) {
        c.title =
          typeof c.customTitle === "function"
            ? c.customTitle
            : () => (
                <span dangerouslySetInnerHTML={{ __html: c.customTitle }} />
              );
      }

      if (c.sortDirections) {
        let dataIndex = c.orderColumn ? c.orderColumn : c.dataIndex;
        c.sorter = (a, b) => {
          if (lazy) {
            return 0;
          }
          if (isNaN(a[dataIndex])) {
            return a[dataIndex].localeCompare(b[dataIndex]);
          }
          return a[dataIndex] - b[dataIndex];
        };
      }

      if (c.filterEnabled) {
        c = this.setupColumnFilter(c, data);
      }

      return c;
    });

    if (editable) {
      stateColumns.push({
        title: "",
        dataIndex: "operation",
        width: "15%",
        render: (text, record) => {
          const { editingKey } = this.state;
          const editable = this.isEditing(record);
          const _link = "#";
          return editable ? (
            <span>
              <a
                  href={_link}
                  onClick={() => this.save("", record.key)}
                  style={{ marginRight: 8 }}
                >
                  Save
                </a>

              <Popconfirm
                title="Sure to cancel?"
                onConfirm={() => this.cancel(record.key)}
              >
                <a href={_link}>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <span>
              <a
                href={_link}
                disabled={editingKey !== ""}
                onClick={() => this.props.editRow(record)}
              >
                Edit
              </a>
              
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure you want to delete?"
                onConfirm={() => this.props.deleteRow(record)}
              >
                <a href={_link} disabled={editingKey !== ""}>
                  Delete
                </a>
              </Popconfirm>

              

            </span>
          );
        },
      });
    }

    if (title) {
      titleElement = () => {
        return <h6>{title}</h6>;
      };
    }

    this.setState({ columns: stateColumns, title: titleElement });
  }

  setupColumnFilter(column, data) {
    let dataIndex = column.dataIndex;
    let values = data.reduce((result, value) => {
      result[value[dataIndex]] = 0;
      return result;
    }, {});

    column.filters = Object.keys(values).map((v) => {
      return { text: v, value: v };
    });
    column.onFilter = (value, record) => {
      return record[dataIndex] == value;
    };

    return column;
  }

  formatNumber(format, value) {
    let options = {
      style: format.style || "decimal",
      maximumFractionDigits: format.precision || 0,
    };

    if (format.style === "percent" && format.locale === "pt-BR") {
      value = (value || 0).toFixed(format.precision || 0).replace(".", ",");
      return `${value}%`;
    }

    if (format.style === "currency") {
      options["currency"] = format.currency || "USD";
    }

    return new Intl.NumberFormat(format.locale || "en-US", {
      ...options,
    }).format(value || 0);
  }

  appendSuffixAndPrefix(column, text) {
    if (column.prefix) {
      text = `${column.prefix}${text}`;
    }

    if (column.suffix) {
      text = `${text}${column.suffix}`;
    }
    return text;
  }

  cancel = () => {
    this.setState({ editingKey: "" });
  };

  isEditing = (record) => record.key === this.state.editingKey;

  save = (form, key) => {
    const newData = [...this.state.data];
    const index = newData.findIndex((item) => key === item.key);
    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...this.state.fieldEdit,
      });
      this.setState({ data: newData, editingKey: "" });
    } else {
      newData.push(this.state.fieldEdit);
      this.setState({ data: newData, editingKey: "" });
    }
  };

  // editRow = (row) => {
  //   this.setState({ editingKey: row.key, fieldEdit: row });
    
  // };

  verifyDuplicateRow = (row, data, count, indexRow) => {
    let _count = 0;
    let label = " (copy) ";
    Object.keys(row).forEach((key) => {
      row[key] += label;
    });
    data.forEach((d) => {
      if (JSON.stringify(d) === JSON.stringify(row)) {
        ++_count;
      }
    });
    if (_count > count) {
      this.verifyDuplicateRow(row, data, _count, indexRow);
    } else {
      data.splice(indexRow + 1, 0, row);
      this.setState({ data });
    }
  };

  duplicateRow = (row) => {
    let data = this.state.data;
    let copyRow = Object.assign({}, row);
    let indexRow = data.indexOf(row);
    let count = 0;
    this.verifyDuplicateRow(copyRow, data, count, indexRow);
  };

  deleteRow = (row) => {
    let data = this.state.data;
    let indexRow = data.indexOf(row);
    data.splice(indexRow, 1);
    this.setState({ data });
  };

  uploadRow = (row)=> {
    message.loading({
      content: 'Uploading...',
      key: row.key,
    });
    setTimeout(() => {
      message.success({
        content: 'Uploaded!',
        key: row.key,
        duration: 2,
      });
    }, 1000);
  }

  handleChangeEditValue = (event, index) => {
    let newFieldEdit = Object.assign({}, this.state.fieldEdit, {
      [index]: event.target.value,
    });
    this.setState({ fieldEdit: newFieldEdit });
  };

  tableChanged = (pagination, filters, sorter, extra) => {
    const { lazy } = this.props;
    if (extra.action === "sort" && lazy && lazy.datasetComponentId) {
    }

    console.log("extra", extra);
  };

  onPageLoadViewDefaultSelectd = () => 
    {
        const {  selectionEnabled,onSelectionChange=()=>{} } = this.props;       
        const { fromFilter } = selectionEnabled || {};        
        if (fromFilter) 
        {            
            const selectedRows =[
              {key: 1, partners: "Partners 1", name: "Alpha Data LLC (AUH)", logo: "/assest/partner1.png", head_quatars: "Abu Dhabi, UAE",partner_since:"April 2010",sales_shares:"10%"},
              {key: 2, partners: "Partners 2", name: "Denova Glosoft Limited", logo: "/assest/partner2.png", head_quatars: "Abu Dhabi, UAE",partner_since:"April 2010",sales_shares:"10%"},
              {key: 3, partners: "Partners 3", name: "Bluth Company", logo: "/assest/partner3.png", head_quatars: "Abu Dhabi, UAE",partner_since:"April 2010",sales_shares:"10%"}
            
            ]
            const selectedRowKeys = [1,2];
            const { key } = fromFilter;
            const values = [1,2];
            selectedRows.forEach((row, index) => 
            {
                console.log(row)
                values.push(row[key]);
            });            
            this.props.dispatch({ type: "UPDATE_SELECTED_VALUES", payload: {key:selectedRowKeys } });
            this.setState({
                selectedValues: values
            })

            onSelectionChange(values,key) 
        }        
    }

 
    componentDidMount() {
      this.setupData(null, true);
      this.setupColumns(this.props);
      this.onPageLoadViewDefaultSelectd();
    }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    console.log("selectedRowKeys changed: ", selectedRowKeys);
    console.log("selectedRows: ", selectedRows);
    this.setState({
      selectedRowKeys,
      selectedRows,
      selectedValues: { TopNTable: selectedRows },
    });
    this.props.selectedValues &&
      typeof this.props.selectedValues == "function" &&
      this.props.selectedValues({ TopNTable: selectedRows });
  };

  render() {
    const {
      rowKey,
      editable,
      pagination,
      selectionEnabled,
      horizontalScroll,
      verticalScroll,
      summary,
      locale,
    } = this.props;
    let scroll;
    console.log("props", this.props);
    if (horizontalScroll !== false || verticalScroll !== false) {
      scroll = { x: horizontalScroll, y: verticalScroll };
    }
    const { data, selectedValues, selectedRowKeys } = this.state;
    let paginationSetup,
      paginationParams = {};
    console.log("selectionValues", selectedValues);
    let rowSelection = null;
    if (
      this.props.selectionEnabled &&
      this.props.selectionEnabled.fromFilter.key === "TopNTable"
    ) {
      rowSelection = {
        selectedRowKeys,
        onChange: this.onSelectChange,
      };
    } else if (selectionEnabled) {
      const { fromFilter } = selectionEnabled || {};
      if (fromFilter) {
        const { key } = fromFilter;
        const selectedRowKeys = data.reduce((acc, cur) => {
          if (selectedValues.includes(cur[key])) {
            acc.push(cur[key]);
          }
          return acc;
        }, []);
       
        rowSelection = {
          selectedRowKeys,
          onChange: this.handleSelectChangeFromFilter,
        };
      }
    }

    if (pagination && Object.keys(pagination).length > 0) {
      paginationSetup = pagination;

      if (pagination.serverSide) {
        paginationParams = {
          pageSize: pagination.pageSize,
          total: pagination.total,
          showLessItems: pagination.showLessItems,
        };
        paginationSetup.onChange = this.handlePageChange;
        paginationSetup.onShowSizeChange = this.handleSizeChange;
        paginationSetup = { ...paginationSetup, ...paginationParams };
      }
    }
    if (editable) {
      const columns = this.state.columns.map((col) => {
        if (!col.editable) {
          return col;
        }
        return {
          ...col,
          render: (text, record) => {
            return (
              <div>
                {this.isEditing(record) ? (
                  <Input
                    defaultValue={this.state.fieldEdit[col.dataIndex]}
                    onChange={(e) => {
                      this.handleChangeEditValue(e, col.dataIndex);
                    }}
                  />
                ) : (
                  record[col.dataIndex]
                )}
              </div>
            );
          },
        };
      });
      console.log('SSSS',this.state.data)
      return (
        <div ref={this.tableContainer}>
          <Table
            rowKey={rowKey}
            loading={this.state.loading}
            columns={columns}
            dataSource={this.state.data}
            title={this.state.title}
            rowClassName="editable-row"
            pagination={{
              onChange: this.cancel,
            }}
            rowSelection={rowSelection}
            scroll={scroll}
            locale={locale}
          />
        </div>
      );
    }

    return (
      <div ref={this.tableContainer}>
        <Table          
          expandIcon={this.state.subTableConfig ? this.renderExpandIcon : false}
          expandedRowRender={
            this.state.subTableConfig ? this.expandedRowRender : null
          }
          loading={this.state.loading}
          columns={this.state.columns}
          dataSource={this.state.data}
          title={this.state.title}
          rowSelection={rowSelection}
          pagination={
            typeof paginationSetup === "undefined" ? false : paginationSetup
          }
          scroll={scroll}
          onChange={this.tableChanged}
          locale={locale}
          summary={() => {
            if (summary && summary.columns && summary.columns.length) {
              return (
                <>
                  <Table.Summary.Row style={summary.rowStyle}>
                    {summary.columns.map((column) => {
                      return (
                        <Table.Summary.Cell colSpan={column.colSpan}>
                          <div style={column.style}>{column.value}</div>
                        </Table.Summary.Cell>
                      );
                    })}
                  </Table.Summary.Row>
                </>
              );
            }
          }}
        />
      </div>
    );
  }
}

export default AntdTableComponent;
