import React, {useState,useEffect} from "react";
import { connect } from "react-redux";
import {Pagination} from 'antd';
import ForEachContainer from "../../../components/for-each-container";
import NotificationTile from "../../tiles/notification-tile";
import "./style.css";

function Notifications(props){
  
    console.log("  Notificationsre render")
    let selected;
    const {data=[],onShowAllClick, showAccordion,landingPageSelectedTab,notification_colors} = props;
    const pageSize= 3;
    const [paginationData, setPaginationData] = useState(data.slice(0,pageSize));
    const [currentPage, setCurrentPage] = useState(1);

    const getNotificationColors = () =>{
      let notification_colorObj = {};
      let index = 0
      data.map(item =>{
        if(!notification_colorObj[item.CATEGORY]){
          notification_colorObj[item.CATEGORY] = notification_colors[index]
          index = index===notification_colors.length?0:index+1;
        }
      })
      return notification_colorObj
    }
  

    const notification_color_config = getNotificationColors();

    useEffect(()=> {
      
      setCurrentPage(1);
      setPaginationData(data.slice(0,pageSize));
      
    }, [props.loading])

    const handlePaginationChange= (page) => {
      setCurrentPage(page);
      setPaginationData(data.slice((page-1)*pageSize,page*pageSize))
    }

    return (
      <div className="is-display-flex is-flex-column">
        <div className="is-align-self-end is-font-size-sm is-cursor-pointer is-text-white" onClick={onShowAllClick}>Show All</div>
      <ForEachContainer
        itemSpan={"12"}
        onlyFirst={false}
        itemsResolver={paginationData}
        resolvedItems={paginationData}
        containerStyle={{minHeight:'var(--is-hu-10)'}}
      >
        <NotificationTile accordionTile={showAccordion} landingPageSelectedTab={landingPageSelectedTab} notification_colors={notification_color_config}/>
      </ForEachContainer>
      <Pagination className="is-text-right" current={currentPage} hideOnSinglePage={true} onChange={handlePaginationChange} simple defaultPageSize={pageSize} defaultCurrent={1} total={data && data.length} />
      </div>
    );
}


const mapStateToProps = (state, ownProps) => ({  
  showAccordion: state.param.landingPageSelectedTab == 'FAQs',
  landingPageSelectedTab: state.param.landingPageSelectedTab,
  notification_colors:state.param.notification_colors
});

export default connect(mapStateToProps, null)(Notifications);
