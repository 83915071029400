import React from 'react';
import PropTypes from 'prop-types';

export default class Schema extends React.Component {

    static mapStateToProps = (state, ownProps) => {
        try {
            const { fromFilter } = ownProps.selectionEnabled;
            if (fromFilter) {
                const { filter } = fromFilter;
                const { filters } = state.components.filterService.props;
                return {
                    selectedValues: filters[filter].value
                }
            }
        } catch (err) {

        }
    };

    static metadata = {
        displayName: 'Antd Table Component',
        container: false,
        visual: true,
        builderCompatible: true,
        thumbnail: undefined,
        categories: ['General']
    };

    static propTypes = {
        rowKey: PropTypes.string,
        editable: PropTypes.bool,
        columns: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            dataIndex: PropTypes.string,
            key: PropTypes.string,
            render: PropTypes.func,
            sortDirections: PropTypes.arrayOf(PropTypes.string),
            filterEnabled: PropTypes.bool,
            customTitle: PropTypes.string,
            customRender: PropTypes.string,
            useColumnValue: PropTypes.bool,
            orderColumn: PropTypes.string,
            align: PropTypes.oneOf(['left', 'right', 'center'])
        })),
        pagination: PropTypes.shape({
            pageSize: PropTypes.number,
            showSizeChanger: PropTypes.bool,
            serverSide: PropTypes.bool,
            total: PropTypes.string
        }),
        lazy: PropTypes.shape({
            datasetComponentId: PropTypes.string.isRequired
        }),
        data: PropTypes.array,
        selectionEnabled: PropTypes.bool,
        horizontalScroll: PropTypes.bool,
        verticalScroll: PropTypes.bool,
        locale: PropTypes.PropTypes.shape()

    };

    static defaultProps = {
        cellClick: false,
        editable: false,
        rowKey: 'key',
        title: 'TABLE COMPONENT',
        columns: [],
        data: [],
        selectedRowKeys: [],
        selectionEnabled: true,
        currentPage: 1,
        pageSize: 4,
        horizontalScroll: false,
        verticalScroll: false,
        locale: {}
    };

    static i18n = {
        default: "en-US"
    };

}