import thunk from "redux-thunk";
import rootReducer from "./reducers";
import { createLogger } from 'redux-logger'
import { createStore, applyMiddleware, compose } from "redux";

const logger = createLogger({
});
const reduxMulti=({ dispatch })=> {
  return next => action =>
    Array.isArray(action)
      ? action.filter(Boolean).map(dispatch)
      : next(action)
}
const middleware = [thunk, logger,reduxMulti];


const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(...middleware),
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;