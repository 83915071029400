import ReactDOM from "react-dom";

import React, { useState } from "react";
import Pdf from "@mikecousins/react-pdf";
import Pdffile from './sample.pdf'
const MyPdfViewer = () => {
  const [page, setPage] = useState(1);

  return (
    <Pdf file={Pdffile} page={page}>
      {({ pdfDocument, pdfPage, canvas }) => (
        <>
          {!pdfDocument && <span>Loading...</span>}
          {canvas}
          {Boolean(pdfDocument && pdfDocument.numPages) && (
            <nav>
              <ul className="pager">
                <li className="previous">
                  <button
                    disabled={page === 1}
                    onClick={() => setPage(page - 1)}
                  >
                    ❮
                  </button>
                </li>
                <li className="next">
                  <button
                    disabled={page === pdfDocument.numPages}
                    onClick={() => setPage(page + 1)}
                  >
                     ❯
                  </button>
                </li>
                <button>
                <a href={Pdffile} class="button" download="Sample.pdf"><i class="fa fa-download"></i>Download</a>
                </button>
                
              </ul>
            </nav>
          )}
        </>
      )}
    </Pdf>
  );
};

export default MyPdfViewer
