const isSet = function (e) {
	if(typeof e === "undefined") return false;
	if(e == null || e == 'null') return false;
	return true;
};

const setHeightUnit = function() {
    var fontPivot = getRootColor('--is-font-size-pivot');
    var h = (10 / fontPivot.replace('px', ''));
    document.documentElement.style.setProperty('--is-hu', h+'rem');
    return h;
}

const getColorVariation = function(color, percent, isLight) {
	if(!isSet(isLight)) isLight = 1;
	var hls = getHSLColors(color);
    var h = hls[0], s = hls[1], l = parseInt(hls[2]), lF, c;
	if(isLight === 1) {
		lF = lighten(l, percent);
	} else {
		lF = darken(l, percent);
	}
	return 'hsl(' + h + ',' + s + '%,' + lF + '%)';
}
const colorVariation = function(varName, color, percent, isLight) {
	const c =  getColorVariation(color, percent, isLight);
	document.documentElement.style.setProperty(varName, c);
    return c;
}
const textColor = function(varName, color, text, textInverse) {
	if(!isSet(text)) text = '#000';
	if(!isSet(textInverse)) textInverse = '#fff';
	var tC = getTextColor(color, text, textInverse);
	document.documentElement.style.setProperty(varName, tC);
    return tC;
}
const getTextColor = function(color, text, textInverse, high) {
    var bgLuma = luma(color);
    var c1Luma = luma(text);
    var c2Luma = luma(textInverse);
    var c1Diff = Math.abs(bgLuma - c1Luma);
    var c2Diff = Math.abs(bgLuma - c2Luma);
	if(isSet(high) && high == 1) {
		if(c1Diff > c2Diff) {
			return getColorVariation(text, 60, 0);
		} else {
			return getColorVariation(textInverse, 30, 1);
		}
	} else {
		return (c1Diff > c2Diff) ? text : textInverse;
	}
};

const setChartColorPalette = function(element) {
    for (let i = 1; i <= 12; i++) {
        const fullElementName = `${element}-${i}`;
        const color = getRootColor(fullElementName);
        const hls = getHSLColors(color);
        const h = hls[0], s = hls[1], l = parseInt(hls[2]);
        const main = 'hsl(' + h + ',' + s + '%,' + l + '%)';
        const inverseLight = 'hsl(' + h + ',' + s + '%,' + lighten(l, 95) + '%)';
        let vText = 'hsl(' + h + ',' + s + '%,' + darken(l, 30) + '%)';
        if(l >= 50) {
            vText = 'hsl(' + h + ',' + s + '%,' + darken(l, 50) + '%)';
        }
        const tC = getTextColor(main, vText, inverseLight, 1);
        document.documentElement.style.setProperty(fullElementName+'-text', tC);
    }
}

//setColorVariations();
const setColorPalette = function(element) {

	// main color
	var El = (element == '--is-default') ? '--is-text' : element;
    var color = getRootColor(El);
    
    if(color !== '') {
        var hls = getHSLColors(color);
        var h = hls[0], s = hls[1], l = parseInt(hls[2]), lF = [], tC;
        // Set vars in document
        // Color
        var main = 'hsl(' + h + ',' + s + '%,' + l + '%)';
        document.documentElement.style.setProperty(element, main);
        // inverse light
        lF = lighten(l, 95);
        var inverseLight = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
        document.documentElement.style.setProperty(element+'-inverse-light', inverseLight);
        // inverse
        lF = lighten(l, 90);
        var inverse = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
        document.documentElement.style.setProperty(element+'-inverse', inverse);
        // inverse dark
        lF = lighten(l, 70);
        var inverseDark = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
        document.documentElement.style.setProperty(element+'-inverse-dark', inverseDark);
        // inverse darker
        lF = lighten(l, 50);
        var inverseDarker = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
        document.documentElement.style.setProperty(element+'-inverse-darker', inverseDarker);
        // inverse darkest
        lF = lighten(l, 30);
        var inverseDarkest = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
        document.documentElement.style.setProperty(element+'-inverse-darkest', inverseDarkest);
        // light
        lF = lighten(l, 20);
        var light = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
        document.documentElement.style.setProperty(element+'-light', light);
        // dark
        lF = darken(l, 30);
        var dark = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
        document.documentElement.style.setProperty(element+'-dark', dark);
        // darker
        lF = darken(l, 60);
        var darker = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
        document.documentElement.style.setProperty(element+'-darker', darker);
        // darkest
        lF = darken(l, 80);
        var darkest = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
        document.documentElement.style.setProperty(element+'-darkest', darkest);
        // Border Colors
        document.documentElement.style.setProperty(element+'-border', dark);
        document.documentElement.style.setProperty(element+'-border-hover', darker);
        // inverse
        document.documentElement.style.setProperty(element+'-inverse-border', inverseDark);
        document.documentElement.style.setProperty(element+'-inverse-border-hover', inverseDarker);
        // Text Colors
		var vText = dark;
		var vTextDisabled = light;
		var vlink = darker;
		var vlinkHover = main;
		var high = 1;
		if(l >= 50) {
			lF = darken(l, 50);
			var vText = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
			
			lF = darken(l, 20);
			var vTextDisabled = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
			
			lF = darken(l, 50);
			var vlink = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
			
			lF = darken(l, 10);
			var vlinkHover = 'hsl(' + h + ',' + s + '%,' + lF + '%)';
		}
		
        // main color
        tC = getTextColor(main, vText, inverseLight, high);
        document.documentElement.style.setProperty(element+'-text', tC);
        // light
        tC = getTextColor(light, vText, inverseLight, high);
        document.documentElement.style.setProperty(element+'-light-text', tC);
        // dark
        tC = getTextColor(dark, vText, inverseLight, high);
        document.documentElement.style.setProperty(element+'-dark-text', tC);
        // darker
        tC = getTextColor(darker, vText, inverseLight, high);
        document.documentElement.style.setProperty(element+'-darker-text', tC);
        // darkest
        tC = getTextColor(darkest, vText, inverseLight, high);
        document.documentElement.style.setProperty(element+'-darkest-text', tC);
		
        tC = getTextColor(main, vTextDisabled, inverse, 0);
        document.documentElement.style.setProperty(element+'-text-disabled', tC);
        tC = getTextColor(main, vlink, inverseLight, high);
        document.documentElement.style.setProperty(element+'-link', tC);
        tC = getTextColor(main, vlinkHover, inverseDarker, high);
        document.documentElement.style.setProperty(element+'-link-hover', tC);
		
        // inverse
        tC = getTextColor(inverse, vText, inverseLight, high);
        document.documentElement.style.setProperty(element+'-inverse-text', tC);
        // inverse light
		tC = getTextColor(inverseLight, vText, inverseLight, high);
		document.documentElement.style.setProperty(element+'-inverse-light-text', tC);
        // inverse dark
        tC = getTextColor(inverseDark, vText, inverseLight, high);
        document.documentElement.style.setProperty(element+'-inverse-dark-text', tC);
        // inverse darker
        tC = getTextColor(inverseDarker, vText, inverseLight, high);
        document.documentElement.style.setProperty(element+'-inverse-darker-text', tC);
        // inverse darkest
        tC = getTextColor(inverseDarkest, vText, inverseLight, high);
        document.documentElement.style.setProperty(element+'-inverse-darkest-text', tC);
		
        tC = getTextColor(inverse, vTextDisabled, inverse, 0);
        document.documentElement.style.setProperty(element+'-inverse-text-disabled', tC);
        tC = getTextColor(inverse, vlink, inverseLight, high);
        document.documentElement.style.setProperty(element+'-inverse-link', tC);
        tC = getTextColor(inverse, vlinkHover, inverseDarker, high);
        document.documentElement.style.setProperty(element+'-inverse-link-hover', tC);
    }
}

// Troca as classes dos elementos 'outline' por 'outline-inverse', e vice versa...
const setOutlinePalette = function() {

	var states = ['default', 'primary', 'secondary', 'accent', 'success', 'warning', 'danger'];
	for(var i = 0; i < states.length; i++) {
		var def = 'is-'+states[i]+'-outline';
		var inv = 'is-'+states[i]+'-outline-inverse';
		var tmp = '-tmp';
		
		// 'outline' => 'outline-inverse-tmp'
		var outline = document.querySelectorAll('.'+def);
		for(var a = 0; a < outline.length; a++) { 
			if(isSet(outline[a])) {
				outline[a].classList.remove(def);
				outline[a].classList.add(inv+tmp);
			}
		}
		// 'outline-inverse' => 'outline'
		var inverse = document.querySelectorAll('.'+inv);
		for(var a = 0; a < inverse.length; a++) {
			if(isSet(inverse[a])) {
				inverse[a].classList.remove(inv);
				inverse[a].classList.add(def);
			}
		}
		// 'outline-inverse-tmp' => 'outline-inverse'
		var invTmp = document.querySelectorAll('.'+inv+tmp);
		for(var a = 0; a < invTmp.length; a++) {
			if(isSet(invTmp[a])) {
				invTmp[a].classList.remove(inv+tmp);
				invTmp[a].classList.add(inv);
			}
		}
	}
	
}

const getRootColor = function(e) {
    return getComputedStyle(document.documentElement).getPropertyValue(e).trim();
}
const lighten = function(l, percent) {
    //var f = (l > 50) ? (percent + 5) : (percent - 5);
    return Math.round(l + (100 - l) * (percent / 100));
}
const darken = function(l, percent) {
    return Math.round(l - (l * (percent / 100)));
}
const themeFactor = function(bg, dark, light, isLight) {
	if(!isSet(dark)) dark = '#000';
	if(!isSet(light)) light = '#fff';
	var f = getTextColor(bg, dark, light);
	if(f == light) {
		return (isLight == 1) ? 0 : 1;
	} else {
		return isLight;
	}
}
const luma = function(color) {
    var r = 0, g = 0, b = 0, match = [];
    // 3 digits {#fff}
    if (color.length === 4) {
        r = "0x" + color[1] + color[1];
        g = "0x" + color[2] + color[2];
        b = "0x" + color[3] + color[3];
        // 6 digits {#ffffff}
    } else if (color.length === 7) {
        r = "0x" + color[1] + color[2];
        g = "0x" + color[3] + color[4];
        b = "0x" + color[5] + color[6];
    } else if (color.indexOf('rgb') !== -1) {
        match = color.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
        r = match[1];
        g = match[2];
        b = match[3];
    } else if (color.indexOf('hsl') !== -1) {
        match = color.match(/hsla?\((\d{1,3}), ?(\d{1,3})%, ?(\d{1,3})%\)?(?:, ?(\d(?:\.\d?))\))?/);
        var rgb = hslToRgb(match[1], match[2], match[3]);
        r = rgb[0];
        g = rgb[1];
        b = rgb[2];
    }
    return ((0.2126 * r) + (0.7152 * g) + (0.0722 * b)) / 255; // SMPTE C, Rec. 709 weightings
}
const hslToRgb = function(h, s, l) {
    var r, g, b, m, c, x
    if (!isFinite(h)) h = 0
    if (!isFinite(s)) s = 0
    if (!isFinite(l)) l = 0
    h /= 60
    if (h < 0) h = 6 - (-h % 6)
    h %= 6
    s = Math.max(0, Math.min(1, s / 100))
    l = Math.max(0, Math.min(1, l / 100))
    c = (1 - Math.abs((2 * l) - 1)) * s
    x = c * (1 - Math.abs((h % 2) - 1))
    if (h < 1) {
        r = c
        g = x
        b = 0
    } else if (h < 2) {
        r = x
        g = c
        b = 0
    } else if (h < 3) {
        r = 0
        g = c
        b = x
    } else if (h < 4) {
        r = 0
        g = x
        b = c
    } else if (h < 5) {
        r = x
        g = 0
        b = c
    } else {
        r = c
        g = 0
        b = x
    }
    m = l - c / 2
    r = Math.round((r + m) * 255)
    g = Math.round((g + m) * 255)
    b = Math.round((b + m) * 255)
    return [r, g, b];
}
const getHSLColors = function(color) {
    var h = 0, s = 0, l = 0;
    if(color !== '' && color !== 'undefined' && color !== null) {
        var r = 0, g = 0, b = 0, match = [];
        // 3 digits {#fff}
        if (color.length === 4) {
            r = "0x" + color[1] + color[1];
            g = "0x" + color[2] + color[2];
            b = "0x" + color[3] + color[3];
            // 6 digits {#ffffff}
        } else if (color.length === 7) {
            r = "0x" + color[1] + color[2];
            g = "0x" + color[3] + color[4];
            b = "0x" + color[5] + color[6];
        } else if (color.indexOf('rgb') !== -1) {
            match = color.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
            r = match[1];
            g = match[2];
            b = match[3];
        } else if (color.indexOf('hsl') !== -1) {
            match = color.match(/hsla?\((\d{1,3}), ?(\d{1,3})%, ?(\d{1,3})%\)?(?:, ?(\d(?:\.\d?))\))?/);
            return [match[1], match[2], match[3]];
        }
        // Make r, g, and b fractions of 1
        r /= 255;
        g /= 255;
        b /= 255;
        // Find greatest and smallest channel values
        var cmin = Math.min(r,g,b),
            cmax = Math.max(r,g,b),
            delta = cmax - cmin;
        // Calculate hue
        // No difference
        if (delta === 0)
            h = 0;
        // Red is max
        else if (cmax === r)
            h = ((g - b) / delta) % 6;
        // Green is max
        else if (cmax === g)
            h = (b - r) / delta + 2;
        // Blue is max
        else
            h = (r - g) / delta + 4;
        h = Math.round(h * 60);
        // Make negative hues positive behind 360°
        if (h < 0)
            h += 360;
        // Calculate lightness
        l = (cmax + cmin) / 2;
        // Calculate saturation
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        // Multiply l and s by 100
        s = +Math.round(s * 100);
        l = +Math.round(l * 100);
    }
    return [h, s, l];
}

const setVariationVar = function(varName, newValues, color, percent, isLight) {
    return!newValues[varName] ? colorVariation(varName, color, percent, isLight) : newValues[varName];
}

const setTextColorVar = function(varName, newValues, color, text, textInverse) {
    return !newValues[varName] ? textColor(varName, color, text, textInverse) : newValues[varName];
}

export default class VariablesManipulator {
    static getUpdatedVariables = () => {
        const varList = Array.from(document.styleSheets)
        .filter(sheet => sheet.href === null || sheet.href.startsWith(window.location.origin))
        .reduce((acc, sheet) =>
            (acc = [...acc, ...Array.from(sheet.cssRules).reduce((def, rule) =>
                    (def = rule.selectorText === ":root" ? [
                            ...def,
                            ...Array.from(rule.style).filter(name => name.startsWith("--"))
                        ] : def), []
                    )
            ]), []
        );
        const tempDiv = document.createElement('div');
        const root = document.querySelector(':root');
        const rootStyle = getComputedStyle(root);
        const TEMP_DIV_ID = 'temp_div_theme_service';
        let variables = {};
        tempDiv.style.display = 'none';
        tempDiv.id = TEMP_DIV_ID;
        document.body.appendChild(tempDiv);
        for (let currVar of varList) {
            const varName = currVar.replace('--', '').replace(/-([\S])/g, (match, s1) => {return s1.toUpperCase();});
            let propertyValue = rootStyle.getPropertyValue(currVar).trim();
            if ((propertyValue.includes('calc') || propertyValue.includes('em')) && !propertyValue.includes('rgba')) {
                document.querySelector(`#${TEMP_DIV_ID}`).style.width = propertyValue;
                variables[varName] = getComputedStyle(document.querySelector(`#${TEMP_DIV_ID}`)).width;
            } else {
                variables[varName] = propertyValue;
            }
        }
        tempDiv.parentNode.removeChild(tempDiv);
        return variables;
    }

    static setColorVariations = function(newValues) {
        
        var themeBg = getRootColor('--is-theme-bg');
        var primary = getRootColor('--is-primary');
        // Verifica se o background é claro ou escuro
        // 1 => fundo claro / 0 => fundo escuro
        var bgLight = getRootColor('--is-bgLight');
        // Atualiza o valor com base no background informado
        document.documentElement.style.setProperty('--is-bgLight', _lighten);
        
        var _light = setVariationVar('--is-light', newValues, themeBg, 95, 1);
        var _dark = setVariationVar('--is-dark', newValues, themeBg,  85, 0);
        setTextColorVar('--is-light-text', newValues, _light);
        setTextColorVar('--is-dark-text', newValues, _dark);
        
        var _lighten = themeFactor(themeBg, _dark, _light, 1);
        var _darken = themeFactor(themeBg, _dark, _light, 0);
    
        // =================================================================
        var themeBgMuted = setVariationVar('--is-theme-bg-muted', newValues, themeBg, 5, _darken);
        setVariationVar('--is-theme-bg-muted-strong', newValues, themeBg, 12, _darken);
        setVariationVar('--is-theme-bg-inverse', newValues, themeBg, 90, _darken);
        setVariationVar('--is-theme-bg-inverse-muted', newValues, themeBg, 82, _darken);
        setVariationVar('--is-theme-bg-inverse-muted-strong', newValues, themeBg, 75, _darken);
        
        //===> TEXT COLOR
        var text = setVariationVar('--is-text', newValues, themeBg, _lighten == 1 ? 70 : 85, _darken);
        var textInverse = setVariationVar('--is-text-inverse', newValues, text, 95, _lighten);
        setVariationVar('--is-text-muted', newValues, text, 40, _lighten);
        setVariationVar('--is-text-inverse-muted', newValues, text, 70, _lighten);
    
        //==> LINK
        var linkColor = setTextColorVar('--is-link-color', newValues, themeBg, primary, textInverse);
        setVariationVar('--is-link-color-hover', newValues, linkColor, 25, 0);
        setVariationVar('--is-link-color-inverse', newValues, linkColor, 95, _lighten);
        setVariationVar('--is-link-color-inverse-hover', newValues, linkColor, 70, _lighten);
        
        //==> BORDER
        setVariationVar('--is-border', newValues, themeBg, 20, _darken);
        setVariationVar('--is-border-thin', newValues, themeBg, 10, _darken);
        setVariationVar('--is-border-bold', newValues, themeBg, 40, _darken);
        
        //==> COMPONENT
        setVariationVar('--is-component-bg', newValues, themeBg, 95, _lighten);
        var componentBgMuted = setVariationVar('--is-component-bg-muted', newValues, themeBgMuted, 60, _lighten);
        setVariationVar('--is-component-text', newValues, text, 0, _lighten);
        setVariationVar('--is-component-text-muted', newValues, componentBgMuted, 30, _darken);
        
        // Default [text based]
        setColorPalette('--is-default');
        // Primary
        setColorPalette('--is-primary');
        // Secondary
        setColorPalette('--is-secondary');
        // Accent
        setColorPalette('--is-accent');
        // Success
        setColorPalette('--is-success');
        // Warning
        setColorPalette('--is-warning');
        // Danger
        setColorPalette('--is-danger');

        setChartColorPalette('--is-palette-divergent');
        setChartColorPalette('--is-palette-standard');
        setChartColorPalette('--is-palette-single-hue');

        if(bgLight != _lighten) setOutlinePalette();
        
        return true;
        
    };
}