import axios from "axios";
const ENDPOINT = window.location.origin+"/userdetails";


export default {
  fetchUser: () =>
    axios({
      method: "GET",
      url: ENDPOINT
    })
};
