import React from 'react';
import Schema from './schema';
import Events from './events';
import style from './theme-controller.css';
import './antd-override.css';
import './webix-override.css';
import './formio-override.css';
import './teste.less';

export default class ThemeService extends React.Component {

    getData = async (endpoint) => {
        /*
        return Api.fetch('/api/themeservice/' + endpoint, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            return response.json().then(function(data) {
                return data;
            });
        });
        */
    }

    getFrameworkThemes = async() => {
        const {dispatch, currentTheme, currentBreakpoint, accessibility} = this.props;
        const data = await this.getData('theme');
        if (data && data.length) {
            //dispatch(Events.LIST_LOADED(data));
            const foundTheme = (data || []).filter((theme) => theme.name === currentTheme)[0];
            //this.props.dispatch(Events.THEME_CHANGED(foundTheme, currentBreakpoint, accessibility));
        }
    }

    getChartThemes = async() => {
        const {dispatch} = this.props;
        const data = await this.getData('chartTheme');
        if (data && data.length) {
            //dispatch(Events.CHART_THEME_LIST_LOADED(data));
        }
    }

    setGrayscale = () => {
        const {isGrayscale} = this.props;
        if (isGrayscale) {
            document.body.style.filter = 'grayscale(1)';
        }
    }

    componentDidMount = () => {
        this.getFrameworkThemes();
        this.getChartThemes();
        this.setGrayscale();
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.props.currentTheme !== nextProps.currentTheme || this.props.currentBreakpoint !== nextProps.currentBreakpoint) {
            const foundTheme = (this.props.themes || []).filter((theme) => theme.name === nextProps.currentTheme)[0];
            //this.props.dispatch(Events.THEME_CHANGED(foundTheme, nextProps.currentBreakpoint, nextProps.accessibility));
        }
    }

    render = () => {
        return (
            <div className={style.root}/>
        )
    }

}
