import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import store from "./store/store";
import { Provider } from "react-redux";
import 'antd/dist/antd.css'
import './custom_style.less'
import App from "./app"

ReactDOM.render(
  <Provider store={store}>
    <React.Fragment>
      <App/>
    </React.Fragment>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
