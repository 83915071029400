import React from 'react';
import PropTypes from 'prop-types';

export default class Schema extends React.Component {

    static metadata = {
        displayName: 'Semantic Component',
        container: false,
        visual: true,
        builderCompatible: true,
        thumbnail: undefined,
        categories: ['General']
    };

    static propTypes = {
        value: PropTypes.string.isRequired,
        actions: PropTypes.arrayOf(PropTypes.func)
    };

    static defaultProps = {
    };

    static mapStateToProps = (state, ownProps) => {
        return { 
            variables: state.components.themeService.props.variables || {}
        };
    }

    static i18n = {
        default : "en-US"
    };

}