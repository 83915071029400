import axios from "axios";
let ENDPOINT =  window.location.origin+"/datarouter/data/search"
if(window.location.host.includes('localhost')){
  ENDPOINT="http://localhost:8080/dataService/data/search"
}
// https://dataservice-lnkibq352q-uc.a.run.app

export default {
  getMethod: () =>
    axios({
      method: "GET",
      url: ENDPOINT,
      // 'headers': {
      //     Authorization: token
      // }
    }),
  fetchReport: (reportName, filters = [],dynamicColumns = []) =>
    axios({
      method: "POST",
      url: ENDPOINT,
      data: {
        elasticQueryName:reportName,
      reportName:reportName,
        filters,
        dynamicColumns
      },
      // 'headers': {
      //     Authorization: token
      // }
    }),
  postMethod: (reportName, requestBody) =>
    axios({
      method: "POST",
      url: ENDPOINT,
      // 'headers': {
      //     Authorization: token
      // },
      data: {
        reportName: reportName,
      },
    }),
  putMethod: (reportingName, requestBody) =>
    fetch(ENDPOINT, {
      method: "PATCH",
      //headers: {    "Content-type": "application/json",  Authorization: token  },
      body: JSON.stringify(requestBody),
    }),
};
