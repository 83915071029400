import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LandingPageCombined from "PageCombiner/LandingScreen.js";
import LoginPage from 'PageCombiner/LoginPage.js';

window.current_customer = (()=>{
  let currentOrigin = window.location.origin;
  switch (true) {
    case currentOrigin.includes("greece"):
      return 'gcsd';

    case currentOrigin.includes("k12360.innive.io"):
      return "demo";
    
    case currentOrigin.includes("knox"):
      return "knox";

    default:
      return "demo"; //hardcode here for local
  }
})();

export default function App (){
  return <div>
    <Router>
      <Switch>
        <Route exact path="/" component={LandingPageCombined} />
        <Route exact path="/login" component={LoginPage}/>
      </Switch>
      </Router>
      {window.location.pathname.includes(".html") && window.location.pathname.includes("login.html") ? <LoginPage/>: window.location.pathname.includes("index.html") ?  <LandingPageCombined/> : null}
    
  </div>
}
