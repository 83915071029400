import React, { useState, useEffect } from "react";
import { createSelector } from "reselect";
import Container from "../../components/Container";
import Notifications from "../../templates/analytics/notifications";
import UsefulLinksTile from "../../templates/analytics/useful-link-tile";
import MiddleSection from "../../templates/analytics/middle-section";
import TableModal from "../../templates/analytics/table-modal";
import LausdLandingpageTopTile from "../../templates/analytics/lausd-landingpage-top-tile";
import ModalVideoPlayer from "../../templates/analytics/modal-video-player";
import TabExplorer from "../../components/tab-explorer-component";
import Footer from "../../templates/footer/lausd-footer";
import ViewBoxComponent from "@innive/view-box-component";
import {
  setStaticValueFilter,
  setStaticData,
  setcommonDataAdd,
} from "../../store/actions/staticReport";
import { setCurrentReports } from "../../store/actions/report";
import { setParam } from "../../store/actions/param";
import { setLoading } from "../../store/actions/report";
import { setUserData } from "../../store/actions/user";
import "./style.css";
import { connect } from "react-redux";
import "../../components/Header/index.css";
import landingPageTopTile1 from "../.././assest/landingpage-toptile.png";
// "assest/landingpage-toptile.png"
import landingPageTopTile2 from "../.././assest/background2.png";
import CarouselContainer from "components/carousel-container";
import ImageTile from "templates/tiles/top-tile";
import MiddleCards from "components/MiddleCards";
import { Spin } from "antd";
import { CURRENT_CUSTOMER, CURRENT_ROLE_CUSTOMER_BASED } from "utils/customerBasedConfigs";

import { Modal } from "antd";
const reports = () => [
  { reportName: "Landing_Page_WhatsNew" },
  { reportName: "Landing_Page_JobAids" },
  { reportName: "Landing_Page_FAQ" },
  {
    reportName: "Landing_Page_Application_Links"
  },
];

const reportMapping = {
  "Whats New": "Landing_Page_WhatsNew_Preview",
  "System Notification": "Landing_Page_Notification_Preview",
  "Training Videos": "Landind_Page_Videos",
  "Tutorial of The Week": "Landing_Page_JobAids",
  FAQs: "Landing_Page_FAQ",
  "Useful Links": "Landing_Page_Application_Links",
};

const AppComponent = (props) => {
  // debugger
  const [bgImage, setBgImage] = useState(landingPageTopTile1);
  const columns = [
    { size: 12 },
    { size: 12 },
    { size: 12, classes: ["useful-link-bg"] },
    { size: 12 },
  ];
  const {
    videoPlayerModal,
    Landing_Page_Application_Links,
    Landing_Page_JobAids,
    Landing_Page_FAQ,
    Landing_Page_WhatsNew,
    usefulLinksData,
    landingPageTopTileData,
    middleCardTilesData,
    notificationCardData,
    userCredentialsData,
    Landing_Page_Training_Video,
    setStaticData,
    setCurrentReports,
    setParam,
    isLoading,
    setUserData,
    setcommonDataAdd,
    setLoading
  } = props;
  const [loggingOut, setloggingout] = useState(false);

  const [isIframeLoaded, setIsIframeLoaded] = useState(true);

  useEffect(async () => {
    await setUserData();
    setCurrentReports(reports());
    setStaticData([
      "whatsnew",
      "usefulLink",
      "usefulLinks",
      "selectionTile",
      "notifications",
      "allNotifications",
      "middleCardTiles",
      "userCredentials",
      "notificationCard",
      "landingPageTopTile",
      "dashboardInfo",
    ]);
  }, []);
  useEffect(() => {
    let {
      middleCardTilesData,
      Landing_Page_FAQ,
      Landing_Page_WhatsNew,
      Landing_Page_JobAids,
    } = props;
    if (
      (middleCardTilesData && Landing_Page_FAQ && Landing_Page_WhatsNew,
      Landing_Page_JobAids)
    ) {
      const updatedMiddleCardTilesData = middleCardTilesData.map((ele) => {
        // Create a new object for each element to avoid modifying the original
        const updatedElement = { ...ele };

        if (ele.isFaq) {
          updatedElement.content = Landing_Page_FAQ;
        } else if (ele.isWhatsnew) {
          updatedElement.content = Landing_Page_WhatsNew;
        } else if (ele.isTutorial) {
          updatedElement.content = Landing_Page_JobAids;
        }

        return updatedElement;
      });

      // Check if the state needs to be updated before calling setcommonDataAdd
      const isStateUpdated =
        JSON.stringify(updatedMiddleCardTilesData) !==
        JSON.stringify(props.middleCardTilesData);

      if (isStateUpdated) {
        setcommonDataAdd({ middleCardTilesData: updatedMiddleCardTilesData });
      }
    }
  }, [
    middleCardTilesData,
    Landing_Page_FAQ,
    Landing_Page_WhatsNew,
    setcommonDataAdd,
    Landing_Page_JobAids,
  ]);

 

  const handleUsefulTileClick = (data) => {
    let $userInfo = {};
    let userRole = props?.user?.authorities[0]?.authority;
    let customerBasedRole = CURRENT_ROLE_CUSTOMER_BASED()[userRole]
      ? CURRENT_ROLE_CUSTOMER_BASED()[userRole]
      : userRole;
    let employeeId = props?.user?.user?.attributes?.employeeId[0]?.EMPLOYEE_ID;
    let forDev = props.userCredentialsData.find(
      (ele) => ele.ROLE.toLowerCase() == data.ROLE.toLowerCase()
    );
    if (customerBasedRole && customerBasedRole != "DEVELOPER") {
      $userInfo.USERNAME = props?.user?.user?.username || "";
      $userInfo.ROLE = userRole || "";
      $userInfo.EMPLOYEE_ID = employeeId || "";
    } else {
      $userInfo = forDev;
    }
    if ($userInfo && data.WORKBENCH) {
      $userInfo.WORKBENCH = data.WORKBENCH;
    }
    if (CURRENT_CUSTOMER() != "greece" && customerBasedRole && customerBasedRole == "DEVELOPER") {
      sessionStorage.setItem("$UserLoginInfo", JSON.stringify($userInfo));
    }
    if(CURRENT_CUSTOMER() == "greece"){
      sessionStorage.setItem("$UserLoginInfo", JSON.stringify({ROLE : data.ROLE}));
    }
    if(window.location.origin.includes("knoxschools")){
      sessionStorage.setItem("$UserLoginInfo", JSON.stringify(forDev));
    }
    const item = Array.isArray(data) ? data[0] : data;
    item.link && window.open(item.link, "_blank");
  };

  const handleShowAllClick = () => {
    setParam({ usefulLinksModal: false, landingPageTableModal: true });
  };

  const handleUsefulShowAll = () => {
    setParam({ usefulLinksModal: true, landingPageTableModal: true });
  };

  const renderTabs = () => {
    let keys = Object.keys(reportMapping).filter(
      (key) => key != "Useful Links"
    );
    let tabs = [];
    keys.forEach((key) => {
      tabs.push({
        tabName: key,
        child: (
          <Notifications
            loading={isLoading}
            data={props[reportMapping[key]]}
            onShowAllClick={handleShowAllClick}
          />
        ),
      });
    });
    return tabs;
  };
  const handleIframeLoad = () => {
    setIsIframeLoaded(false);
  };
  return (
    <Spin
      tip={
        <span style={{ fontSize: "19px", color: "#055ba8" }}>
          Logging Out...
        </span>
      }
      spinning={loggingOut}
      size="large"
    >
      <Container loading={isLoading}>
        <TableModal />
        {window.current_customer === "gcsd" ? 
        (<Modal
          className="video"
          destroyOnClose={true}
          title="GREECE Central School District"
          visible={videoPlayerModal}
          onCancel={() => {
            setParam({ videoPlayerModal: false });
            setIsIframeLoaded(true)
          }}
          footer={null}
          centered
          style={{ width: "80%" }}
        >
          <Spin spinning={isIframeLoaded} size="large">
            <iframe
              title="Embedded Content"
              src="https://elocallink.tv/e/share.php?ffb=1&v=mt9&ap=1"
              width="100%"
              height="600"
              frameBorder="0"
              onLoad={handleIframeLoad}
            />
          </Spin>
        </Modal>) : 
        (
          <ModalVideoPlayer />
        )
        }

       
        

        <div className="pageContainer">
          <ViewBoxComponent columns={columns}>
            <LausdLandingpageTopTile
              loggingOutChange={setloggingout}
              useFulLinkData={usefulLinksData}
              param={setParam}
              notifyData={notificationCardData}
              data={landingPageTopTileData}
              onClick={handleUsefulTileClick}
            />
            <UsefulLinksTile
              data={usefulLinksData}
              dataMapping={{
                img: "MODULEIMGURL",
                title: "MODULENAME",
                description: "MODULEDESCRIPTION",
                link: "MODULELINK",
              }}
              tileClick={handleUsefulTileClick}
              showAllClick={handleUsefulShowAll}
            />
            
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div
                style={{ height: "fit-content", marginTop: "26px" }}
                className="middleCardHolder is-mar-bottom-5 la-top-tile-bg is-hu-30 is-align-self-end is-rounded"
              >
                <MiddleCards data={middleCardTilesData} setParam={setParam} />
              </div>
            </div>

            <Footer data={usefulLinksData} />
          </ViewBoxComponent>
        </div>
      </Container>
    </Spin>
  );
};
const getUserData = createSelector(
  (state) => state.reports.Landing_Page_Application_Links,
  (Landing_Page_Application_Links) => {
    return Landing_Page_Application_Links;
  }
);

const landingFaq = createSelector(
  (state) => state.reports.Landing_Page_FAQ,
  (Landing_Page_FAQ) => {
    return Landing_Page_FAQ;
  }
);
const landingWhatsnew = createSelector(
  (state) => state.reports.Landing_Page_WhatsNew,
  (Landing_Page_WhatsNew) => {
    return (Landing_Page_WhatsNew || []);
  }
);
const landingTutorial = createSelector(
  (state) => state.reports.Landing_Page_JobAids,
  (Landing_Page_JobAids) => {
    return Landing_Page_JobAids;
  }
);
const getMiddlecardsdata = createSelector(
  (state) => state.staticReport.middleCardTilesData,
  (middleCardTilesData) => {
    return middleCardTilesData;
  }
);

const mapStateToProps = (state) => ({
  commonData: state.commonData,
  videoPlayerModal: state.param.videoPlayerModal,
  middleCardTilesData: getMiddlecardsdata(state),
  userCredentialsData: state.staticReport.userCredentialsData,
  usefulLinksData: getUserData(state),
  notificationCardData: state.staticReport.notificationCardData,
  selectionTileData: state.staticReport.selectionTileData,
  landingPageTopTileData: state.staticReport.landingPageTopTileData,
  Landing_Page_WhatsNew: landingWhatsnew(state),
  Landing_Page_JobAids: landingTutorial(state),
  Landing_Page_FAQ: landingFaq(state),
  allNotificationsData: state.staticReport.allNotificationsData,
  dashboardInfoData: state.staticReport.dashboardInfoData,
  isLoading: state.reports.isLoading,
  user: state.reports.user,
});

export default connect(mapStateToProps, {
  setStaticValueFilter,
  setStaticData,
  setcommonDataAdd,
  setUserData,
  setCurrentReports,
  setParam,
  setLoading,
})(AppComponent);
