import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from 'moment';
import { Modal, Button, Form, Input, Select, Radio, Slider, DatePicker, TimePicker, message, Upload,Popconfirm } from 'antd';
import ViewBoxComponent from "@innive/view-box-component";
import { addStaticData, setStaticData } from "../../../store/actions/staticReport";
import Table from "../../../components/antd-editable-table";
import { setParam } from "../../../store/actions/param";
import { setCurrentReports,setReportDatatablecount} from "../../../store/actions/report";
import "./style.css";
const { Option } = Select;
let BASE_URL
if(window.location.host.includes('localhost')){
  BASE_URL  = "http://localhost:8080/dataService/data"
}else{
  BASE_URL = window.location.origin+'/datarouter/data'
}


const WrappedAddNotificationForm = Form.create({ name: "add_notification" })(
  TableModal
);

const ignoreColumns = ["KEY", "ID","THUMBNAIL_IMAGE","ATTACHMENT_TYPE","UPDATED_BY","CREATED_BY","CREATED_DATE","TYPE"];

function TableModal(props) {
  // debugger
  useEffect(() => {
    // setCurrentReports(reports)
    setStaticData(["whatsnew"]);
    // setUserData()
  }, [props.whatsnewData]);
  const { setParam, setCurrentReports, userRole, allowedRoles, landingPageSelectedReport,user,setReportDatatablecount } = props;
  const [showAddModal, setShowAddModal] = useState(false);
  const [editRow, setEditRow] = useState(null);
  const [fileList, setFileList] = useState({});
  const [fileUrl, setFileUrl] = useState({});
  const [uploading, setUploading] = useState(false);
  const [dynamicItem, setDynamicItem] = useState();
  const [popOverVisible, setPopOverVisible] = useState(false);

  useEffect(() => {

    if (editRow && props.form) {
      
  
      if(editRow.TARGET_RESOLUTION_DATE) {
        const momentDate = moment(editRow.TARGET_RESOLUTION_DATE,'YYYY-MM-DD HH:mm:ss.SSS');
        editRow.TARGET_RESOLUTION_DATE = momentDate;
      }

      
      
      props.form.setFieldsValue(editRow)
    } else if (props.form && !editRow) {
      props.form.resetFields();
    }

  }, [editRow])

  const handlePopOverVisibleChange = () => {
    if(fileList && Object.keys(fileList).length) {
      setPopOverVisible(true);
    } else {
      handleFormSubmit()
    }

  }

  const handleAttachmentType = (type) => {
    setDynamicItem(type);
  }

  const handleAddNotification = () => {
    setEditRow(null);
    setShowAddModal(true);
  }
  const onAddCancel = () => {
    setDynamicItem(null);
    setShowAddModal(false);
  }
  const onAddOk = () => {
    setDynamicItem(null);
    setShowAddModal(false);
  }
  const handleEditRow = (row) => {
    if(row.ATTACHMENT_TYPE) {
      setFileUrl({URL: row.URL})
      setDynamicItem(row.ATTACHMENT_TYPE)
    }

    if(row.TYPE) {
      setFileUrl({URL: row.URL})
      setDynamicItem(row.TYPE)
    }
    setEditRow(row);
    setShowAddModal(true);
  }
  const handleDeleteRow = (row) => {
    const dateNow = new Date();
    // delete row.CREATED_DATE;
    // delete row.UPDATED_DATE;
    // row.UPDATED_DATE = moment(dateNow).format('YYYY-MM-DD HH:mm:ss');
    // row.STATUS = "Closed";
    const transformedObject = {
     
      columns: Object.entries(row).map(([columnName, columnValue]) => ({
        columnName: columnName,
        columnValue: columnValue
      }))
    };
    // const transformedArray = [row].map(obj => ({
    //   columns: Object.entries(obj).map(([columnName, columnValue]) => ({
    //     columnName,
    //     columnValue: columnValue !== undefined ? [columnValue] : ['']
    //   }))
    // }));
    fetch(`${BASE_URL}/delete`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "tableName": props.reportName, "columns": transformedObject.columns }),
        // body: JSON.stringify({ "reportName": props.reportName, "data": row }),
      })
      .then((res) => res.json())
      .then((data) => {
      })
      .catch(() => {
        message.error("Error: https://localhost:8443/data/search/update");
      })
      .finally(() => {
        setShowAddModal(false);
        setCurrentReports([{reportName: props.reportName},{reportName: props.reportName+'_Preview'}])
      });
    
  }
  const handleUpload = (field) => {

    const formData = new FormData();
    let fileName = "";


    if(fileList && Object.keys(fileList).length) {

      fileList[field]?.forEach((file) => {
        fileName = file.name
        formData.append('files', file, file.name);
      });
      setUploading(true);
  
      fetch(`${BASE_URL}/upload`, {
        method: 'POST',
        body: formData,
      })
        .then((res) => res.json()).then((dataRecived)=>{
          if (dataRecived.status && dataRecived.status === 500) {
            message.error('Upload size exceeded,please upload file below 5MB');
            return; 
          }else{
          const URLS = dataRecived;
          setFileList((prev)=> {
            delete prev[field]; 
            return prev
          });
          setFileUrl({...fileUrl,  [field]:URLS[0]});
          message.success(field+' Uploaded Successfully!');
          }
         } )
        .catch(() => {
          message.error('Upload Failed :( Try Again.');
        })
        .finally(() => {
          setUploading(false);
        });

    } else {
      message.error('Select a file to upload');
    }

    
  }

  const handleUploadRow = (row) => {
    message.loading({
      content: 'Uploading...',
      key: row.Short_Description,
    });
    setTimeout(() => {
      message.success({
        content: 'Uploaded!',
        key: row.Short_Description,
        duration: 2,
      });
    }, 1000);
  }

  const insertRow = (values) => {
   
    setReportDatatablecount([{tablecount: props.reportName+'_row_count'}])
    .then((data)=>{
      // debugger
      values.ID=  (data.tableDatacount[props.reportName+'_row_count'][0]?.COUNT||0)+1
    // const [{ "COUNT": countValue }] = table_count;
    //   values.ID=countValue

      const transformedArray = [values].map(obj => ({
        columns: Object.entries(obj).map(([columnName, columnValue]) => ({
          columnName,
          columnValue: columnValue !== undefined ? columnValue : ''
        }))
      }));
     let req= transformedArray.length&&transformedArray[0].columns
    fetch(`${BASE_URL}/insert`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "tableName": props.reportName, "columns": req }),

        // body: JSON.stringify({ "reportName": props.reportName, "data": values,autoID:true }),
      })
      .then((res) => res.json())
      .then((data) => {
  
      })
      .catch(() => {
        message.error("Error: /data/search/insert");
      })
      .finally(() => {
        setShowAddModal(false);
        setCurrentReports([{reportName: props.reportName},{reportName: props.reportName+'_Preview'}])
        
      });
    })
  }

  const updateRow = (values) => {
    const transformedArray = [values].map(obj => ({
      columns: Object.entries(obj).map(([columnName, columnValue]) => ({
        columnName,
        columnValue: columnValue !== undefined ? columnValue : ''
      }))
    }));
    // const transformedArray = [values].map(obj => ({
    //   columns: Object.entries(obj).map(([columnName, columnValue]) => ({
    //     columnName,
    //     columnValue
    //   }))
    // }));
    // const transformedArray = Object.entries(values).map(([columnName, columnValue]) => ({
    //   columnName,
    //   columnValue
    // }));
    
  // debugger
    let filter_arr = transformedArray.length && transformedArray[0].columns.filter(e=>e.columnName == "ID");
    let columns_arr = transformedArray.length && transformedArray[0].columns;
    fetch(`${BASE_URL}/update`,
      {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ "tableName": props.reportName, "columns": columns_arr , "filters": filter_arr}),
      })
      .then((res) => res.json())
      .then((data) => {
        
      })
      .catch(() => {
    
        message.error("Error: /data/search/update");
      })
      .finally(() => {
        setShowAddModal(false);
        setCurrentReports([{reportName:props.reportName},{reportName: props.reportName+'_Preview'}])
      });
  }

  const handleFormSubmit = (e) => {
    
    if(e) {
      e.preventDefault();
    }
    
    props.form.validateFields((err, values) => {
      // debugger
      if (!err) {
        
        console.log('Received values of form: ', values);
        const dateNow = new Date();
        if(fileList && Object.keys(fileList).length) {

          alert('Upload Files')

        } else {
          

          if(editRow) {
            // delete editRow.CREATED_DATE;
            // delete editRow.UPDATED_DATE;
            editRow.UPDATED_DATE = moment(dateNow).format('YYYY-MM-DD HH:mm:ss');
            editRow.UPDATED_BY = user && user.user_full_name && user.user_full_name.length && user.user_full_name[0].FULL_NAME;
            let newValues = {...editRow, ...values};
            newValues.THUMBNAIL_IMAGE = fileUrl["THUMBNAIL_IMAGE"] || values.THUMBNAIL_IMAGE;
            if(fileUrl["URL"] || values.URL)
              newValues.URL = values.URL ? values.URL : (fileUrl["URL"] ? fileUrl["URL"] : "");
            updateRow(newValues)
          } else {
            values.STATUS = values.STATUS ? values.STATUS:  "Opened";
            values.CREATED_BY = user && user.user_full_name && user.user_full_name.length && user.user_full_name[0].FULL_NAME;
            values.CREATED_DATE = moment(dateNow).format('YYYY-MM-DD HH:mm:ss')
            values.UPDATED_DATE = moment(dateNow).format('YYYY-MM-DD HH:mm:ss')
            values.THUMBNAIL_IMAGE = fileUrl["THUMBNAIL_IMAGE"] || "";
            if(fileUrl["URL"] || values.URL)
                values.URL = values.URL ? values.URL : (fileUrl["URL"] ? fileUrl["URL"] : "");
            insertRow(values);
          }

          props.form.resetFields();
        setDynamicItem(null);
        setFileUrl({});
        setFileList({});
        }
        
        
      }

    });
  }

  const handleTrainingUrlClick = (url) => {
    
    setParam({videoPlayerModal: true, videoPlayerModalUrl: url})
  }

  let { title, visible, onOk, onCancel, data = [] ,whatsnewData} = props;
  if(data && !data.length){
    data = whatsnewData || []
  }
 // const columns = Object.keys(data && data.length && data[0]).filter(key=> { if(title=="Whats New" || title=="System Notification"  ) {return !ignoreColumns.includes(key) } else {return !ignoreColumns.includes(key) && key!="STATUS"   } }  ).map(key=> {
  const columns = [...new Set(data.reduce((keys, obj) => [...keys, ...Object.keys(obj)], []))].filter(key=> { if(title=="Whats New" || title=="System Notification"  ) {return !ignoreColumns.includes(key) } else {return !ignoreColumns.includes(key) && key!="STATUS" && key!="RELATED_URL"   } }  ).sort((a,b)=>{
    if(title=="Whats New" || title == "FAQs" || title == "Tutorial of The Week"){
      let desiredOrder =[]
      if(title=="Whats New"){
        desiredOrder= ['TITLE', 'SHORT_DESCRIPTION', 'LONG_DESCRIPTION', 'CATEGORY', 'URL', 'UPDATED_DATE', 'STATUS'];
      }else if(title == "FAQs"){
        desiredOrder=['QUESTIONS', 'ANSWERS','RELATED_URL','CATEGORY',  'UPDATED_DATE']
      }else if(title == "Tutorial of The Week"){
        desiredOrder= ['TITLE', 'SHORT_DESCRIPTION', 'LONG_DESCRIPTION', 'CATEGORY', 'URL', 'UPDATED_DATE'];
      }
      const indexA = desiredOrder.indexOf(a);
      const indexB = desiredOrder.indexOf(b);
      if (indexA !== -1 && indexB !== -1) { return indexA - indexB; }
      if (indexA !== -1) { return -1; }
      if (indexB !== -1) {  return 1; }
    }else{
      return
    }
   }).map(key=> {
 let titleStr = key.toLowerCase().split(`_`).map(str=> str.charAt(0).toUpperCase()+str.slice(1)  ).join(` `);
    let customRender;
    if(key.includes('URL')) {
      if(title=="Training Videos") {
        customRender = function(value,record) {return <span style={{color:'#1890ff'}} className="is-cursor-pointer" onClick={()=> {handleTrainingUrlClick(value)}}  >{value}</span>}
      } else {
        customRender = function(value,record) {return <a target="_blank" href={value}>{value}</a>}
      }
      
    }
    let columnObj={
      dataIndex: key,
      key: key,
      title: titleStr,
      customRender
    };
    return columnObj;

  })
  const { getFieldDecorator } = props.form;

  const renderAddModalContent = () => {
    // debugger
    return props.formFields && props.formFields.map((row, i) => {
      if (row.type == "input") {

        if(row.dynamic) {
          if(dynamicItem && dynamicItem == row.label) {
            return (
              <Form.Item label={row.label} >
                {getFieldDecorator(row.dataIndex, { rules: [] })(<Input placeholder={row.placeholder} />)}
              </Form.Item>
            )
          } else {
            return null
          }
        } else {
          return (
            <Form.Item label={row.label} >
              {getFieldDecorator(row.dataIndex, { rules: [] })(<Input placeholder={row.placeholder} />)}
            </Form.Item>
          )
        }

        
      } else if (row.type == "select") {
        return (
          <Form.Item label={row.label}>
            {getFieldDecorator(row.dataIndex, { rules: [] })(<Select onChange={handleAttachmentType} placeholder={row.placeholder}>{row.options && row.options.length && row.options.map(option => {
              return <Option value={option.value}>{option.label}</Option>
            })}</Select>)}
            
          </Form.Item>
        )
      } else if (row.type == "slider") {
        return (
          <Form.Item label={row.label}>
            {getFieldDecorator(row.dataIndex, { rules: [] })(<Slider step={null} tooltipVisible={false}
              marks={row.options || {}}
            />)}

          </Form.Item>
        )
      } else if (row.type == "radio") {
        return (
          <Form.Item label={row.label}>
            {getFieldDecorator(row.dataIndex, { rules: [] })(<Radio.Group>
              {row.options && row.options.length && row.options.map(option => {
                return (
                  <Radio value={option.value}>{option.label}</Radio>
                )
              })}
            </Radio.Group>)}
          </Form.Item>
        )
      } else if (row.type == "calendar") {
        return (

          <Form.Item label={row.label}>
            {getFieldDecorator(row.dataIndex, { rules: [] })(<DatePicker showTime use12Hours format="YYYY/MM/DD hh:mm A" />)}
          </Form.Item>
        )
      } else if (row.type == "file") {

        if(row.dynamic) {

          if(dynamicItem && dynamicItem == row.label) {
            return (

              <Form.Item extra={fileUrl && fileUrl.URL} label={row.label}>
              <span className="is-display-flex is-flex-column is-justify-content-center is-align-items-center">
    
                <Upload  key={row.dataIndex} fileList={fileList[row.dataIndex]} onRemove={(file) => {
                  const index = fileList[row.dataIndex]?.indexOf(file);
                  const newFileList = fileList[row.dataIndex]?.slice();
                  newFileList?.splice(index, 1);
                  setFileList({...fileList,[row.dataIndex]:newFileList});
                }}
                  beforeUpload={(file) => {
                    const fList = fileList[row.dataIndex] || [];
                    fList.push(file)
                    setFileList({...fileList,[row.dataIndex]:fList});
                    return false;
                  }} >
                  <Button icon="upload" >Select File</Button>
                </Upload>
                <Button
                  type="primary"
                  onClick={()=>handleUpload(row.dataIndex)}
                  disabled={fileList[row.dataIndex]?.length === 0}
                  loading={uploading}
                >
                  {uploading ? 'Uploading' : 'Upload'}
                </Button>
    
              </span>
              </Form.Item>
    
            )
          } else {
            return null
          }
        } else {

          return (

            <Form.Item label={row.label}>
            <span className="is-display-flex is-flex-column is-justify-content-center is-align-items-center">
            
              <Upload key={row.dataIndex} fileList={fileList[row.dataIndex]} onRemove={(file) => {
                const index = fileList[row.dataIndex]?.indexOf(file);
                const newFileList = fileList[row.dataIndex]?.slice();
                newFileList?.splice(index, 1);
                setFileList({...fileList,[row.dataIndex]:newFileList});
              }}
                beforeUpload={(file) => {
                  const fList = fileList[row.dataIndex] || [];
                  fList.push(file)
                  setFileList({...fileList,[row.dataIndex]:fList});
                  return false;
                }} >
                <Button icon="upload" >Select File</Button>
              </Upload>
              <Button
                type="primary"
                style={{background :"#008cff"}}
                onClick={()=>handleUpload(row.dataIndex)}
                disabled={fileList[row.dataIndex]?.length === 0}
                loading={uploading}
              >
                {uploading ? 'Uploading' : 'Upload'}
              </Button>
              {row.dataIndex=='THUMBNAIL_IMAGE' && fileUrl.THUMBNAIL_IMAGE && <div><span></span><img width='35px' height='35px' src={fileUrl.THUMBNAIL_IMAGE}></img></div>}
            </span>
            </Form.Item>
  
          )

        }
        
      }
    })
  }

  const handleOKClick = () => {
    setParam({landingPageTableModal:false})
    setCurrentReports([{reportName: landingPageSelectedReport}])
  }

  const handleCancelClick = () => {
    setParam({landingPageTableModal:false})
    setCurrentReports([{reportName: landingPageSelectedReport}])
  }
  const isEditable = allowedRoles.includes(userRole?.toUpperCase())
  return (
    <>
      <Modal
        className='table-modal'
        footer=''
        width={"90%"}
        closable={true}
        title={<div className="is-font-size-lg is-modal-title-color">{title}</div>}
        visible={visible}
        onOk={onOk || handleOKClick}
        onCancel={onCancel  || handleCancelClick}
      >
        <ViewBoxComponent columns={[{ size: 12 }, { size: 12 }]}>

          <div className="is-text-right" >
          {isEditable && <Button style={{background : "#008cff"}} className="is-mar-x-1" type="primary" onClick={handleAddNotification}>Add</Button>}
            {/* {allowedRoles.includes(userRole?.toUpperCase())?
            
            :""} */}
          </div>

          <div>
            <Table pagination={{pageSize:10}} rowKey="Short_Description" columns={columns} data={data} selectionEnabled={null} title="" editable={isEditable ? true:false} editRow={handleEditRow} deleteRow={handleDeleteRow} uploadRow={handleUploadRow} />
          </div>

        </ViewBoxComponent>
      </Modal>


      <Modal
        className='add-modal'
        footer=''
        width={"50%"}
        closable={true}
        destroyOnClose={true}
        title={title}
        visible={showAddModal}
        onOk={onAddOk}
        onCancel={()=>{
          setFileList({});
          onAddCancel();
        }}
      >
        <ViewBoxComponent columns={[{ size: 12 }]}>

          <Form onSubmit={handleFormSubmit}>

            {renderAddModalContent()}



            <Form.Item className="is-text-right" >

            <Popconfirm
        title="Do you want to upload selected file(s)?"
        visible={popOverVisible}
        onVisibleChange={handlePopOverVisibleChange}
        onConfirm={()=> {if(fileList) { Object.keys(fileList).map(field=> {handleUpload(field)}); setPopOverVisible(false) }  }}
        onCancel={()=> {setFileList({});setPopOverVisible(false);  }}
        okText="Yes"
        cancelText="No"
      >
        <Button htmlType="submit" type="primary">Save</Button>

      </Popconfirm>
              
            </Form.Item>
          </Form>


        </ViewBoxComponent>
      </Modal>
    </>
  );
}
const reportNameFunction=(state)=>{
  // debugger
 return state.param.usefulLinksModal ? "Landing_Page_Application_Links" : (state.param.showallReportMappings[state.param.landingPageSelectedReport] || state.param.landingPageSelectedReport)
}
const dataNameFunction=(state)=>{
  // debugger
 return  state.param.usefulLinksModal ? state.reports["Landing_Page_Application_Links"] : state.reports[(state.param.showallReportMappings[state.param.landingPageSelectedReport] || state.param.landingPageSelectedReport)]
}

const formdata=(state)=>{
  // debugger
  return state.param.usefulLinksModal ? state.reports.formFields["Useful Links"] : state.reports.formFields[state.param.landingPageSelectedTab]
}
const mapStateToProps = (state, ownProps) => ({
  visible:state.param.landingPageTableModal,
  reportName: reportNameFunction(state),
  // reportName: state.param.usefulLinksModal ? "Landing_Page_Application_Links" : (state.param.showallReportMappings[state.param.landingPageSelectedReport] || state.param.landingPageSelectedReport),
  formFields: formdata(state),
  // formFields: state.param.usefulLinksModal ? state.reports.formFields["Useful Links"] : state.reports.formFields[state.param.landingPageSelectedReport],
  data:dataNameFunction(state) ,
  // data: state.param.usefulLinksModal ? state.reports["Landing_Page_Application_Links"] :  state.reports[(state.param.showallReportMappings[state.param.landingPageSelectedReport] || state.param.landingPageSelectedReport)],
  title: state.param.usefulLinksModal ? "Useful Links" :  state.param.landingPageSelectedTab,
  userRole:state.param.userRole,
  
  user:state.reports.user,
  allowedRoles: state.param.allowedRoles,
  landingPageSelectedReport : state.param.landingPageSelectedReport,
  whatsnewData:state.staticReport.whatsnewData,

});

export default connect(mapStateToProps, { addStaticData,setParam,setCurrentReports,setReportDatatablecount})(WrappedAddNotificationForm);
