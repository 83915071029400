let params = [];

export const extractParams = (input) => {
    params = [];
    //const paramsList = (input || '').match(/\[(.*?)\]\((.*?)\}\}\)\"/g);

    return (input || '').replace(/\(&{(.*?)}&\)"/g, (paramsList) => {
        return paramsList.replace(/&{(.*?)}&/g, (currParam) => {
            const param = currParam.substr(2, currParam.length-4);
            try {
                params.push(JSON.parse(param)); 
            } catch(e) {
                params.push(undefined);
            }
            return `semantic_params[${params.length-1}]`;
        });
    });
}

export const getParams = () => {
    return params;
}