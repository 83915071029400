import React from 'react';
import './style.css';
import FooterLogo from '../../../assest/innive_footer_logo.png';

const Footer = ({data}) => {
  const footerText = (data && data[0]?.FOOTER) ?  data[0]?.FOOTER : "";

  return (
    <div className="page-footer-container is-pad-1">
      <div className="is-width-100 is-display-flex is-justify-content-between is-align-items-center">
        <div className="is-display-flex is-align-items-center footer_line">
          <img height="50px" style={{ flex: 0 }} className="is-mar-left-5" src={FooterLogo} alt="Footer Logo" />
          <span className="is-text-center" style={{ flex: 1 }}>
            {/* <div className="is-font-size-md is-text-bold">Executive Dashboard Home Page</div>
            <div className="is-font-size-sm">LOS ANGELES UNIFIED SCHOOL DISTRICT</div>*/}
            <div className="is-font-size-xs">
            {footerText.length ? footerText : '© Disclaimer: This information is the property of Innive and is intended solely for use by individuals expressly authorized by the organization. This information is not to be reproduced in any form, viewed, distributed, or disclosed in any way to or by unauthorized individuals. The sharing of this information is subject to conditions set forth in applicable laws, regulations, and policies regarding privacy and confidentiality.'}  
            </div>
          </span>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default Footer;