// const controlPrompts = {
//     dashboard_name: ["dashboardInfo"]
// }

const initialState = {
    notificationCard : [{name: "Lex Murphy",message: "has requested access to", access: "UNIX directory",timeStrap: "Today at 9:45 AM"},
    {name: "Steven Jones",message: "requested access to", access: "UNIX directory",timeStrap: "Today at 10:00 AM"}
  ],
  whatsnew: [
    // {
    //     "ID": 12,
    //     "TITLE": "Alert Test 2",
    //     "SHORT_DESCRIPTION": null,
    //     "LONG_DESCRIPTION": null,
    //     "CATEGORY": null,
    //     "ATTACHMENT_TYPE": null,
    //     "URL": null,
    //     "THUMBNAIL_IMAGE": "",
    //     "CREATED_DATE": "2022-08-08 13:11:05.000",
    //     "CREATED_BY": "RUDNICK,MOLLIE",
    //     "UPDATED_BY": null,
    //     "STATUS": "Opened",
    //     "UPDATED_DATE": null
    // },
    // {
    //     "ID": 11,
    //     "TITLE": "Alert Test 1",
    //     "SHORT_DESCRIPTION": "hig",
    //     "LONG_DESCRIPTION": null,
    //     "CATEGORY": null,
    //     "ATTACHMENT_TYPE": null,
    //     "URL": null,
    //     "THUMBNAIL_IMAGE": "",
    //     "CREATED_DATE": "2022-08-08 13:10:38.000",
    //     "CREATED_BY": "RUDNICK,MOLLIE",
    //     "UPDATED_BY": "RUDNICK,MOLLIE",
    //     "STATUS": "Opened",
    //     "UPDATED_DATE": "2022-08-17 09:17:04.000"
    // },
    // {
    //     "ID": 14,
    //     "TITLE": "8 Aug Test",
    //     "SHORT_DESCRIPTION": "8 Aug Test",
    //     "LONG_DESCRIPTION": "8 Aug Test8 Aug Test8 Aug Test8 Aug Test8 Aug Test",
    //     "CATEGORY": "8 Aug Test",
    //     "ATTACHMENT_TYPE": "PDF",
    //     "URL": "https://storage.googleapis.com/i360controller/Executive Dashboard Phase IV - CoS Profile Achievement.pdf",
    //     "THUMBNAIL_IMAGE": "https://storage.googleapis.com/i360controller/IMG-4453.jpg",
    //     "CREATED_DATE": "2022-08-08 14:22:27.000",
    //     "CREATED_BY": "KUMARESHAN,SARAVANAN",
    //     "UPDATED_BY": "KUMARESHAN,SARAVANAN",
    //     "STATUS": "Opened",
    //     "UPDATED_DATE": "2022-08-08 14:25:22.000"
    // },
    // {
    //     "ID": 10,
    //     "TITLE": "Alert test",
    //     "SHORT_DESCRIPTION": null,
    //     "LONG_DESCRIPTION": null,
    //     "CATEGORY": null,
    //     "ATTACHMENT_TYPE": null,
    //     "URL": null,
    //     "THUMBNAIL_IMAGE": "https://storage.googleapis.com/i360controller/Pdf-2127829.png",
    //     "CREATED_DATE": "2022-08-08 13:08:00.000",
    //     "CREATED_BY": "RUDNICK,MOLLIE",
    //     "UPDATED_BY": "RUDNICK,MOLLIE",
    //     "STATUS": "Opened",
    //     "UPDATED_DATE": "2022-08-08 13:11:16.000"
    // },
    // {
    //     "ID": 2,
    //     "TITLE": "Localhost Insert Test",
    //     "SHORT_DESCRIPTION": "Localhost Insert Test",
    //     "LONG_DESCRIPTION": "Localhost Insert Test - PDF to URL Test (Working) - Thumbnail Edit Test (Preview Data not updating always after save) - Updated Date Test (Working)",
    //     "CATEGORY": "Test",
    //     "ATTACHMENT_TYPE": "URL",
    //     "URL": "yahoomail.com",
    //     "THUMBNAIL_IMAGE": "https://storage.googleapis.com/i360controller/R.png",
    //     "CREATED_DATE": "2022-08-04 12:20:48.000",
    //     "CREATED_BY": "RUDNICK,MOLLIE",
    //     "UPDATED_BY": "RUDNICK,MOLLIE",
    //     "STATUS": "Opened",
    //     "UPDATED_DATE": "2022-08-05 19:04:40.000"
    // },
    // {
    //     "ID": 7,
    //     "TITLE": "8/4 Testing",
    //     "SHORT_DESCRIPTION": "8/4 Testing",
    //     "LONG_DESCRIPTION": "8/4 Testing",
    //     "CATEGORY": "8/4 Testing",
    //     "ATTACHMENT_TYPE": "PDF",
    //     "URL": "https://storage.googleapis.com/i360controller/sample.pdf",
    //     "THUMBNAIL_IMAGE": "https://storage.googleapis.com/i360controller/COS_3542.png",
    //     "CREATED_DATE": "2022-08-04 15:16:53.000",
    //     "CREATED_BY": "RUDNICK,MOLLIE",
    //     "UPDATED_BY": "RUDNICK,MOLLIE",
    //     "STATUS": "Opened",
    //     "UPDATED_DATE": "2022-08-05 06:25:27.000"
    // },
    // {
    //     "ID": 4,
    //     "TITLE": "8/4 Testing editing",
    //     "SHORT_DESCRIPTION": "8/4 Testing",
    //     "LONG_DESCRIPTION": "8/4 Testing",
    //     "CATEGORY": "8/4 Testing",
    //     "ATTACHMENT_TYPE": "URL",
    //     "URL": "https://rptsvr1.tea.texas.gov/perfreport/account/2018/download.html",
    //     "THUMBNAIL_IMAGE": "https://storage.googleapis.com/i360controller/COS_3505.png",
    //     "CREATED_DATE": "2022-08-04 14:30:12.000",
    //     "CREATED_BY": "RUDNICK,MOLLIE",
    //     "UPDATED_BY": "RUDNICK,MOLLIE",
    //     "STATUS": "Opened",
    //     "UPDATED_DATE": "2022-08-05 06:24:27.000"
    // },
    // {
    //     "ID": 6,
    //     "TITLE": "8/4 test one",
    //     "SHORT_DESCRIPTION": "This is a short desc",
    //     "LONG_DESCRIPTION": "This is a long desc, longer than the short desc",
    //     "CATEGORY": "Test",
    //     "ATTACHMENT_TYPE": "URL",
    //     "URL": "https://lausd.executivedashboard-dev.innive.io/summary_page.html",
    //     "THUMBNAIL_IMAGE": "https://storage.googleapis.com/i360controller/Untitled.png",
    //     "CREATED_DATE": "2022-08-04 11:52:12.000",
    //     "CREATED_BY": "RAVICHANDRAN,VIVEK",
    //     "UPDATED_BY": "RUDNICK,MOLLIE",
    //     "STATUS": "Opened",
    //     "UPDATED_DATE": "2022-08-05 04:46:13.000"
    // },
    // {
    //     "ID": 3,
    //     "TITLE": "Test",
    //     "SHORT_DESCRIPTION": null,
    //     "LONG_DESCRIPTION": null,
    //     "CATEGORY": null,
    //     "ATTACHMENT_TYPE": "PDF",
    //     "URL": "https://storage.googleapis.com/i360controller/sample.pdf",
    //     "THUMBNAIL_IMAGE": "",
    //     "CREATED_DATE": "2022-08-04 09:55:16.000",
    //     "CREATED_BY": "RUDNICK,MOLLIE",
    //     "UPDATED_BY": "RUDNICK,MOLLIE",
    //     "STATUS": "Opened",
    //     "UPDATED_DATE": "2022-08-05 04:45:49.000"
    // },
    // {
    //     "ID": 26,
    //     "TITLE": "Deployment Env Insert Test",
    //     "SHORT_DESCRIPTION": "Deployment Env Insert Test",
    //     "LONG_DESCRIPTION": "PDF to URL test (Not Working) - Thumbnail Edit Test (Working) - Update Time Test (Working)",
    //     "CATEGORY": "Test",
    //     "ATTACHMENT_TYPE": "PDF",
    //     "URL": "https://storage.googleapis.com/i360controller/sample.pdf",
    //     "THUMBNAIL_IMAGE": "https://storage.googleapis.com/i360controller/Pdf-2127829.png",
    //     "CREATED_DATE": "2022-08-04 10:32:47.000",
    //     "CREATED_BY": "RUDNICK,MOLLIE",
    //     "UPDATED_BY": "RUDNICK,MOLLIE",
    //     "STATUS": "Opened",
    //     "UPDATED_DATE": "2022-08-05 04:45:19.000"
    // }
],
  userCredentials :window.location.origin.includes("knox") ? [{
    ROLE: "Counselor",
    USERNAME: "S.MERRITTMAY",
    EMPLOYEE_ID: "01171409",
    CAMPUS_CODE : "0066"
  },
  {
    ROLE: "Principal",
    USERNAME: "C.GOROCICA",
    EMPLOYEE_ID: "01174234",
    CAMPUS_CODE : "0115"
  }] : [
    {
      ROLE: "Teacher",
      USERNAME: "PATRICIA.VALLE",
      EMPLOYEE_ID: "01067788"
    },
    {
      ROLE: "Counselor",
      USERNAME: "S.MERRITTMAY",
      EMPLOYEE_ID: "01171409"
    },
    {
      ROLE: "Principal",
      USERNAME: "C.GOROCICA",
      EMPLOYEE_ID: "01174234"
    },
    {
      ROLE: "Community of School",
      USERNAME: "TRAVIS.BRANDY",
      EMPLOYEE_ID: "00804636"
    },
    {
      ROLE: "DEVELOPER",
      USERNAME: "YUE.ZHANG",
      EMPLOYEE_ID: "01101626"
    },
    {
      ROLE: "CENTRAL_OFFICE",
      USERNAME: "LAURA.VILLALOBOS",
      EMPLOYEE_ID: "00639720"
    },
    {
        ROLE: "DISTRICT_ADMIN",
        USERNAME: "LORRAINE.THOMPSON@GREECECSD.ORG", 
        EMPLOYEE_ID: "99999990"
    }
  ],
  usefulLinks: [
    {   
        "ID": 11,
        "APPLICATION_NAME": "At a Glance",
        "APPLICATION_DESCRIPTION": "Sample description",
        "APPLICATION_URL": "/teacher_dashboar.html",
        "THUMBNAIL_IMAGE": "https://achieve.lausd.net/cms/lib/CA01000043/Centricity/Template/GlobalAssets/images///Logo/LAUSD_seal_oct2022v1.png",
        "DISPLAY_ORDER": "1",
        "CREATED_DATE": "2022-08-04 12:29:31.000",
        "CREATED_BY": "KUMARESHAN,SARAVANAN",
        "UPDATED_BY": "RUDNICK,MOLLIE",
        "STATUS": "Opened",
        "UPDATED_DATE": "2022-08-05 12:59:34.000"
    },
    {
        "ID": 12,
        "APPLICATION_NAME": "Course Schedule",
        "APPLICATION_DESCRIPTION": "Sample description",
        "APPLICATION_URL": "/at_a_glance.html",
        "THUMBNAIL_IMAGE": "https://images.freeimages.com/images/large-previews/3b2/prague-conference-center-1056491.jpg",
        "DISPLAY_ORDER": "2",
        "CREATED_DATE": "2022-08-04 12:32:37.000",
        "CREATED_BY": "KUMARESHAN,SARAVANAN",
        "UPDATED_BY": "RUDNICK,MOLLIE",
        "STATUS": "Opened",
        "UPDATED_DATE": "2022-08-05 13:00:47.000"
    },
    {
        "ID": 13,
        "APPLICATION_NAME": "Classroom View",
        "APPLICATION_DESCRIPTION": "My integrated Student Information System, MiSiS is a modernized all-in-one student information solution.",
        "APPLICATION_URL": null,
        "THUMBNAIL_IMAGE": "https://images.freeimages.com/images/large-previews/3b2/prague-conference-center-1056491.jpg",
        "DISPLAY_ORDER": "3",
        "CREATED_DATE": "2022-08-04 12:40:11.000",
        "CREATED_BY": "KUMARESHAN,SARAVANAN",
        "UPDATED_BY": "RUDNICK,MOLLIE",
        "STATUS": "Opened",
        "UPDATED_DATE": null
    },
    {
        "ID": 14,
        "APPLICATION_NAME": "Student Monitoring",
        "APPLICATION_DESCRIPTION": "LAUSD WebsiteLAUSD WebsiteLAUSD Website",
        "APPLICATION_URL": "https://achieve.lausd.net/",
        "THUMBNAIL_IMAGE": "https://images.freeimages.com/images/large-previews/3b2/prague-conference-center-1056491.jpg",
        "DISPLAY_ORDER": "4",
        "CREATED_DATE": "2022-08-05 12:57:13.000",
        "CREATED_BY": "RUDNICK,MOLLIE",
        "UPDATED_BY": "KUMARESHAN,SARAVANAN",
        "STATUS": "Opened",
        "UPDATED_DATE": "2022-08-08 14:38:17.000"
    },{
      "ID": 15,
      "APPLICATION_NAME": "Assessment Analytics",
      "APPLICATION_DESCRIPTION": "Sample description",
      "APPLICATION_URL": "/teacher_dashboar.html",
      "THUMBNAIL_IMAGE": "https://images.freeimages.com/images/large-previews/3b2/prague-conference-center-1056491.jpg",
      "DISPLAY_ORDER": "1",
      "CREATED_DATE": "2022-08-04 12:29:31.000",
      "CREATED_BY": "KUMARESHAN,SARAVANAN",
      "UPDATED_BY": "RUDNICK,MOLLIE",
      "STATUS": "Opened",
      "UPDATED_DATE": "2022-08-05 12:59:34.000"
  },
  {
      "ID": 16,
      "APPLICATION_NAME": "Student Search",
      "APPLICATION_DESCRIPTION": "Sample description",
      "APPLICATION_URL": "/at_a_glance.html",
      "THUMBNAIL_IMAGE": "https://images.freeimages.com/images/large-previews/3b2/prague-conference-center-1056491.jpg",
      "DISPLAY_ORDER": "2",
      "CREATED_DATE": "2022-08-04 12:32:37.000",
      "CREATED_BY": "KUMARESHAN,SARAVANAN",
      "UPDATED_BY": "RUDNICK,MOLLIE",
      "STATUS": "Opened",
      "UPDATED_DATE": "2022-08-05 13:00:47.000"
  },
  {
      "ID": 17,
      "APPLICATION_NAME": "Whole Child View",
      "APPLICATION_DESCRIPTION": "My integrated Student Information System, MiSiS is a modernized all-in-one student information solution.",
      "APPLICATION_URL": null,
      "THUMBNAIL_IMAGE": "https://storage.googleapis.com/i360controller/COS_3515.png",
      "DISPLAY_ORDER": "3",
      "CREATED_DATE": "2022-08-04 12:40:11.000",
      "CREATED_BY": "KUMARESHAN,SARAVANAN",
      "UPDATED_BY": "RUDNICK,MOLLIE",
      "STATUS": "Opened",
      "UPDATED_DATE": null
  },
  // {
  //     "ID": 18,
  //     "APPLICATION_NAME": "LAUSD Website",
  //     "APPLICATION_DESCRIPTION": "LAUSD WebsiteLAUSD WebsiteLAUSD Website",
  //     "APPLICATION_URL": "https://achieve.lausd.net/",
  //     "THUMBNAIL_IMAGE": "https://storage.googleapis.com/i360controller/COS_3515.png",
  //     "DISPLAY_ORDER": "4",
  //     "CREATED_DATE": "2022-08-05 12:57:13.000",
  //     "CREATED_BY": "RUDNICK,MOLLIE",
  //     "UPDATED_BY": "KUMARESHAN,SARAVANAN",
  //     "STATUS": "Opened",
  //     "UPDATED_DATE": "2022-08-08 14:38:17.000"
  // }
],
Landing_Page_WhatsNew:[{"ID":9,"TITLE":"Whats New 3","SHORT_DESCRIPTION":"New short desc","LONG_DESCRIPTION":"New Long desc","CATEGORY":"New categ","ATTACHMENT_TYPE":"URL","URL":"www.lausd.in","THUMBNAIL_IMAGE":"","CREATED_DATE":"2023-10-19 16:22:14.000","CREATED_BY":"0","UPDATED_DATE":"2023-10-19 16:37:57.000","UPDATED_BY":"JOHNSTON,WILLIAM","STATUS":"Opened"},{"ID":8,"TITLE":"Test Whats New v1","SHORT_DESCRIPTION":"Test Whats New","LONG_DESCRIPTION":"Test Whats New","CATEGORY":"Test Whats New New","ATTACHMENT_TYPE":"URL","URL":"https://wholechild.lausd.net/","THUMBNAIL_IMAGE":"https://storage.googleapis.com/i360controller/adminstrator.png","CREATED_DATE":"2023-08-30 09:46:56.000","CREATED_BY":"KUMARESHAN,SARAVANAN","UPDATED_DATE":"2023-10-19 16:20:54.000","UPDATED_BY":"0","STATUS":"Opened"}],
Landing_Page_WhatsNew_Preview:[{"ID":9,"TITLE":"Whats New 3","SHORT_DESCRIPTION":"New short desc","LONG_DESCRIPTION":"New Long desc","CATEGORY":"New categ","ATTACHMENT_TYPE":"URL","URL":"www.lausd.in","THUMBNAIL_IMAGE":"","CREATED_DATE":"2023-10-19 16:22:14.000","CREATED_BY":"0","UPDATED_DATE":"2023-10-19 16:37:57.000","UPDATED_BY":"JOHNSTON,WILLIAM","STATUS":"Opened"},{"ID":8,"TITLE":"Test Whats New v1","SHORT_DESCRIPTION":"Test Whats New","LONG_DESCRIPTION":"Test Whats New","CATEGORY":"Test Whats New New","ATTACHMENT_TYPE":"URL","URL":"https://wholechild.lausd.net/","THUMBNAIL_IMAGE":"https://storage.googleapis.com/i360controller/adminstrator.png","CREATED_DATE":"2023-08-30 09:46:56.000","CREATED_BY":"KUMARESHAN,SARAVANAN","UPDATED_DATE":"2023-10-19 16:20:54.000","UPDATED_BY":"0","STATUS":"Opened"}],
Landing_Page_FAQ:[{"ID":2,"QUESTIONS":"FAQ","ANSWERS":"FAQ","RELATED_URL":null,"CATEGORY":"FAQ","THUMBNAIL_IMAGE":"","CREATED_DATE":"2023-08-30 17:53:05.000","CREATED_BY":"KUMARESHAN,SARAVANAN","UPDATED_DATE":"2023-08-30 17:53:05.000","UPDATED_BY":null,"STATUS":"Opened"},{"ID":4,"QUESTIONS":"Test FAQ","ANSWERS":"Test FAQ","RELATED_URL":"https://wholechild.lausd.net/","CATEGORY":"Test FAQ","THUMBNAIL_IMAGE":"","CREATED_DATE":"2023-08-30 09:43:25.000","CREATED_BY":"KUMARESHAN,SARAVANAN","UPDATED_DATE":"2023-08-30 09:43:25.000","UPDATED_BY":null,"STATUS":"Opened"},{"ID":3,"QUESTIONS":"FAQ","ANSWERS":"Answered","RELATED_URL":null,"CATEGORY":"Data","THUMBNAIL_IMAGE":"","CREATED_DATE":"2023-08-30 09:42:45.000","CREATED_BY":"RAVICHANDRAN,VIVEK","UPDATED_DATE":"2023-08-30 09:42:45.000","UPDATED_BY":null,"STATUS":"Opened"}],
middleCardTiles : [
        {
          isTutorial: true,
          showAll : true,
          header: "Tutorial of The  Week",
          isPagintionCount: true,
          isTagSelectionEnabled: false,
          isAccordion: false,
          content:[],
        //   content: [
        //     {
        //       image: "laptop-for-business-student.png",
        //       header: "This is a test header",
        //       subHeader: "subheader here goes..",
        //       mainContent:
        //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum ",
        //       actions: [
        //         {
        //           label: "Open Full Article",
        //           target: () => {},
        //         }
        //       ],
        //     },
        //     {
        //       image: "laptop-for-business-student.png",
        //       header: "This is a test header",
        //       subHeader: "subheader here goes..",
        //       mainContent:
        //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum ",
        //       actions: [
        //         {
        //           label: "Open Full Article",
        //           target: () => {},
        //         }
        //       ],
        //     },
        //   ],
        },
        {
          isTutorial: false,
          showAll : true,
          header: "What's New ?",
          isWhatsnew : true,
          isPagintionCount: true,
          isTagSelectionEnabled: false,
          isAccordion: false,
          content:[]
        //   content: [
        //     {
        //       image: "stu.png",
        //       header: "This is a test header",
        //       subHeader: "subheader here goes..",
        //       eventTime: "22/10/2022 11 AM",
        //       mainContent:
        //         "Explore. Discover. Attend.",
        //       whatsNewContent : "Late application starts Feb. 2 for all unique programs and school options available for your child.",
        //     },
        //     {
        //       image: "stu.png",
        //       header: "This is a test ",
        //       subHeader: "subheader here goes..",
        //       eventTime: "22/10/2022 11 AM",
        //       mainContent:
        //         "Los Angeles Unified Schools Open Providing Safe and Welcoming Learning Environment",
        //       whatsNewContent : "Late application starts Feb. 1 for all unique programs and school options available for your child.",
        //     },
        //     {
        //       image: "stu.png",
        //       header: "This is a test header",
        //       subHeader: "subheader here goes..",
        //       eventTime: "22/10/2022 11 AM",
        //       mainContent:
        //         "Los Angeles Unified Schools Open Providing Safe and Welcoming Learning Environment",
        //       whatsNewContent : "Late application starts Feb. 1 for all unique programs and school options available for your child."
        //     },
        //     {
        //       image: "stu.png",
        //       header: "This is a test header",
        //       subHeader: "subheader here goes..",
        //       eventTime: "22/10/2022 11 AM",
        //       mainContent:
        //         "1 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
        //     },
        //     {
        //       image: "stu.png",
        //       header: "This is a test ",
        //       subHeader: "subheader here goes..",
        //       eventTime: "22/10/2022 11 AM",
        //       mainContent:
        //         "2 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
        //     },
        //     {
        //       image: "stu.png",
        //       header: "This is a test header",
        //       subHeader: "subheader here goes..",
        //       eventTime: "22/10/2022 11 AM",
        //       mainContent:
        //         "3 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
        //     },
        //     {
        //       image: "stu.png",
        //       header: "This is a test header",
        //       subHeader: "subheader here goes..",
        //       eventTime: "22/10/2022 11 AM",
        //       mainContent:
        //         "1 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
        //     },
        //     {
        //       image: "stu.png",
        //       header: "This is a test ",
        //       subHeader: "subheader here goes..",
        //       eventTime: "22/10/2022 11 AM",
        //       mainContent:
        //         "2 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
        //     },
        //     // {
        //     //   image: "laptop-for-business-student.png",
        //     //   header: "This is a test header",
        //     //   subHeader: "subheader here goes..",
        //     //   mainContent:
        //     //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum ",
        //     //   actions: [
        //     //     {
        //     //       label: "Open Full Article",
        //     //       target: () => {},
        //     //     },
        //     //     {
        //     //       label: "Download",
        //     //       target: () => {},
        //     //     },
        //     //   ],
        //     // },
        //   ],
        },
        {
          isTutorial: false,
          isFaq : true,
          header: "FAQs",
          isPagintionCount: true,
          isTagSelectionEnabled: false,
          isAccordion: false,
          content:[]
          // content: [
          //   {
          //     image: "stu.png",
          //     header: "This is a test header",
          //     subHeader: "subheader here goes..",
          //     eventTime: "22/10/2022 11 AM",
          //     mainContent:
          //       "1 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
          //     actions: [
          //       {
          //         label: "Open Full Article",
          //         target: () => {},
          //       },
          //       {
          //         label: "Download",
          //         target: () => {},
          //       },
          //     ],
          //   },
          //   {
          //     image: "stu.png",
          //     header: "This is a test ",
          //     subHeader: "subheader here goes..",
          //     eventTime: "22/10/2022 11 AM",
          //     mainContent:
          //       "2 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
          //     actions: [
          //       {
          //         label: "Open Full Article",
          //         target: () => {},
          //       },
          //       {
          //         label: "Download",
          //         target: () => {},
          //       },
          //     ],
          //   },
          //   {
          //     image: "stu.png",
          //     header: "This is a test header",
          //     subHeader: "subheader here goes..",
          //     eventTime: "22/10/2022 11 AM",
          //     mainContent:
          //       "3 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
          //     actions: [
          //       {
          //         label: "Open Full Article",
          //         target: () => {},
          //       },
          //       {
          //         label: "Download",
          //         target: () => {},
          //       },
          //     ],
          //   },
          //   {
          //     image: "stu.png",
          //     header: "This is a test header",
          //     subHeader: "subheader here goes..",
          //     eventTime: "22/10/2022 11 AM",
          //     mainContent:
          //       "1 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
          //     actions: [
          //       {
          //         label: "Open Full Article",
          //         target: () => {},
          //       },
          //       {
          //         label: "Download",
          //         target: () => {},
          //       },
          //     ],
          //   },
          //   {
          //     image: "stu.png",
          //     header: "This is a test ",
          //     subHeader: "subheader here goes..",
          //     eventTime: "22/10/2022 11 AM",
          //     mainContent:
          //       "2 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
          //     actions: [
          //       {
          //         label: "Open Full Article",
          //         target: () => {},
          //       },
          //       {
          //         label: "Download",
          //         target: () => {},
          //       },
          //     ],
          //   },
          //   {
          //     image: "stu.png",
          //     header: "This is a test header",
          //     subHeader: "subheader here goes..",
          //     eventTime: "22/10/2022 11 AM",
          //     mainContent:
          //       "3 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries",
          //     actions: [
          //       {
          //         label: "Open Full Article",
          //         target: () => {},
          //       },
          //       {
          //         label: "Download",
          //         target: () => {},
          //       },
          //     ],
          //   },
          //   // {
          //   //   image: "laptop-for-business-student.png",
          //   //   header: "This is a test header",
          //   //   subHeader: "subheader here goes..",
          //   //   mainContent:
          //   //     "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum ",
          //   //   actions: [
          //   //     {
          //   //       label: "Open Full Article",
          //   //       target: () => {},
          //   //     },
          //   //     {
          //   //       label: "Download",
          //   //       target: () => {},
          //   //     },
          //   //   ],
          //   // },
          // ],
        },
      ],
    selectionTile : [
        {
            name: "Executive Dashboard",
            image: "assest/homepage_logo.png",
            link: "/executivedashboard.html"
        },
        {
            name: "Whole Child View",
            image: "assest/whole_child.png",
            link: "/whole_child.html"
        },
        {
            name: "Open Data Dashboard",
            image: "assest/open_data.png",
            link: "/opendata.html"
        }
    ],
    dashboardInfo: [
        {
            dashboard_name: "Executive Dashboard",
            description: "Executive dashboard is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            link: "/executivedashboard.html"
        },
        {
            dashboard_name: "Whole Child View",
            description: "Whole child view is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            link: "/whole_child.html"
        },
        {
            dashboard_name: "Open Data Dashboard",
            description: "Open data dashboard is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
            link: "/opendata.html"
        }
    ],
    notifications: [
        {
            label: "Lorem ipsum 1",
            time: "2022/02/02 11:00 AM",
            description: "When you select any of the following notification, will you be adressed to that specific page. You can use the arrows to see more.",
            status: "Opened"
        },
        {
            label: "Lorem ipsum 2",
            time: "2022/03/02 02:30 PM",
            description: "When you select any of the following notification, will you be adressed to that specific page. You can use the arrows to see more.",
            status: "In Review"
        },
        {
            label: "Lorem ipsum 3",
            time: "2022/12/02 12:00 PM",
            description: "When you select any of the following notification, will you be adressed to that specific page. You can use the arrows to see more.",
            status: "Closed"
        }
    ],
    allNotifications: [
        {
            label: "Lorem ipsum 1",
            time: "2022/02/02 11:00 AM",
            description: "When you select any of the following notification, will you be adressed to that specific page. You can use the arrows to see more.",
            status: "Opened",
            type: "Type",
            requestedBy: "John Marston",
            category: "Data",
            releaseTime: "2022/02/02 12:00 PM",
            key: "Lorem ipsum 1"
        },
        {
            label: "Lorem ipsum 2",
            time: "2022/03/02 02:00 PM",
            description: "When you select any of the following notification, will you be adressed to that specific page. You can use the arrows to see more.",
            status: "In Review",
            type: "Type",
            requestedBy: "Arthur Morgan",
            category: "System",
            releaseTime: "2022/03/02 04:00 PM",
            key: "Lorem ipsum 2"
        },
        {
            label: "Lorem ipsum 3",
            time: "2022/12/02 12:00 PM",
            description: "When you select any of the following notification, will you be adressed to that specific page. You can use the arrows to see more.",
            status: "Closed",
            type: "Type",
            requestedBy: "Sadie Adler",
            category: "Data",
            releaseTime: "2022/12/02 09:00 PM",
            key: "Lorem ipsum 3"
        },
        {
            label: "Lorem ipsum 4",
            time: "2022/02/02 11:00 AM",
            description: "When you select any of the following notification, will you be adressed to that specific page. You can use the arrows to see more.",
            status: "Opened",
            type: "Type",
            requestedBy: "Hosea Mathews",
            category: "Data",
            releaseTime: "2022/02/02 12:23 PM",
            key: "Lorem ipsum 4"
        },
        {
            label: "Lorem ipsum 5",
            time: "2022/03/02 02:00 PM",
            description: "When you select any of the following notification, will you be adressed to that specific page. You can use the arrows to see more.",
            status: "In Review",
            type: "Type",
            requestedBy: "Abigail Marston",
            category: "System",
            releaseTime: "2022/03/02 04:30 PM",
            key: "Lorem ipsum 5"
        },
        {
            label: "Lorem ipsum 6",
            time: "2022/12/02 12:00 PM",
            description: "When you select any of the following notification, will you be adressed to that specific page. You can use the arrows to see more.",
            status: "Closed",
            type: "Type",
            requestedBy: "Lenny Summers",
            category: "Data",
            releaseTime: "2022/12/02 09:00 PM",
            key: "Lorem ipsum 6"
        }
    ],
    usefulLink : [
        {
            title: "Focus Application",
            description: "When you select any of the following subject rea cards, will you be adressed to that specific page. You can use the arrows to see more.",
            image: "https://cdn-icons-png.flaticon.com/512/4696/4696727.png",
            link:"/focus.html"
        },
        {
            title: "Student Search",
            description: "When you select any of the following subject rea cards, will you be adressed to that specific page. You can use the arrows to see more.",
            image: "https://cdn-icons-png.flaticon.com/512/1055/1055645.png",
            link:"/focus.html"
        },
        {
            title: "My Team",
            description: "When you select any of the following subject rea cards, will you be adressed to that specific page. You can use the arrows to see more.",
            image: "https://cdn-icons-png.flaticon.com/512/1256/1256650.png",
            link:"/focus.html"
        },
        {
            title: "My Data",
            description: "When you select any of the following subject rea cards, will you be adressed to that specific page. You can use the arrows to see more.",
            image: "https://cdn-icons-png.flaticon.com/512/3039/3039367.png",
            link:"/focus.html"
        }
    ],
    landingPageTopTile :[
        {
            metric_name: "Total Enrollment",
            image:"https://cdn-icons-png.flaticon.com/512/476/476863.png",
            metric_value:"48803",
            metric_type:"number",
            metric_variance:"5",
            metric_indicator:"positive",
            link:"/summary_page.html"
        },
        {
            metric_name: "Graduation Rate",
            image:"https://cdn-icons-png.flaticon.com/512/2231/2231642.png",
            metric_value:"97%",
            metric_type:"percentage",
            metric_variance:"5",
            metric_indicator:"positive"
        }
    ]
}
export default function (state = initialState, action) {
//   debugger
    switch (action.type) {

        case "FILTER_STATIC_DATA":
            {
                const filteredData = {}
                const filters = action.payload
                const filterKeys = Object.keys(filters);

                // filterKeys.forEach(key=> {
                //     controlPrompts[key].forEach(report=> {
                //         filteredData[report+"Data"]=initialState[report].filter(row=> row[key]== filters[key]  )
                //     })
                // });

                const initialDataKeys = Object.keys(initialState) ;

                filterKeys.forEach(column=> {
                    initialDataKeys.forEach(key=> {
                        filteredData[key+"Data"]=initialState[key].filter(row=> {
                            if(row[column]) {return row[column] == filters[column]}
                            else if(!row[column]) {return true}  }  )
                    })
                });
                return { ...state, ...filteredData};
            }
            case "ADD_STATIC_DATA":
                { 
                    const {report, data,row} = action.payload;
                    let selectedData = JSON.parse(JSON.stringify(data));
                    selectedData && selectedData.push(row);
                    const reportObj = {
                        [report+"Data"]: selectedData
                    }
                    return { ...state, ...reportObj};
                }
        case "LOAD_STATIC_DATA":
                const initialData = {}
                const requestedData = action.payload;
                requestedData.forEach(key=> {
                    initialData[key+"Data"]=initialState[key]
                });
                // const initialDataKeys = Object.keys(initialState);
                // initialDataKeys.map(key => {
                //     initialData[key+"Data"]=initialState[key]
                // })
                return { ...state, ...initialData};
        case "CommonDataAdd":
        //   debugger
                // const initialData = {}
                let {middleCardTilesData} = action.payload;
                // requestedData.forEach(key=> {
                //     initialData[key+"Data"]=initialState[key]
                // });
                // const initialDataKeys = Object.keys(initialState);
                // initialDataKeys.map(key => {
                //     initialData[key+"Data"]=initialState[key]
                // })
                return { ...state,
                  middleCardTilesData:middleCardTilesData};
        
        default:
            return state;
    }
}