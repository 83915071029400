import React from "react";
import Schema from "./schema";
import { connect } from "react-redux";
import SemanticComponent from "../../../components/semantic-component/component";
// import  "../../../assest/";
import { toMillion } from "../../../utils/CurrencyUTIL";
import "./style.css";

class UsefulLinksTile extends Schema {
  // import(`../../../assest/${data.img}`).then((module) => {
  //   logo = module.default; // <= base64 image
  // });
  // let {item=[]}= this.props
  // let data1 = Array.isArray(item)?item[0]:item || {}

  // let img= require(`/assest/${data1.img}`)
  
  render() { 
    const { selected,tileClick,item=[]} = this.props; 
    const data = Array.isArray(item)?item[0]:item || {}
    
    // {
    //                 require(`../../assest/${each.image}`).default
    //               }
  //  let img= `../../../assest/${data.img}`
   let img= `${data.img}`
   let description=`${data.description}`
   let title=`${data.title}`
  //  let img=require(`../../../assest/${data.img}`).default 
   return (
    <div className="content_img" onClick={()=>tileClick(item)}>
      <div className=" is-row is-cursor-pointer" >
        <SemanticComponent
          // value={`<row class="custom is-mar-1 is-font-size-sm  is-rounded ">
          // <column class="title">
          //     <row class=" is-display-flex is-hu-28 image">
          //     <column size=12 style= "overflow:hidden">  
          //     <image style=" width:100%" class="is-hu-28 is-wu-5" src="${img|| "https://cdn-icons-png.flaticon.com/128/1077/1077012.png"}" ></image>
          //     </column>
           
          //     </row>
          // </column>
          //       </row>`}
          
          value={
            `<row class="is-display-flex is-hu-33 is-align-center is-justify-content-center is-shadow-none is-pad-1" style="background:var(--tiles-bg); color:var(--cardContent)">
            <column class="is-display-flex is-align-center is-flex-column is-mar-top-8">
              <row class="">
              <column>
                <row>
                  <column class="is-display-flex is-align-center is-justify-content-center is-mar-bottom-5">
                    <image class="is-hu-12 is-wu-12" src="${img|| "https://cdn-icons-png.flaticon.com/128/1077/1077012.png"}"></image>
                  </column>
                </row>
                <row class="is-text-center is-pad-bottom-4">
                  <column class="is-text-center" style="font-size:20px">
                 ${title}
                  </column>
                </row>
               <row class="is-pad-top-4">
                <column>
                <row class="is-margin-auto is-display-flex is-border is-rounded-pill" style="width:50%;">
                <column style="flex:0 0 35px; margin-bottom:2px" class="is-display-flex is-align-items-center is-justify-content-end is-mar-right-1">
                   <image class="" src="/images/shuttle.png" style="height:17px;"></image>
                </column>
                <column style="flex:1" class="is-display-flex is-align-center" style="color:#7e7e7e; 1px solid #ced4da">
               Dashboard
                </column>
              </row>
                </column>
               </row>
              </column>
              </row>
            </column>
          </row>`
          }
        />
        
      </div>
      <div className="content_description">
        <div className="content_title">{title}</div>
        <div className="content_desc">{description}</div>
        
        <SemanticComponent
          value={`<row class="">
          <column>
          <row class="is-display-flex is-border is-rounded-pill" style="width:50%;">
          <column style="flex:0 0 35px; margin-bottom:2px" class="is-display-flex is-align-items-center is-justify-content-end is-mar-right-1">
             <image class=" dashboard-icon" src="/images/shuttle.png" style="height:17px;"></image>
          </column>
          <column style="flex:1" class="is-display-flex is-align-center" style="color:#7e7e7e; 1px solid #ced4da">
          Dashboard
          </column>
          </row>
          </column>
          </row>`

          }
        />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {  
};

export default connect(mapStateToProps, null)(UsefulLinksTile);
