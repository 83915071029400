import React, {useRef, useState} from "react";
import { connect } from "react-redux";
import {Avatar,Modal,Card} from 'antd';
import landingPageTopTile1 from "../../../assest/landingpage-toptile.png"
import landingPageTopTile2 from "../../../assest/background2.png"
import ProfileImage from "../../../assest/person.png";
import ThemeSwitcher from "../../../assest/ellipseThemeSwitcher.svg"
import topBannerBG1 from "../../../assest/top_banner_bg1.jpg";
import topBannerBG2 from "../../../assest/top_banner_bg2.jpg"
import { useEffect } from "react";
import config from "./config";

function TopTile(props) {
  // const [isDark,setIsDark] = useState(window.document.querySelector('[data-theme="dark"]'));
  const [jsonConfig, setJsonConfig] = useState({});
  const [clientLogoPath,setClientLogoPath] = useState("/images/client_logo.png");
  const [isLight, setIsLight] = useState(false);

useEffect(()=>{
  let isDarkMode = !isLight;
  let darkModeImage = '/images/client_logo_dark.png';
  let defaultPath =  "/images/client_logo.png";
  if(isDarkMode){
    setClientLogoPath(darkModeImage)
  }else{
    setClientLogoPath(defaultPath)
  }
},[isLight]);
  useEffect(() => {
    if (window.current_customer === 'gcsd') {
      import(`./style_${window.current_customer}.css`).then(() => {
        console.log('Styles for Greece loaded');
      });
    }else{
      import('./style.css').then(() => {
        console.log('Styles for Demo loaded');
      });
    }
  }, [window.current_customer]);


  useEffect(()=>{
    if(config)
    setJsonConfig(config[window.current_customer]);
  },[config]);

// debugger
  console.log("TopTile re render")
  const bottomNavigationRef = useRef(null);
  const { selected, onClick, data = [],user , useFulLinkData} = props;
  const [open,setOpen] = useState(false);
  const [bgImage, setBgImage] = useState(landingPageTopTile1);
  const [isUserHover,setIsUserHover] = useState(false);
  let {welcome_text , welcome_description} = (useFulLinkData && useFulLinkData[0]?.HEADER_LANDING) ? JSON.parse(useFulLinkData[0]?.HEADER_LANDING) : {};

  const firstName = user && user.fullName


  const handleCarouselChange = (index) => {
    if(index==0) setBgImage(landingPageTopTile1)
    else if(index==1) setBgImage(landingPageTopTile2) 
  }

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    var url = (window.BR && window.BR.logoutURI) || '/logout';
    
    fetch(url, {
    method: 'POST',
    headers: {
    'Content-Type': 'application/json'
    },
    })
    .then(response => {
    redirectToLogout(response);
    })
    .catch(error => {
    redirectToLogout();
    });
    }
    
    const redirectToLogout = (response) => {
    var redirectUrl = `${window.location.origin}/login/login.html`;
    var iframe = window.document.createElement('iframe');
    iframe.src = redirectUrl;
    iframe.style = "display:none;"
    window.document.body.appendChild(iframe);
    if (response && response.headers.get('href')) {
        window.location.href = response.headers.get('href');
    } else {
        setTimeout(() => {
            window.location.href = window.location.origin;
        }, 1000);
    }
    }
    
  const handleLausdVideo = () =>{
    if(window.current_customer === "gcsd"){
      props.param({videoPlayerModal: true, videoPlayerModalUrl:"https://elocallink.tv/e/share.php?ffb=1&v=mt9&ap=1" })
    }else{
     props.param({videoPlayerModal: true, videoPlayerModalUrl:"https://www.youtube.com/watch?v=rZ1aOFAfMrQ&ab_channel=Innive" })
    }
  }
useEffect(() => {
  document.documentElement.setAttribute('data-theme', isLight ? "light" : 'dark');
  document.documentElement.setAttribute('class', isLight ? "light" : 'dark');
}, [isLight])

  const handleThemeSwicth = () =>{
    setIsLight(!isLight);
  }
  

  const scrollToBottom = () => {
    const footerElement = document.querySelector('.page-footer-container.is-pad-1');
    if (footerElement) {
      footerElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  };


  const handleTopSectionBg=()=>{
    if(window.current_customer==="gcsd"){
       if(isLight){
      return (<img src= {topBannerBG1}/>)
       }else{
        return (<img src= {topBannerBG2}/>)
       }
    }else{
      return  (<video autoPlay muted loop>
        <source src="https://assets.mixkit.co/videos/preview/mixkit-students-walking-in-a-university-4519-large.mp4" type="video/mp4" />
        </video>)
    }
  }

    return (
      <>
       <Modal className = "notificationModal" title={null} closable={false} visible={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} width={350} height={500} footer={null} >
        {/* {props.content} */}
       <text>Notifications</text>{
        (props.notifyData || []).map((ele,i)=>{
          return(
            <Card key = {i} className="notification-card" 
              title={<Avatar key = {i} className="is-shadow" style={{height:'40px',width:'40px'}} src={ProfileImage} alt="USERIMAGE" />}
              style={{
                width: '100%',
                display: 'flex'
              }}
            >
            <p><strong>{ele.name}</strong> {ele.message} <srtong>{ele.access}</srtong></p>
            <p>{ele.timeStrap}</p>
            </Card>
          )
        })
       }
       
      </Modal>
      <div>
     <div className="videoBg">
     {handleTopSectionBg()}
      {/* <video autoPlay muted loop>
        <source src="https://assets.mixkit.co/videos/preview/mixkit-students-walking-in-a-university-4519-large.mp4" type="video/mp4" />
      </video> */}
    </div>
      <div className="is-pos-relative is-row is-hu-60 is-text-white is-display-flex is-justify-content-between banner-top-gradient"   onClick={() => onclick}>
        <div className="is-display-flex is-flex-column is-justify-content-center is-wu-80 la-title-bg is-pad-y-5 is-pad-x-15 ">
          <div className="is-display-flex is-align-items-center client-logo-landing">
            <img class="is-hu-10" src={clientLogoPath} ></img>
            <span className="is-pad-x-2 welcm-title">
            </span>
          </div>

          <div  className="is-pad-x-5">
            <div className="is-font-size-xl banner-top-texts">Hi <span className="is-font-weight-bold">{firstName ? firstName.trim() : `User` }</span>
            {welcome_text ? ", "+ welcome_text+'' : <>, Welcome to Innive K12360 Product Analytics Landing Page!</>}
            </div>
            <div className="is-font-size-md is-pad-y-1 is-mar-bottom-5 banner-top-texts">
            {welcome_description ? welcome_description : <>Whole Child Analytics by Innive K12 360 integrates assessment and other data to focus on the whole child. not just academic skills, but also areas like social and emotional learning. Then, educators and administrators alike use the integrated data to evaluate learning and support interventions, accelerate learning goals, and identify areas of improvement.</>}
            </div>
          </div>
        </div>

        <div className="is-wu-40 user-opacity is-mar-top-5 is-mar-right-5">
          <div onMouseOver={()=>setIsUserHover(true)} onMouseOut={()=>{setIsUserHover(false)}} className="is-display-flex is-align-items-center is-align-self-end is-justify-content-end is-mar-3 user-profile">
          
           {/* theme button */}
           <Avatar onClick={handleThemeSwicth}  className={(isLight?"themeButton is-cursor-pointer is-mar-top-2":"themeButtonRotate is-cursor-pointer")+" is-mar-x-2"} style={{height:'40px',width:'35px', background:"none" }} src={ThemeSwitcher} alt="USERIMAGE" />
           {/* profile button */}
           <Avatar className="is-shadow" style={{height:'55px',width:'54px'}} src={ProfileImage} alt="USERIMAGE"/>
           <div className="is-mar-x-2" style={{display:isUserHover?'block':'none'}}>
              <div className="is-font-size-md">{firstName ? firstName.trim() : `User` }</div>
              <div onClick={handleLogout} className="is-font-size-sm is-cursor-pointer is-text-right">Logout</div>
           </div>
           
           <img className="is-mar-left-2" style={{height:"4rem",width:"10rem"}} src={"/images/innive_logo.png"} ></img>

          </div>
          <div className="playButtonContainer">
          <div className="playButton is-hu-10 is-wu-19 banner-top-texts" onClick={()=>{handleLausdVideo()}}>
             <svg width="38" height="33" viewBox="0 0 38 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M36 18.0359C38.6667 19.5755 38.6667 23.4245 36 24.9641L6.75001 41.8516C4.08334 43.3912 0.75 41.4667 0.75 38.3875V4.61251C0.75 1.53331 4.08333 -0.391198 6.75 1.1484L36 18.0359Z" fill="white"/>
            </svg>
              <p style={{margin: "2%", fontSize:"26px"}}>Play</p>
          </div>
          </div>
         
         
        </div>
        <div className="bottom-navigation" onClick={scrollToBottom} ref={bottomNavigationRef}>
       {[1,1,1].map((ele,ind)=>{
        return (
          <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity={ind==0?"0.5":ind==1?"0.75":"1"} fill-rule="evenodd" clip-rule="evenodd" d="M0.463748 0.460261C1.08208 -0.15342 2.08459 -0.15342 2.70292 0.460261L9.5 7.20624L16.2971 0.460261C16.9154 -0.15342 17.9179 -0.15342 18.5363 0.460261C19.1546 1.07394 19.1546 2.06892 18.5363 2.6826L10.6196 10.5397C10.0013 11.1534 8.99874 11.1534 8.38041 10.5397L0.463748 2.6826C-0.154583 2.06892 -0.154583 1.07394 0.463748 0.460261Z" fill="#201D1D"/>
          </svg>
        )
       })}
      </div>
      </div>
    </div>
      </>
     
    );
}

const mapStateToProps = (state, ownProps) => ({
  user:state.reports.user,
  // currentUser:state.param.currentUser
});

export default connect(mapStateToProps, null)(TopTile);
