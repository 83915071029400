export const CURRENT_ROLE_CUSTOMER_BASED = ()=>{
  let currentCustomer = window.location.origin.includes("greece") ? "greece" : "demo"; //Hardcode here to change the local role for dev
  switch (currentCustomer) {
      case "greece":
        return {
          // "DEVELOPER" : "DISTRICT_ADMIN"
        };
        case "gcsd":
          return {
            // "DEVELOPER" : "DISTRICT_ADMIN"
          };
      case "demo":
        return {
          "DEVELOPER" : "DEVELOPER"
        };
    }
}


export const CURRENT_CUSTOMER = ()=>{
    return window.location.origin.includes("greece") ? "greece" : "demo"
}