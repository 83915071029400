import React from 'react';
import ReactPlayer from 'react-player/lazy'

// Only loads the YouTube player



const VideoPlayer = ({
  url= "",
}) => {

  return (
    <ReactPlayer url={url}  width='100%' height='100%' controls={true} />
  )
}

export default VideoPlayer